<template>
  <section class="login">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth">
          <div class="row w-100 flex-grow">
            <div class="col-xl-4 col-lg-6 mx-auto">
              <div class="auth-form-light text-left">
                <div class="brand-logo text-center">
                    <img src="../public/assets/images/cheapcomfort-logo.svg"/>
                </div>
                <div class="auth-form">
                  <form>
               
                    <div class="form-fields">
                      <!-- Name -->
                      <b-form-group>
                        <b-form-input
                          id="input-3"
                          type="text"
                          placeholder="Name"
                          v-model="$v.name.$model"
                          :state="$v.name.$dirty ? !$v.name.$error : null"
                          aria-describedby="input-3-live-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          v-if="!$v.name.required"
                          id="input-3-live-feedback"
                        >
                          This is a required field.
                        </b-form-invalid-feedback>
                      </b-form-group>
    
                      <!-- Email -->
                      <b-form-group>
                        <b-form-input
                          id="input-4"
                          type="text"
                          placeholder="Email"
                          v-model="$v.email.$model"
                          :state="$v.email.$dirty ? !$v.email.$error : null"
                          aria-describedby="input-4-live-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          v-if="!$v.email.required"
                          id="input-4-live-feedback"
                        >
                          This is a required field.
                        </b-form-invalid-feedback>
                      </b-form-group>
    
                      <!-- Password -->
                      <b-form-group>
                        <b-form-input
                          id="input-4"
                          type="text"
                          placeholder="Password"
                          v-model="$v.password.$model"
                          :state="$v.password.$dirty ? !$v.password.$error : null"
                          aria-describedby="input-4-live-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          v-if="!$v.password.required"
                          id="input-4-live-feedback"
                        >
                          This is a required field.
                        </b-form-invalid-feedback>
                      </b-form-group>
    
                      <!-- Phone Number -->
                      <b-form-group>
                        <b-form-input
                          id="input-5"
                          type="tel"
                          placeholder="Phone Number"
                          v-model="$v.phone.$model"
                          :state="$v.phone.$dirty ? !$v.phone.$error : null"
                          aria-describedby="input-5-live-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          v-if="!$v.phone.required"
                          id="input-5-live-feedback"
                        >
                          This is a required field.
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback
                          v-if="!$v.phone.minLength"
                          id="input-5-live-feedback"
                        >
                          Phone number must have at least {{ $v.phone.$params.minLength.min }} digits.
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback
                          v-if="!$v.phone.maxLength"
                          id="input-5-live-feedback"
                        >
                          Phone number must have at most {{ $v.phone.$params.maxLength.max }} digits.
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>
  
                    <!-- Add your existing fields here -->
  
                    <div class="form-group text-center m-t-20 mb-2 mt-4">
                      <button
                        type="button"
                        :disabled="isDisabled"
                        class="btn btn-primary btn-block btn-lg waves-effect waves-light"
                        @click="register()"
                      >
                        Sign Up
                      </button>
                    </div>
                    <div class="social-login text-center">
                      <router-link to="/Admin" class="btn">
                          <i class="fa fa-user m-right-5"></i><span>Login</span>
                      </router-link>
                    </div>
                  </form>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </section>
</template>

<script>
import API from '@/api'
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  name: "Sign_up",
  mixins: [validationMixin],
  data() {
    return {
      name: "",
      email: "",
      password: "",
      phone: "",
      isDisabled: false,
      isHide: false,
    };
  },
  validations: {
    // Existing field validations ...

    name: {
      required,
    },
    email: {
      required,
    },
  
    password: {
      required,
      minLength: minLength(6),
    },
    phone: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(15),
    },
  },
  methods: {
    register() {
      this.$v.$touch();

      if (this.$v.$anyError) {
        return;
      }

      this.isDisabled = true;

      let data = {
        name: this.name,
        email: this.email,
        password: this.password,
        phone: this.phone,
        // Add your existing fields to the data object
      };

           API.registerUser(
                    data,
                    data => {
                      console.log(data);  
                        if (data.token) {
                          this.$router.push("/Admin");
                        } else {
                            this.$swal({
                                type: 'error',
                                title: 'Oops...',
                                text: data.message,
                            })
                        }
                        this.isDisabled = false;
                    },
                    err => {
                        this.isDisabled = false;
                        this.$swal({
                            type: 'error',
                            title: 'Oops...',
                            text: err.errors.email,
                        })
                    }
                )


    },
  },
};
</script>

