<template>
    <section class="login">
        <div class="container-scroller">
            <div class="container-fluid page-body-wrapper full-page-wrapper">
                <div class="content-wrapper d-flex align-items-center auth">
                    <div class="row w-100 flex-grow">
                        <div class="col-xl-4 col-lg-6 mx-auto">
                            <div class="auth-form-light text-left">
                                <div class="brand-logo text-center">
                                    <img class="test" src="../../../public/assets/images/cheapcomfort-logo.svg" />
                                </div>
                                <div class="auth-form">
                                    <form>
                                        <div class="form-fields">
                                            <b-form-group>
                                                <b-form-input id="input-1" type="email" placeholder="Enter Email"
                                                    v-model="$v.email.$model"
                                                    :state="$v.email.$dirty ? !$v.email.$error : null"
                                                    aria-describedby="input-2-live-feedback"></b-form-input>
                                                <b-form-invalid-feedback v-if="!$v.email.required"
                                                    id="input-2-live-feedback">
                                                    This is a required field
                                                </b-form-invalid-feedback>
                                                <b-form-invalid-feedback v-if="!$v.email.email"
                                                    id="input-2-live-feedback">
                                                    Invalid email format
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                            <b-form-group>
                                                <span class="icon is-small is-right" style="">
                                                    <b-form-input id="input-2" :type="inputType"
                                                        placeholder="Enter Password" v-model="$v.password.$model"
                                                        :state="$v.password.$dirty ? !$v.password.$error : null"
                                                        aria-describedby="input-3-live-feedback"></b-form-input>
                                                    <b-form-invalid-feedback v-if="!$v.password.required"
                                                        id="input-3-live-feedback">This is a required
                                                        field.</b-form-invalid-feedback>
                                                    <b-form-invalid-feedback v-if="!$v.password.minLength"
                                                        id="input-3-live-feedback">Password must have at least {{
                                                        $v.password.$params.minLength.min }}
                                                        letters.</b-form-invalid-feedback>
                                                    <!--                                         <b-form-invalid-feedback v-if="password && !$v.password.valid" id="input-3-live-feedback">Password contains atleast One Uppercase, One Lowercase, One Number and One Special Chacter.</b-form-invalid-feedback>-->
                                                    <i @click="toggleShow" class="fas password-icon"
                                                        :class="{ 'fa fa-eye': showPassword, 'fa fa-eye-slash': !showPassword, }"></i></span>

                                            </b-form-group>
                                        </div>
                                        <div class="form-check">
                                            <label class="form-check-label text-muted">
                                                <input type="checkbox" v-model="remember_be" class="form-check-input" />
                                                Remember me
                                                <i class="input-helper"></i>
                                            </label>
                                        </div>
                                        <div>
                                            <vue-recaptcha sitekey="6Lc_o30qAAAAAGIPH2_7HHaS9KJsZpg3mZUoFP0-"
                                                @verify="onVerify"></vue-recaptcha>
                                        </div>
                                        <div class="form-group text-center m-t-20 mb-2">
                                            <button type="button"
                                                class="btn btn-primary btn-block btn-lg waves-effect waves-light"
                                                :disabled="this.$v.email.$anyError || this.$v.password.$anyError"
                                                @click="login()">
                                                SIGN IN
                                            </button>
                                        </div>
                                        <div class="social-login text-center">
                                            <router-link to="/forget-password" class="btn">
                                                <i class="fa fa-lock m-r-5"></i><span>Forgot password</span>
                                            </router-link>
                                            <router-link to="/sign-up" class="btn">
                                                <i class="fa fa-user m-right-5"></i><span>Sign Up</span>
                                            </router-link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </section>
</template>


<script>
import router from "../../../router"
import API from '@/api'
import Footer from "../../../layout/partials/Footer";
import { validationMixin } from "vuelidate";
import { helpers, required, minLength, email, sameAs } from "vuelidate/lib/validators";
import { VueRecaptcha } from 'vue-recaptcha';


export default {
    name: "Login",
    mixins: [validationMixin],
    components: {
        Footer,
        VueRecaptcha
    },
    data() {
        return {
            email: '',
            password: '',
            disabled: '',
            remember_be: '',
            isChecked: false,
            showPassword: true,
            inputType: 'password',
            recaptchaToken: '',
        };
    }, validations: {
        email: {
            email,
            required,
        }, password: {
            required,
            minLength: minLength(6),
        }
    },

    methods: {
        onVerify(recaptchaToken) {
            this.recaptchaToken = recaptchaToken;
        },
        checkFunction(event) {
            if (event.target.checked) {
                this.isChecked = true;
            } else {
                this.isChecked = false;
            }

        },
        login() {
            this.$v.email.$touch();
            this.$v.password.$touch();
            if (this.$v.email.$anyError || this.$v.password.$anyError) {
                return;
            }
            let email = this.email
            let password = this.password
            let remember_be = this.remember_be
            let recaptchatoke = this.recaptchaToken
            let data = {
                email: email,
                password: password,
                client_url: window.location.href,
                remember_be: remember_be,
                recaptcha_token: recaptchatoke
            }
            this.disabled = true;
            API.login(
                data,
                data => {
                    if (typeof data.token !== "undefined") {
                        localStorage.setItem('isLoggedin', data.token)
                        localStorage.setItem('client_settings', JSON.stringify(data.client_settings))
                        localStorage.setItem('user', JSON.stringify(data.user))
                        localStorage.setItem('userPermissions', JSON.stringify(data.permission))
                        if (this.remember_be == 'true') {
                            localStorage.setItem('email', this.email)
                            localStorage.setItem('password', this.password)
                        }
                        router.push({ name: "dashboard" });
                    } else {
                        this.$swal({
                            type: 'error',
                            title: 'Oops...',
                            text: data.message,
                        })
                    }
                },
                err => {
                    this.$swal({
                        type: 'error',
                        title: 'Oops...',
                        text: err.message,
                    })
                }
            )
        },
        toggleShow() {
            this.showPassword = !this.showPassword;
            if (this.showPassword) {
                this.inputType = 'password';
            } else {
                this.inputType = 'text';
            }
        }
    }
}
</script>

<style>
.page-body-wrapper.full-page-wrapper {
    min-height: calc(100vh - 77px);
}

.page-body-wrapper.full-page-wrapper .content-wrapper {
    height: 100%;
}
</style>