<template>
    <section class="tables new-updated-design px-3 px-md-0 pt-4 pt-md-0">
        <vue-snotify></vue-snotify>
        <div>
            <b-modal id="modal" :title="referralNote" ref="referral-modal">
                <div class="row">
                    <div class="col-md-12">
                        <p class="mb-3">{{ modalContent }}</p>
                    </div>
                </div>
            </b-modal>
        </div>
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card properties-table">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">{{ $t('properties_list.View_Bookings') }}</h4>
                    </div>
                    <div class="card-body">
                        <vue-good-table mode="remote" @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :pagination-options="{
                                enabled: true, dropdownAllowAll: false, perPageDropdown: [10, 20, 50, 100, 200], nextLabel: this.$t('pagination.next'),
                                prevLabel: this.$t('pagination.Previous'),
                                rowsPerPageLabel: this.$t('pagination.rowsPerPage'),
                                ofLabel: this.$t('pagination.of'),
                            }" :rows="rows" :columns="columns" :sort-options="{
                                enabled: false,
                            }">
                            <template slot="table-row" slot-scope="props">

                                <span v-if="props.column.field == 'name'">
                                    <span> {{ props.row.user.name }}</span>
                                </span>
                                <span v-if="props.column.field == 'email'">
                                    <span> {{ props.row.user.email }}</span>
                                </span>
                                <span v-if="props.column.field == 'mobile_phone'">
                                    <span> {{ props.row.user.mobile_phone }}</span>
                                </span>
                                <span v-if="props.column.field == 'amount'">
                                    <span> {{ props.row.amount }}</span>
                                </span>
                                <span v-if="props.column.field == 'property_type'">
                                    <span> {{ $t('property_detail.' + props.row.property.property_type) }}</span>
                                </span>
                                <span v-if="props.column.field === 'action'" class="text-nowrap">

                                    <b-button size="sm" @click="propertyDetail(props.row.property.id)"
                                        class="mr-1 btn-info text-white">
                                        {{ $t('property_detail.Property_Detail') }}
                                    </b-button>

                                </span>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
        <div></div>
    </section>
</template>

<script>
import Vue, { set } from "vue";
import SortedTablePlugin from "vue-sorted-table";
import API from '@/api'
import moment from 'moment'

Vue.use(SortedTablePlugin, {
    ascIcon: '<i class="ti-arrow-down"></i>',
    descIcon: '<i class="ti-arrow-up"></i>'
});
export default {
    name: 'Spotters',
    data() {
        return {
            searchTime: null,
            isDisabled: false,
            referralNote: 'Referral Note',
            modalContent: '',
            selected: true,
            unSelected: false,
            serverParams: {
                search: "",
                sort: [
                    {
                        field: 'UserID', // example: 'name'
                        type: 'desc' // 'asc' or 'desc'
                    }
                ],
                page: 1, // what page I want to show
                perPage: 10 // how many items I'm showing per page
            },
            columns: [
                {
                    label: 'Guest',
                    field: 'name',
                    filterable: false,
                },
                {
                    label: 'Guest Email',
                    field: 'email',
                    sortable: false,
                },
                {
                    label: 'Guest Contact',
                    field: "mobile_phone",
                    sortable: false,
                },
                {
                    label: 'Amount',
                    field: "amount",
                    sortable: false,
                },
                {
                    label: 'Property Type',
                    field: "property_type",
                    sortable: false,
                },
                {
                    label: 'Action',
                    field: "action",
                    sortable: false,
                },
            ],
            isLoading: false,
            rows: [],
            totalRecords: 0,
            spots: [],
            ReferredBy: new Map(),
            form: {
                ReferredID: '',
            },
        };
    },
    methods: {
        updateColumnLabels() {
            this.columns.forEach(col => {
                switch (col.field) {
                    case 'name':
                        col.label = this.$t('booking.Guest');
                        break;
                    case 'email':
                        col.label = this.$t('booking.Guest_Email');
                        break;
                    case 'mobile_phone':
                        col.label = this.$t('booking.Guest_Contact');
                        break;
                    case 'amount':
                        col.label = this.$t('booking.Amount');
                        break;
                    case 'property_type':
                        col.label = this.$t('properties_list.Property_Type');
                        break;
                    case 'action':
                        col.label = this.$t('properties_list.action');
                        break;
                    default:
                        break;
                }
            });
        },
        propertyDetail(id) {
            this.$router.push({ name: 'properties_detail', params: { id: id } });
        },

        loadItems() {
            API.getPropertyOwnerBookings(this.serverParams,
                data => {
                    this.totalRecords = data.totalRecords;
                    this.rows = data.data;
                    this.isLoading = false;
                }, err => {
                    console.log(err)
                    this.isLoading = false;
                })
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.loadItems();
        },
        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage });
            this.loadItems();
        },
        errorMsg(err) {
            console.log(err);
            this.$showNotification({ message: 'Failed system error' }, "error")
        }
    },
    computed: {
        bokkingguest() {
            return this.$t('booking.Guest');
        },
        bokkingguestemail() {
            return this.$t('booking.Guest_Email');
        },
        bokkingguestcontact() {
            return this.$t('booking.Guest_Contact');
        },
        bokkingamount() {
            return this.$t('booking.Amount');
        },
        propertytype() {
            return this.$t('properties_list.Property_Type');
        },
        action() {
            return this.$t('properties_list.action');
        },

    },
    mounted() {
        this.isLoading = true;
        Vue.prototype.$EventBus.$on('changeLanguage', this.updateColumnLabels);
        this.loadItems();
        this.updateColumnLabels();
    },
    watch: {
        '$i18n.locale': function (newLocale, oldLocale) {
            this.columns.find(col => col.field === 'name').label = this.bokkingguest;
            this.columns.find(col => col.field === 'email').label = this.bokkingguestemail;
            this.columns.find(col => col.field === 'mobile_phone').label = this.bokkingguestcontact;
            this.columns.find(col => col.field === 'amount').label = this.bokkingamount;
            this.columns.find(col => col.field === 'property_type').label = this.propertytype;
            this.columns.find(col => col.field === 'action').label = this.action;
        }
    }
}
</script>