<canvas ref="barChart"></canvas>

<script>
import {
  Bar
} from 'vue-chartjs'

export default {
  extends: Bar,
  props: ['chartData', 'labels'],
  data() {
    return {
      datacollection: {
        labels: this.labels,
        datasets: [{
          label: 'Total price',
          data: this.chartData,
          backgroundColor: 'rgb(112 211 255)', // Single color for all bars
          borderColor: 'rgba(54, 162, 235, 1)', // Same border color for all bars
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              fontColor: 'black', // Change Y-axis text color to black
              fontSize: 12, // Optional: adjust font size
              fontStyle: '300' // Add light font weight (300)
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [{
            ticks: {
              beginAtZero: true,
              fontColor: 'black', // Change X-axis text color to black
              fontSize: 12, // Optional: adjust font size
              fontStyle: '300' // Add light font weight (300)
            },
            gridLines: {
              display: true
            }
          }]
        },
        legend: {
          display: true,
          labels: {
            fontColor: 'black', // Change legend text color to black
            fontSize: 12, // Optional: adjust font size
            fontStyle: '300' // Add light font weight (300)
          }
        },
        elements: {
          point: {
            radius: 0
          }
        },
        responsive: true,
        maintainAspectRatio: false,
      }
    }
  },
  mounted() {
    this.renderChart(this.datacollection, this.options)
  },
  watch: {
    chartData(newVal) {
      if (newVal) {
        // Update the dataset data
        this.datacollection.datasets[0].data = newVal
        this.renderChart(this.datacollection, this.options)
      }
    }
  }
}
</script>

<style>
canvas {
  height: 100% !important; /* Adjust height here */
  min-height: 500px;
}
</style>
