<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <div class="row">
            <div class="col-xl-12">
                <div class="left-card m-b-30">
                    <div class="Customer-card-body">
                        <div v-if="regMsgTextErr != ''">
                            <div class="alert-message md-form">
                                <div class="alert alert-danger">{{ regMsgTextErr }}</div>
                            </div>
                        </div>
                        <div v-if="regMsgTextSucc != ''">
                            <div class="alert-message md-form">
                                <div class="alert alert-success">{{ regMsgTextSucc }}</div>
                            </div>
                        </div>
                        <div class="card" v-if="currStep == 1">
                            <div class="card-heading">
                                <h5>User information</h5>
                            </div>
                            <div class="card-body md-form">
                                <div class="form-group">
                                    <label>First Name<span class="text-red">*</span></label>
                                    <input type="text" class="form-control" v-model="firstName">
                                </div>
                                <div class="form-group">
                                    <label>Last Name<span class="text-red">*</span></label>
                                    <input type="text" class="form-control" v-model="lastName">
                                </div>
                                <div class="form-group">
                                    <label>Date Of Birth<span class="text-red">*</span></label>
                                    <input type="date" class="form-control" v-model="dob">
                                </div>
                                <div class="form-group">
                                    <label for="country" class="form-label">Country<span class="text-red">*</span></label>
                                    <select id="country" name="country" v-model="country"
                                        class="form-control mb-3">
                                        <option value="" disabled>Select an option</option>
                                        <option v-for="country in countries" :key="country.id" :value="country.iso">
                                            {{ country.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label>City<span class="text-red">*</span></label>
                                    <input type="text" class="form-control" v-model="city">
                                </div>
                                <div class="form-group" v-if="country == 'US'">
                                    <label>State<span class="text-red">*</span></label>
                                    <input type="text" class="form-control" v-model="state">
                                </div>
                                <div class="form-group">
                                    <label>Street Address<span class="text-red">*</span></label>
                                    <input type="text" class="form-control" v-model="streetAddress">
                                </div>
                                <div class="form-group">
                                    <label>Postal Code<span class="text-red">*</span></label>
                                    <input type="text" class="form-control" v-model="postalCode">
                                </div>
                            </div>
                        </div>
                        <div class="card second-step" v-if="currStep == 2">
                            <div class="card-heading">
                                <h5>Bank Information</h5>
                            </div>
                            <div class="card-body md-form">
                                <div class="form-group">
                                    <label>Account holder name<span class="text-red">*</span></label>
                                    <input type="text" class="form-control" v-model="accountHolderName">
                                </div>
                                <div class="form-group" v-if="country == 'US'">
                                    <label>Routing Number<span class="text-red">*</span></label>
                                    <input type="number" class="form-control" v-model="routingNumber">
                                </div>
                                <div class="form-group">
                                    <label>Account Number<span class="text-red">*</span></label>
                                    <input type="text" class="form-control" v-model="accountNumber">
                                </div>
                            </div>
                        </div>
                        <div class="card" v-if="currStep == 3">
                            <div class="card-heading">
                                <h5>Identity Verification</h5>
                            </div>
                            <div class="card-body md-form step-five">
                                <div class="form-group">
                                    <label for="images">Upload Front ID Images:</label>
                                    <input type="file" id="frontID"  @change="previewImage"
                                        accept="image/*">

                                    <div v-if="idImageFront" class="images-listing">
                                        <!-- <img v-if="imagePreview.path" :src="imagePreview.path" alt="Preview">
                                        <img v-else :src="imagePreview" alt="Preview"> -->
                                        <div class="images-card">
                                            <img :src="idImageFront" alt="Preview">
                                            <button type="button" class="btn btn-primary" @click="removeImage('frontID')">
                                                <i class="fas fa-trash-alt"></i> {{ $t('property_detail.Remove') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" v-if="currStep == 4">
                            <div class="card-heading">
                                <h5>Identity Verification</h5>
                            </div>
                            <div class="card-body md-form step-five">
                                <div class="form-group">
                                    <label for="images">Upload Back ID Images:</label>
                                    <input type="file" id="backID" @change="previewImage"
                                        accept="image/*">

                                    <div v-if="idImageBack" class="images-listing">
                                        <div class="images-card">
                                            <img :src="idImageBack" alt="Preview">
                                            <button type="button" class="btn btn-primary" @click="removeImage('backID')">
                                                <i class="fas fa-trash-alt"></i> {{ $t('property_detail.Remove') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card laststep" v-if="currStep == 5">
                            <div class="card-heading">
                                <h5>{{ $t('property_detail.all_done') }}!</h5>
                            </div>
                            <div class="md-form">
                                <button v-if="isLoading == true" type="button" class="btn btn-primary" @click="submit()" 
                                        style="margin-left: 43% !important; height: 46px; min-width: 110px; 
                                            display: flex; justify-content: center;" disabled>
                                    <img src="https://staging.cheapcomforts.com/public/images/loader.gif" 
                                        style="height: 20px;">
                                </button>
                                <button v-else type="button" class="btn btn-primary" @click="submit()">
                                    <span>Submit</span>
                                </button>
                            </div>
                        </div>
                        <div class="card" v-if="currStep < 6">
                            <div class="footer-buttons md-form">
                                <button id="bckBtn" name="bckBtn" class="btn btn-default pull-left" v-if="currStep > 1"
                                    @click="bckBtn" :disabled="regBusy">
                                    <i class="fa fa-angle-left"></i> <span>{{ $t('register.back') }}</span>
                                </button>
                                <button id="regSub" name="regSub" class="btn btn-primary pull-right" @click="regSub"
                                    :disabled="regBusy" v-if="currStep !== 5">{{ regBtnText }}</button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div></div>
    </section>
</template>

<script>
import Vue from "vue";
import API from '../api'
import Snotify, { SnotifyPosition } from 'vue-snotify'
import { validationMixin } from "vuelidate";
import postalCodes from 'postal-codes-js'
import iban from 'iban';
import { validateRoutingNumber } from 'bank-routing-number-validator';


const options = {
    toast: {
        position: SnotifyPosition.rightTop,
    }
}
Vue.use(Snotify, options)
export default {
    mixins: [validationMixin],

    data() {
        return {
            currStep: 1,
            regBtnText: this.$t('register.next'),
            regMsgTextErr: '',
            regMsgTextSucc: '',
            region: '',
            firstName: '',
            lastName: '',
            dob: new Date(),
            streetAddress: '',
            city: '',
            state: '',
            country: '',
            postalCode: '',
            accountHolderName: '',
            routingNumber: null,
            accountNumber: '',
            idImageFront: '',
            idImageBack: '',
            countries: [],
            postalCodeRegex: null,
            isLoading: false,
        };
    },

    mounted() {
        API.getAllCountries(data => {
            this.countries = data.data;
        }, error => {
            console.log(error);
        });
        this.region = localStorage.getItem('region');
    },

    methods: {

        previewImage(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    if(event.target.id == 'frontID') {
                        this.idImageFront = e.target.result;
                    }
                    if(event.target.id == 'backID') {
                        this.idImageBack = e.target.result;
                    }
                };
                reader.readAsDataURL(file);
            }
        },

        removeImage(side) {
            if(side == 'frontID') {
                this.idImageFront = null;
            }
            if(side == 'backID') {
                this.idImageBack = null;
            }
        },


        bckBtn() {
            if (this.currStep > 1) {
                this.regMsgTextErr = '';
                this.regBtnText = this.$t('register.next');
                this.currStep = this.currStep - 1;
            }
        },

        submit() {
            this.isLoading = true;
            API.addBankinfo({
                'first_name' : this.firstName,
                'last_name' : this.lastName,
                'country' : this.country,
                'city' : this.city,
                'street_address' : this.streetAddress,
                'state' : this.state,
                'postal_code' : this.postalCode,
                'dob' : this.dob,
                'ac_holder_name' : this.accountHolderName,
                'account_number' : this.accountNumber,
                'id_front': this.idImageFront,
                'id_back': this.idImageBack,
                'routing_number': this.country === 'US' ? this.routingNumber : '',
            }, data => {
                this.isLoading = false;
                this.$router.push({ name: 'home' });
            }, error => {
                this.isLoading = false;
                this.regMsgTextErr = error.error;
                return;
            });
        },

        regSub() {
            this.regMsgTextErr = '';
            if (this.currStep === 1) {
                if (!this.firstName) {
                    this.regMsgTextErr = 'First Name is required';
                    return;
                }
                if (!this.lastName) {
                    this.regMsgTextErr = 'Last Name is required';
                    return;
                }
                if (!this.dob) {
                    this.regMsgTextErr = 'Date of Birth is required';
                    return;
                }
                if (!this.country) {
                    this.regMsgTextErr = 'Country is required';
                    return;
                }
                if (!this.streetAddress) {
                    this.regMsgTextErr = 'Street Address is required';
                    return;
                }
                if (!this.city) {
                    this.regMsgTextErr = 'City is required';
                    return;
                }
                if (this.country == 'US' && !this.state) {
                    this.regMsgTextErr = 'State is required';
                    return;
                }
                if (!this.postalCode) {
                    this.regMsgTextErr = 'Postal code is required';
                    return;
                }

                if (this.postalCode) {
                    const verifyPostalCode = postalCodes.validate(this.country, this.postalCode);
                    if (verifyPostalCode !== true) {
                        this.regMsgTextErr = verifyPostalCode;
                    return
                    }
                }

                this.currStep = 2;
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep === 2) {
                if (!this.accountHolderName) {
                    this.regMsgTextErr = 'Account holder name is required';
                    return;
                }
                if (this.city == 'US') {
                    console.log(this.routingNumber);
                    if (!this.routingNumber) {
                        this.regMsgTextErr = 'Routing number is required';
                        return;
                    }
                    if (!this.accountNumber) {
                        this.regMsgTextErr = 'Account Number is required';
                        return;
                    }
                    // if (validateRoutingNumber(this.routingNumber)) {
                    //     console.log('Valid Routing Number');
                    // } else {
                    //     this.regMsgTextErr = 'Invalid Routing Number';
                    //     return;
                    // }
                    if (iban.isValid(this.accountNumber)) {
                        console.log('valid IBAN');
                    } else {
                        this.regMsgTextErr = 'Invalid Account Number';
                        return;
                    }
                } else {
                    if (!this.accountNumber) {
                        this.regMsgTextErr = 'Account Number is required';
                        return;
                    }
                    if (iban.isValid(this.accountNumber)) {
                        console.log('valid IBAN');
                    } else {
                        this.regMsgTextErr = 'Invalid Account Number';
                        return;
                    }
                }
                // if (this.city == 'US' && !this.routingNumber) {
                //     this.regMsgTextErr = 'Routing number is required';
                //     return;
                // }
                // if (!this.accountNumber) {
                //     this.regMsgTextErr = 'Account Number is required';
                //     return;
                // }
                this.regBtnText = this.$t('register.next');
                this.currStep = 3;
                this.regBtnText = this.$t('register.next');

            } else if (this.currStep == 3) {
                this.regBtnText = this.$t('register.next');
                if (!this.idImageFront) {
                    this.regMsgTextErr = 'Front ID image is required';
                    return;
                }
                this.currStep = 4;
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep == 4) {
                this.regBtnText = this.$t('register.next');
                if (!this.idImageBack) {
                    this.regMsgTextErr = 'Back ID image is required';
                    return;
                }
                this.currStep = 5;
                this.regBtnText = this.$t('register.next');
            }else if (this.currStep == 5) {
                this.currStep = 6;
                this.regBtnText = this.$t('register.next');
            }
        },

    }
}
</script>

<style></style>

<style>
.checkbox-group .custom-control-label {
    padding-top: 5px !important;
}

.custom-label {
    font-size: 0.875rem;
}

.checkbox-group .b-form-checkbox {
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
    vertical-align: middle;
}

.Customer-card-body {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.Customer-card-body .card {
    border: 0;
}

.Customer-card-body .card .card-heading h5 {
    margin: 0;
    font-size: 1.4rem;
    line-height: 1.2;
}

.Customer-card-body .card-body {
    border: 0;
    border-radius: 0;
    padding: 20px;
}

/* .Customer-card-body .card-body .form-group .form-check {
    margin-top: 0;
    margin-bottom: 15px;
    width: 50%;
} */

.Customer-card-body .card-body .form-group .radio-buttons-wrapper {
    padding-left: 1.25rem;
    display: flex;
    flex-wrap: wrap;
}

.Customer-card-body .card .card-heading {
    margin-bottom: 0;
}

.footer-buttons.md-form {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-buttons.md-form .btn.btn-default {
    border: 1px solid #B6B6B6;
    background: #B6B6B6;
    width: 105px;
    color: #fff;
    margin-right: 15px;
    font-weight: 500;
    border-radius: 5px;
    height: 44px;
}

.footer-buttons.md-form .btn.btn-default:hover {
    background: #70d3ff;
    border-color: #70d3ff;
    color: #fff;
}

.footer-buttons.md-form .btn.btn-primary {
    border-radius: 5px !important;
}


.Customer-card-body .card.second-step .card-body .form-group .radio-buttons-wrapper {
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.Customer-card-body .card.second-step .card-body .form-group .radio-buttons-wrapper label.form-check-label {
    margin-left: 0;
}

.Customer-card-body .card.second-step .card-body .form-group .radio-buttons-wrapper label.form-label {
    width: 100%;
    font-weight: 500;
}

.Customer-card-body .card.second-step .card-body .form-group .form-check {
    width: 70px;
    padding-left: 20px;
}

.Customer-card-body .card-body .form-group .form-control {
    border-radius: 35px;
}

/* .Customer-card-body .card-body .form-group .form-control {
    border-radius: 35px;
    border: 1px solid #676A7D;
} */

.Customer-card-body .card-body .form-group .checkbox-group .form-check .form-check-label {
    margin: 0;
}

.Customer-card-body .card-body .form-group .checkbox-group .form-check {
    padding-left: 20px;
}

.Customer-card-body .card-body .form-group .checkbox-group .custom-label {
    font-weight: 500;
}

.Customer-card-body .card-body.step-five .form-group label {
    display: block;
    font-weight: 500;
    margin-bottom: 10px;
}

.Customer-card-body .card-body.step-five .form-group {
    margin: 0;
}

.images-listing {
    display: flex;
    flex-wrap: wrap;
    gap: 22px;
}

/* .images-listing .images-card {
    margin-top: 15px;
    width: 100%;
    max-width: 400px;
} */

/* .images-listing .images-card img {
    max-width: 100%;
    height: auto;
} */

/* .images-listing .images-card button.btn {
    width: 100% !important;
    margin-top: 5px !important;
    border-radius: 5px !important;
    height: 30px;
    padding: 0 !important;
    font-size: 14px !important;
} */

.Customer-card-body .card-body.step-five .form-group input[type=file] {
    font-size: 14px;
}

.Customer-card-body .card-body.step-five .form-group input[type=file]::file-selector-button {
    margin-right: 20px;
    border: none;
    background: #70d3ff;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background .2s ease-in-out;
}

/* .Customer-card-body .card-body .form-group textarea.form-control {
    padding: 10px;
    height: 104px;
    border-radius: 15px;
} */

.Customer-card-body .card.laststep {
    border: 1px solid #bcbcbf;
    border-radius: 10px;
    padding: 30px 20px;
    text-align: center;
}

.Customer-card-body .card-body .form-group label {
    font-weight: 500;
}

.guest-content {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    margin-bottom: 15px;
    border-right: 0 !important;
}

.guest-content:last-child {
    margin-bottom: 0;
}

/* .guest-content .btn.btn-info {
    padding: 0;
    width: 30px;
    height: 30px;
    font-size: 24px;
    border-radius: 0;
    background: transparent;
    border: 1px solid rgb(192 194 196);
    color: #000;
    border-radius: 100px;
} */

.guest-content .form-control {
    padding: 0;
    height: 30px;
    border: 0;
    text-align: center;
    width: 60px;
    appearance: none;
}

.guest-content .form-control::-webkit-outer-spin-button,
.guest-content .form-control::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.form-group.quality-fileds {
    display: flex;
    align-items: center;
    gap: 25px;
}
.form-group.quality-fileds .guest-content {
    margin: 0;
    gap: 10px;
}
.form-group.quality-fileds .guest-content .input-group {
    gap: 3px;
}
.form-group.quality-fileds .guest-content label {
    margin: 00;
}
</style>
