<template>
    <section class="login">
        <div class="container-scroller">
            <div class="container-fluid page-body-wrapper full-page-wrapper">
                <div class="content-wrapper d-flex align-items-center auth">
                    <div class="row w-100 flex-grow">
                        <div class="col-xl-4 col-lg-6 mx-auto">
                            <div class="auth-form-light text-left">
                                <div class="brand-logo text-center">
                                    <img src="../public/assets/images/cheapcomfort-logo.svg"/>
                                </div>
                                <div class="auth-form">
                                    <form>
                                        <div class="form-fields">
                                            <b-form-group>
                                                <b-form-input
                                                        id="input-1"
                                                        type="email"
                                                        placeholder="Email Address"
                                                        v-model="$v.email.$model"
                                                        :state="$v.email.$dirty ? !$v.email.$error : null"
                                                        aria-describedby="input-2-live-feedback"
                                                ></b-form-input>
                                                <b-form-invalid-feedback v-if="!$v.email.required" id="input-2-live-feedback">This is a required field.
                                                </b-form-invalid-feedback>
                                                <b-form-invalid-feedback v-if="!$v.email.email" id="input-2-live-feedback">
                                                    Invalid email format
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        
                                        <div class="form-group text-center m-t-20 mb-2 mt-3">
    
                                            <button type="button" :disabled="isDisabled"
                                                    class="btn btn-primary btn-block btn-lg waves-effect waves-light"
                                                    @click="login()">
                                                Send Email
                                            </button>
                                        </div>
                                        <div class="social-login text-center">
                                            <router-link to="/Admin" class="btn">
                                                <i class="fa fa-user m-right-5"></i><span>Login</span>
                                            </router-link>
                                        </div>
                                    </form>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <!-- content-wrapper ends -->
            </div>
            <!-- page-body-wrapper ends -->
            <Footer/>
        </div>
        <div class="col-md-4 col-sm-6 grid-margin stretch-card" v-if="isHide">
            <div class="loader-demo-box">
                <div class="dot-opacity-loader">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
    import API from '@/api'
    import {validationMixin} from "vuelidate";
    import {required, minLength, email, sameAs} from "vuelidate/lib/validators";
    import Footer from "../layout/partials/Footer";

    export default {
        name: "Forget-Password",
        mixins: [validationMixin],
        components :{
            Footer
        },
        data() {
            return {
                email: '',
                disabled: '',
                isDisabled: false,
                isHide: false,
            };
        }, validations: {
            email: {
                email,
                required,
            }
        },
        methods: {
            login() {

                this.$v.email.$touch();
                if (this.$v.email.$anyError) {
                    return;
                }
                this.isDisabled = true;
                let email = this.email
                let data = {
                    email: email,
                }
                API.checkEmail(
                    data,
                    data => {
                        if (data.status == 200) {
                            this.$swal({
                                type: 'success',
                                title: 'Success',
                                text: data.message,
                            })
                        } else {
                            this.$swal({
                                type: 'error',
                                title: 'Oops...',
                                text: data.message,
                            })
                        }
                        this.isDisabled = false;
                    },
                    err => {
                        this.isDisabled = false;
                        this.$swal({
                            type: 'error',
                            title: 'Oops...',
                            text: err.message,
                        })
                    }
                )
            }
        }
    }
</script>
