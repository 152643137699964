import Vue from "vue";
import Router from "vue-router";
import API from "@/api";
import admin from "./admin";
import guest from "./guest";
Vue.use(Router);

// Utility functions
const isLoggedIn = () => localStorage.getItem("isLoggedin") !== null;
const getUserPermissions = () =>
  JSON.parse(localStorage.getItem("userPermissions")) || [];
const getUserDetails = () => JSON.parse(localStorage.getItem("userDetails"));
const getUserToken = () => localStorage.getItem("userLoginToken");

const loginCheck = (data, next, to) => {
  if (data.token) {
    localStorage.setItem("isLoggedin", data.token);
    localStorage.setItem("userPermissions", JSON.stringify(data.permission));
    Vue.prototype.$session.set("isLoggedin", data.token);
    Vue.prototype.$session.set(
      "userPermissions",
      JSON.stringify(data.permission)
    );

    // Redirect only if not already on the target route
    next(to.name === "" ? { name: "dashboard" } : { name: to.name });
  } else {
    next({ name: "login" });
  }
};

const redirectToLoginIfNeeded = (next, to) => {
  if (
    to.name !== "login" &&
    to.name !== "forget-password" &&
    to.name !== "reset-password" &&
    !isLoggedIn()
  ) {
    next({ name: "login" });
  } else if (to.name === "login" && isLoggedIn()) {
    next({ name: "dashboard" });
  } else {
    next();
  }
};

const checkPermissions = (next, to) => {
  const permissions = getUserPermissions();
  if (
    permissions.includes("super_admin") ||
    permissions.includes("property_owner")
  ) {
    // If the route path doesn't match a valid route, redirect to dashboard
    if (to.path == "/Admin" || to.path == "/Admin/") {
      next({ name: "dashboard" });
    } else {
      next();
    }
  } else {
    // () => next({ name: "login" })
    next({ name: "login" });
  }
};

const handleHomeRoutes = (next, to) => {
  const userToken = getUserToken();
  if (to.name === "guest_login" && userToken) {
    next({ name: "home" });
  } else if (to.name === "home" && !userToken) {
    next({ name: "guest_login" });
  } else if (to.name === "home/signup" && !userToken) {
    next({ name: "guest_signup" });
  } else {
    next();
  }
};

const handleAdminRoutes = (next, to) => {
  const email = localStorage.getItem("email");
  const password = localStorage.getItem("password");

  if (email && password && !isLoggedIn()) {
    const data = { email, password };
    API.login(
      data,
      (response) => loginCheck(response, next, to),
      () => next({ name: "login" })
    );
  } else if (
    to.name === "forget-password" &&
    to.name !== "reset-password" &&
    !isLoggedIn()
  ) {
    next({ name: "forget-password" });
  } else if (!isLoggedIn()) {
    redirectToLoginIfNeeded(next, to);
  } else {
    checkPermissions(next, to);
  }
};

const router = new Router({
  linkExactActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  mode: "history",
  base: "/",
  meta: { title: "Cheap Comforts" },
  routes: [...admin, ...guest],
});

router.beforeEach((to, from, next) => {
  // Set document title
  document.title = "Cheap Comforts";

  // Check authentication status
  const isAuthenticatedToAccessWebsite = localStorage.getItem(
    "isAuthenticatedToAccessWebsite"
  );

  if (
    isAuthenticatedToAccessWebsite == "true"
  ) {
    // If user is authenticated, handle the route based on the path
    if (to.path.startsWith("/Admin")) {
      handleAdminRoutes(next, to);
    }
    // Handle home routes
    else if (to.path.startsWith("/home")) {
      handleHomeRoutes(next, to);
    }
    // For all other routes
    else {
      next();
    }
  } else {
    if (to.name !== "websiteProtection" && to.path !== "/websiteProtection") {
      console.log("User is not Authenticated to access the website");
      next({ name: "websiteProtection" });
    } else {
      next(); // If already on the websiteProtection page, just continue
    }
  }
});

export default router;
