<template>
    <b-navbar id="template-header"
        class="default-layout-navbar admin-header col-lg-12 col-12 p-0 fixed-top d-flex flex-row" toggleable="lg">
        <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
            <router-link class="navbar-brand brand-logo m-0" to="/">
                <!-- <img src="@/public/assets/images/watch-logo.png" alt="logo"/> -->
                <img src="@/public/assets/images/cheapcomfort-logo.svg" alt="image" class="site-logo" />
            </router-link>
            <router-link class="navbar-brand brand-logo-mini" to="/">
                <!-- <img src="@/public/assets/images/watch-logo.png" alt="logo"/> -->
                <img src="@/public/assets/images/cheapcomfort-logo.svg" alt="image" class="site-logo" />
            </router-link>
        </div>
        <div class="navbar-menu-wrapper d-flex align-items-center justify-content-between ml-auto ml-lg-0">
            <!-- <button
                    class="navbar-toggler navbar-toggler align-self-center d-lg-block"
                    type="button"
                    @click="toggleSidebar()"
            >
                <span class="ti-layout-grid2"></span>
            </button> -->

            <div class="nav-search d-lg-block">
                <div class="page-title">
                    <a href="/">
                        <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M36.5001 16.7972L18.5527 0.8125L0.611096 16.7972C0.0165054 17.3283 -0.0354491 18.2403 0.495641 18.8349C0.778504 19.1524 1.17682 19.3198 1.57514 19.3198C1.91573 19.3198 2.26209 19.1986 2.53341 18.9562L4.06318 17.5938V32.2911C4.06318 34.2365 5.64491 35.8125 7.58454 35.8125H29.5209C31.4663 35.8125 33.0422 34.2308 33.0422 32.2911V17.5938L34.572 18.9562C35.1666 19.4872 36.0787 19.4353 36.6098 18.8407C37.1409 18.2461 37.0889 17.334 36.4943 16.8029L36.5001 16.7972ZM15.1583 32.9261V22.3043C15.1583 21.9522 15.447 21.6693 15.7933 21.6693H21.3121C21.6642 21.6693 21.9471 21.958 21.9471 22.3043V32.9261H15.1583ZM30.1616 32.2911C30.1616 32.6433 29.873 32.9261 29.5266 32.9261H24.8392V22.3043C24.8392 20.3589 23.2575 18.783 21.3178 18.783H15.7991C13.8537 18.783 12.2778 20.3647 12.2778 22.3043V32.9261H7.59031C7.23818 32.9261 6.95532 32.6375 6.95532 32.2911V15.0192L18.5527 4.68022L30.1616 15.0192V32.2911Z" fill="#70D3FF"/>
                        </svg>
                    </a>   
                </div>
            </div>
            <b-navbar-nav class="navbar-nav-right ml-auto">

                <!-- <b-nav-item-dropdown right class="preview-list mr-1 d-none">
                        <template slot="button-content">
                            <div class="nav-link count-indicator dropdown-toggle">
                                <i class="ti-email mx-0 d-none"></i>
                            </div>
                        </template>
                        <h6 class="p-3 mb-0">Messages</h6>
                        <b-dropdown-item class="preview-item">
                            <div class="preview-thumbnail">
                                <img src="https://via.placeholder.com/36X36" alt="image" class="profile-pic" />
                            </div>
                            <div class="preview-item-content flex-grow">
                                <h6 class="preview-subject ellipsis font-weight-normal">David Grey</h6>
                                <p class="font-weight-light small-text text-muted mb-0">The meeting is cancelled</p>
                            </div>
                        </b-dropdown-item>
                        <b-dropdown-item class="preview-item">
                            <div class="preview-thumbnail">
                                <img src="https://via.placeholder.com/36X36" alt="image" class="profile-pic" />
                            </div>
                            <div class="preview-item-content flex-grow">
                                <h6 class="preview-subject ellipsis font-weight-normal">Tim Cook</h6>
                                <p class="font-weight-light small-text text-muted mb-0">New product launch</p>
                            </div>
                        </b-dropdown-item>
                        <b-dropdown-item class="preview-item">
                            <div class="preview-thumbnail">
                                <img src="https://via.placeholder.com/36X36" alt="image" class="profile-pic" />
                            </div>
                            <div class="preview-item-content flex-grow">
                                <h6 class="preview-subject ellipsis font-weight-normal">Johnson</h6>
                                <p class="font-weight-light small-text text-muted mb-0">Upcoming board meeting</p>
                            </div>
                        </b-dropdown-item>
                        </b-nav-item-dropdown>
                        <b-nav-item-dropdown right class="preview-list">
                            <template slot="button-content">
                            <div class="nav-link count-indicator dropdown-toggle">
                                                    <i class="ti-bell mx-0"></i> 
                                    <span class="count"></span> 
                                <button type="button" class="btn btn-primary btn-sm" v-if="super_admin_email"
                                        @click="loginAs">Back to Super Admin
                                </button>
                            </div>
                        </template>
                    </b-nav-item-dropdown> -->
                <!-- <b-nav-item-dropdown right>
                    <template #button-content>
                        <i class="ti-world"></i>{{ selectedLanguage }}
                    </template>
                    <b-dropdown-item @click="changeLanguage('en')">English</b-dropdown-item>
                    <b-dropdown-item @click="changeLanguage('es')">Spanish</b-dropdown-item>
                    <b-dropdown-item @click="changeLanguage('de')">German</b-dropdown-item>
                    <b-dropdown-item @click="changeLanguage('fr')">French</b-dropdown-item>

                </b-nav-item-dropdown> -->
                <b-nav-item-dropdown right class="nav-profile">
                    <template slot="button-content">
                        <span class="nav-link dropdown-toggle" id="profileDropdown" href="javascript:void(0);"
                            data-toggle="dropdown" aria-expanded="false">
                            <div class="nav-profile-img">
                                <img src="https://staging.cheapcomforts.com/public/simp.svg" alt="image" />
                            </div>
                        </span>
                    </template>

                    <b-dropdown-item class="preview-item">
                        <router-link to="/edit-profile">
                            <i class="ti-user text-primary"></i> Edit Profile
                        </router-link>
                    </b-dropdown-item>
                    <b-dropdown-item class="preview-item">
                        <router-link to="/settings">
                            <i class="ti-settings text-primary"></i> Settings
                        </router-link>
                    </b-dropdown-item>
                    <b-dropdown-item class="preview-item" @click="logout()">
                        <i class="ti-power-off text-primary"></i> Logout
                    </b-dropdown-item>
                </b-nav-item-dropdown>
                <!--                <b-nav-item class="nav-logout d-none d-lg-block nav-settings">-->
                <!--                    <i class="ti-more" @click="togglesettingsPannel()"></i>-->
                <!--                </b-nav-item>-->
            </b-navbar-nav>
            <button class="navbar-toggler navbar-toggler-right align-self-center" type="button"
                @click="toggleMobileSidebar()">
                <span class="mdi mdi-menu"></span>
            </button>
        </div>
    </b-navbar>
</template>

<script>
import Vue from 'vue';
import router from "../../router"
import API from '@/api'

export default {
    name: "app-header",
    data() {
        return {
            selectedLanguage: 'EN',
            clientSettings: [],
            name: '',
            super_admin_email: '',
        };
    },

    methods: {
        changeLanguage(language) {
            // Your language change logic here
            Vue.prototype.$EventBus.$emit('changeLanguage', language);
            this.selectedLanguage = language.toUpperCase();

            console.log(`Selected language: ${language}`);
        },
        toggleSidebar: () => {
            document.querySelector("body").classList.toggle("sidebar-icon-only");
        },
        toggleMobileSidebar: () => {
            document.querySelector("#sidebar").classList.toggle("active");
        },
        togglesettingsPannel: () => {
            document.querySelector("#right-sidebar").classList.toggle("open");
        },
        logout() {
            var admin_user = JSON.parse(localStorage.getItem('user'));
            var citizen_user = JSON.parse(localStorage.getItem('spotterClientSettings'));
            if (citizen_user) {
                if (citizen_user['user_id'] === admin_user['id']) {
                    this.$session.clear();
                    localStorage.removeItem('userPermissions');
                    localStorage.removeItem('isLoggedin');
                    localStorage.removeItem('email');
                    localStorage.removeItem('password');
                    localStorage.removeItem('super_admin_email');
                    router.push("/Admin");
                } else {
                    API.logout(
                        data => {
                            localStorage.removeItem('super_admin_email');
                            this.$session.clear();
                            localStorage.removeItem('userPermissions');
                            localStorage.removeItem('isLoggedin');
                            localStorage.removeItem('email');
                            localStorage.removeItem('password');
                            router.push("/Admin");
                        },
                        err => {
                        }
                    )
                }
            } else {
                API.logout(
                    data => {
                        this.$session.clear();
                        localStorage.removeItem('userPermissions');
                        localStorage.removeItem('isLoggedin');
                        localStorage.removeItem('email');
                        localStorage.removeItem('password');
                        router.push("/Admin");
                    },
                    err => {
                    }
                )
            }
        },
        getClientSettings() {
            this.clientSettings = JSON.parse(localStorage.getItem('client_settings'));
            if (this.clientSettings) {
                this.name = this.clientSettings.name;
            }
        },
        loginAs() {
            let data = {
                email: this.super_admin_email
            }
            API.loginAs(data,
                data => {
                    if (typeof data.token !== "undefined") {
                        localStorage.removeItem('super_admin_email');
                        this.super_admin_email = '';
                        localStorage.setItem('isLoggedin', data.token)
                        localStorage.setItem('client_settings', JSON.stringify(data.client_settings))
                        localStorage.setItem('user', JSON.stringify(data.user))
                        localStorage.setItem('userPermissions', JSON.stringify(data.permission))
                        router.push("/dashboard")
                        router.go("/dashboard")
                    }
                },
                err => {
                }
            )
        }
    },
    beforeMount() {
        // this.getClientSettings();
        // this.super_admin_email = localStorage.getItem('super_admin_email');
    },
};
</script>
<style>
.fixed-top,
.horizontal-menu.fixed-on-scroll .bottom-navbar {
    position: sticky !important;
}
</style>