<template>
  <div class="app-container">
    <!-- Layout with two columns: left side for images, right side for password form -->
    <div class="left-side">
      <img src="../public/assets/images/house-image-1.jpg" alt="Image 1" class="image" />
    </div>

    <!-- Password form on the right side -->
    <div class="right-side">
      <div class="welcome-section">
        <div class="main-heading"> 
          <h1>Welcome to </h1>
          <img src="../public/assets/images/cheapcomfort-logo.svg" alt="cheapcomfort" class="logo" />
        </div>
        <div class="login-box">
          <h2>Please enter the code to access the site:</h2>
          <label>Enter Code <span>*</span></label>
          <input
            type="text"
            v-model="password"
            placeholder="Enter your code"
            @keydown.enter="checkPassword"
            :class="{'input-error': passwordError}"
          />
          <button @click="checkPassword" class="submit-btn">Submit</button>
          <p v-if="passwordError" class="error-message">Incorrect code, please try again.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: '',
      isAuthenticated: false,
      passwordError: false,
      correctPassword: 'B93*2jZ82V', // Change this to your desired password
    };
  },
  methods: {
    checkPassword() {
      if (this.password === this.correctPassword) {
        this.isAuthenticated = true; // Grant access
        localStorage.setItem('isAuthenticatedToAccessWebsite', 'true'); // Save authentication state
        this.passwordError = false; // Clear error
        this.$router.push({ name: 'home' });
      } else {
        this.passwordError = true; // Show error message
        this.password = ''; // Clear password field
      }
    },
  },
};
</script>

<style scoped>
/* General page styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Container for the entire layout */
.app-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.left-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  align-items: center;
  width: 50%;
  padding: 30px;
  background-color: #fff;
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}
.welcome-section {
  width: 100%;
  max-width: 400px;
}
.right-side {
  width: 50%;
  padding: 30px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.main-heading {
  width: 100%;
  text-align: center;
  margin-bottom: 25px;
}

.login-box {
  background-color: #fff;
  width: 100%;
  text-align: center;
}

.main-heading h1 {
  font-size: 25px;
  color: #2c3e50;
  line-height: 1.2;
  font-weight: bold;
  text-align: center;
  margin-right: 0;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.logo {
  width: 100%;
  height: auto;
  max-width: 340px;
}

.welcome-section h2 {
  font-size: 16px;
  color: #333;
  margin-bottom: 50px;
  font-weight: 400;
}
.login-box label {
  text-align: left;
  width: 100%;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
}
.login-box label span {
  color: red;
}

.login-box input {
  width: 100%;
  margin-bottom: 20px;
  transition: border-color 0.3s ease;
  border: 1px solid #D7D7D7;
  background: #fff;
  border-radius: 10px;
  font-size: 16px;
  height: 55px;
  padding: 5px 15px;
  color: #959595;
}

input:focus {
  border-color: #3f87f2;
  outline: none;
}

.input-error {
  border-color: #ff4d4d;
}

button.submit-btn {
  background-color: #70d3ff;
  color: white;
  border: none;
  border: 1px solid #70d3ff;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 60px;
  cursor: pointer;
  height: 50px;
  width: 100%;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

button.submit-btn:hover {
  background-color: #fff;
  color: #70d3ff;
}

.error-message {
  color: #ff4d4d;
  font-size: 14px;
  margin-top: 10px;
  display: inline-block;
}

@media (max-width: 768px) {
  .app-container {
    flex-direction: column;
    padding: 20px;
  }

  .left-side, .right-side {
    width: 100%;
  }

  .image {
    margin-bottom: 10px;
  }

  .logo {
    width: 40px; /* Adjust logo size for smaller screens */
  }

  h1 {
    font-size: 30px; /* Adjust title font size for mobile */
    text-align: left;
  }
}

@media (max-width: 600px) {
  .login-box {
    width: 90%;
  }
}
</style>
