<template>
    <section class="tables new-updated-design  px-3 px-md-0 pt-4 pt-md-0">
        <vue-snotify></vue-snotify>
        <div>
            <b-modal id="invoice-modal" ref="invoice-modal" :title="$t('Invoice Details')" size="lg"
                class="invoice-modal">
                <div v-if="selectedInvoice" class="invoice-modal-content">
                    <!-- Invoice Table Section -->
                    <div class="invoice-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>{{ $t('Description') }}</th>
                                    <th>{{ $t('Amount') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{ $t('Per Night Price') }}</td>
                                    <td>{{ selectedInvoice.price_per_night }} {{ selectedInvoice.payment_currency }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{ $t('Tax Fee') }}</td>
                                    <td>{{ selectedInvoice.taxfee }} {{ selectedInvoice.payment_currency }}</td>
                                </tr>
                                <tr>
                                    <td>{{ $t('Host Fee') }}</td>
                                    <td>{{ selectedInvoice.hostFees }} {{ selectedInvoice.payment_currency }}</td>
                                </tr>
                                <tr>
                                    <td>{{ $t('Service Fee') }}</td>
                                    <td>{{ selectedInvoice.servicefee }} {{ selectedInvoice.payment_currency }}</td>
                                </tr>
                                <tr class="total-row">
                                    <td><b>{{ $t('Total Price') }}:</b></td>
                                    <td><b>{{ selectedInvoice['total_price' + currentLanguage] }} {{
                                        selectedInvoice['payment_currency' + currentLanguage] }}</b></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-modal>

        </div>
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card properties-table">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">{{ $t('View Invoices') }}</h4>
                    </div>
                    <div class="card-body">
                        <div class="search-bar mb-3 search-bar-style"> <!-- Added search bar -->
                            <b>{{ $t('Search') }}: </b>
                            <input style="border: 1px solid #dcdfe6;" type="text" v-model="serverParams.search"
                                @input="onSearch" placeholder="Search..." />
                        </div>
                        <vue-good-table mode="remote" @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :pagination-options="{
                                enabled: true, dropdownAllowAll: false, perPageDropdown: [10, 20, 50, 100, 200], nextLabel: this.$t('pagination.next'),
                                prevLabel: this.$t('pagination.Previous'),
                                rowsPerPageLabel: this.$t('pagination.rowsPerPage'),
                                ofLabel: this.$t('pagination.of'),
                            }" :rows="rows" :columns="columns" :sort-options="{
                                enabled: false,
                            }">
                            <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field == 'name'">
                                    <span>{{ props.row.user['name' + currentLanguage] }}</span>
                                </span>
                                <span v-if="props.column.field == 'email'">
                                    <span>{{ props.row.user['email' + currentLanguage] }}</span>
                                </span>
                                <span v-if="props.column.field == 'mobile_phone'">
                                    <span>{{ props.row.user['mobile_phone' + currentLanguage] }}</span>
                                </span>
                                <span v-if="props.column.field == 'status'">
                                    <span>{{ getStatusText(props.row['status']) }}</span>
                                </span>
                                <!-- <span v-if="props.column.field == 'start_date'">
                                    <span>{{ props.row["start_date" + currentLanguage] }}</span>
                                </span>
                                <span v-if="props.column.field == 'end_date'">
                                    <span>{{ props.row["end_date" + currentLanguage] }}</span>
                                </span>
                                <span v-if="props.column.field == 'total_price'">
                                    <span>{{ props.row["payment_currency" + currentLanguage] }} {{
                                        props.row["total_price" + currentLanguage] }}</span>
                                </span> -->
                                <span v-if="props.column.field === 'action'" class="text-nowrap properties-btns">
                                    <b-button @click="viewInvoiceDetails(props.row.payments)" class="mr-1 edit-btn">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="17"
                                            height="17" fill="white">
                                            <path
                                                d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                                        </svg>

                                    </b-button>
                                    <b-button v-if="props.row.status == 1" class="mr-1 edit-btn">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="17" width="17"
                                            viewBox="0 0 24 24">
                                            <g>
                                                <!-- Envelope -->
                                                <path style="fill:white;"
                                                    d="M20,4H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6c0-1.1-0.9-2-2-2z M18,10l-6,4l-6-4V6l6,4l6-4V10z" />
                                                <!-- Checkmark -->
                                                <path style="fill:white;"
                                                    d="M9 12.2l-2.4-2.4l-1.4 1.4L9 15l8-8l-1.4-1.4z"
                                                    transform="scale(1.5) translate(4, 2)" />
                                            </g>
                                        </svg>



                                    </b-button>
                                    <b-button v-else @click="sendtaxinvoice(props.row.payments.id, props.row.id)"
                                        class="mr-1 edit-btn">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" height="17" width="17"
                                            version="1.1" id="Capa_1" viewBox="0 0 16.615 16.615" xml:space="preserve">
                                            <g>
                                                <g>
                                                    <path style="fill:white;"
                                                        d="M16.614,5.208c0-0.276-0.225-0.501-0.502-0.501H7.53l1.208,0.813    c0.078,0.053,0.142,0.119,0.199,0.19h6.455L10.408,9.32L7.971,7.566l-0.965,0.65l3.111,2.218c0.17,0.123,0.412,0.121,0.582,0    l4.912-3.502v5.09H5.205V9.428L5.003,9.565C4.845,9.669,4.669,9.721,4.488,9.721C4.39,9.721,4.295,9.7,4.202,9.67v2.854    c0,0.277,0.225,0.502,0.502,0.502h11.409c0.277,0,0.502-0.225,0.502-0.502V5.958c0-0.026-0.004-0.05-0.008-0.072    c0.006-0.027,0.008-0.054,0.008-0.08V5.208H16.614z" />
                                                    <path style="fill:white;"
                                                        d="M4.308,8.799c0,0.066,0.037,0.127,0.096,0.158c0.06,0.031,0.13,0.027,0.185-0.01l3.735-2.514    C8.373,6.4,8.403,6.344,8.403,6.283c0-0.06-0.03-0.115-0.079-0.149L4.59,3.621C4.535,3.585,4.464,3.58,4.405,3.611    c-0.059,0.032-0.096,0.093-0.096,0.16v1.454C3.721,5.221,3.235,5.195,2.83,5.146C0.888,4.916,0.368,3.75,0.347,3.7    C0.318,3.632,0.252,3.59,0.181,3.59c-0.012,0-0.024,0-0.035,0.003C0.06,3.611,0,3.685,0,3.77c0,2.944,3.495,3.571,4.307,3.677    L4.308,8.799L4.308,8.799z" />
                                                </g>
                                            </g>
                                        </svg>

                                    </b-button>
                                    <!-- <b-button @click="downloadInvoice(props.row)" class="mr-1 edit-btn">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"
                                            viewBox="0 0 24 24" fill="white">
                                            <g id="Interface / Download">
                                                <path id="Vector" d="M6 21H18M12 3V17M12 17L17 12M12 17L7 12"
                                                    stroke="white" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                            </g>
                                        </svg>
                                    </b-button> -->
                                </span>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
        <div></div>
    </section>
</template>

<script>
import Vue, { set } from "vue";
import SortedTablePlugin from "vue-sorted-table";
import API from '@/api';
import moment from 'moment';
import axios from 'axios';
import Swal from 'sweetalert2';

Vue.use(SortedTablePlugin, {
    ascIcon: '<i class="ti-arrow-down"></i>',
    descIcon: '<i class="ti-arrow-up"></i>'
});
export default {
    name: 'Spotters',
    data() {
        return {
            searchTime: null,
            isDisabled: false,
            modalContent: '',
            selectedInvoice: null,
            currentLanguage: '',
            selected: true,
            unSelected: false,
            serverParams: {
                search: "",
                sort: [
                    {
                        field: 'UserID', // example: 'name'
                        type: 'desc' // 'asc' or 'desc'
                    }
                ],
                page: 1, // what page I want to show
                perPage: 10 // how many items I'm showing per page
            },
            columns: [
                {
                    label: 'Name',
                    field: 'name',
                    filterable: false,
                },
                {
                    label: 'Email',
                    field: 'email',
                    filterable: false,
                },
                {
                    label: 'Phone',
                    field: 'mobile_phone',
                    filterable: false,
                },
                // {
                //     label: 'Status',
                //     field: 'status',
                //     filterable: false,
                // },
                // {
                //     label: 'Start Date',
                //     field: 'start_date',
                //     filterable: false,
                // },
                // {
                //     label: 'End Date',
                //     field: 'end_date',
                //     sortable: false,
                // },
                // {
                //     label: 'Total Price',
                //     field: "total_price",
                //     sortable: false,
                // },
                {
                    label: 'Action',
                    field: "action",
                    sortable: false,
                },
            ],
            isLoading: false,
            rows: [],
            totalRecords: 0,
            spots: [],
            ReferredBy: new Map(),
            form: {
                ReferredID: '',
            },
        };
    },
    methods: {
        updateColumnLabels() {
            this.columns.forEach(col => {
                switch (col.field) {
                    case 'property_id':
                        col.label = this.$t('Property ID');
                        break;
                    case 'card_holder':
                        col.label = this.$t('Card Holder');
                        break;
                    case 'payment_id':
                        col.label = this.$t('Payment ID');
                        break;
                    case 'pdf_path':
                        col.label = this.$t('PDF');
                        break;
                    case 'action':
                        col.label = this.$t('properties_list.action');
                        break;
                    default:
                        break;
                }
            });
        },
        onLanguageChanged(language) {
            if (language == 'en') {
                this.currentLanguage = '';
            } else {
                this.currentLanguage = '_' + language;
            }

        },
        getStatusText(status) {
            return status === 0 ? 'Pending' : 'Sent';
        },
        onSearch() {
            clearTimeout(this.searchTime); // Clear any existing timeout
            this.searchTime = setTimeout(() => { // Set a new timeout
                this.updateParams({ page: 1 }); // Reset to page 1 when searching
                this.loadItems();
            }, 500); // Adjust delay as needed
        },
        viewInvoiceDetails(invoice) {
            this.selectedInvoice = invoice;
            this.$refs['invoice-modal'].show();
        },
        sendtaxinvoice(id, invoiceid) {
            API.sendTaxRequest({ id: id, invoiceid: invoiceid },
                data => {
                    if (data.message) {
                        Swal.fire({
                            type: 'success',
                            title: 'Success!',
                            text: data.message,
                            confirmButtonText: 'Ok',
                        })
                            .then((result) => {
                                if (result.value) {
                                    this.loadItems();
                                }
                            });
                    } else if (data.error) {
                        this.handleApiError(data.error);
                    }
                }, err => {
                    console.log(err)
                    this.isLoading = false;
                })
        },
        editProperty(property) {
            this.$router.push({ name: 'property_id', params: { id: property.id, data: property } });
        },
        downloadInvoice(row) {
            const pdfUrl = row.pdf_path;
            if (pdfUrl) {
                window.open(pdfUrl, '_blank');
            } else {
                console.error('Invoice URL not found.');
            }
        },
        propertyRating(id) {
            this.$router.push({ name: 'view_rating', params: { id: id } });
        },
        dateFormat(val) {
            return moment(val).format('DD MMMM YYYY')
        },
        loadItems() {
            this.isLoading = true;
            API.getInvoiceRequests(this.serverParams,
                data => {
                    this.totalRecords = data.totalRecords;
                    this.rows = data.data;
                    this.isLoading = false;
                }, err => {
                    console.log(err)
                    this.isLoading = false;
                })
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
            console.log("update param", this.serverParams);
        },
        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.loadItems();
        },
        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage });
            this.loadItems();
        },
        errorMsg(err) {
            console.log(err);
            this.$showNotification({ message: 'Failed system error' }, "error")
        }
    },
    computed: {
        propertytype() {
            return this.$t('properties_list.Property_Type');
        },
        action() {
            return this.$t('properties_list.action');
        },
        city() {
            return this.$t('properties_list.city');
        },
        state() {
            return this.$t('properties_list.state');
        },
    },
    mounted() {
        this.isLoading = true;
        Vue.prototype.$EventBus.$on('changeLanguage', this.updateColumnLabels);
        this.loadItems();
        this.updateColumnLabels();
    },
    watch: {
        '$i18n.locale': function (newLocale, oldLocale) {
            this.columns.find(col => col.field === 'property_type').label = this.propertytype;
            this.columns.find(col => col.field === 'action').label = this.action;
            this.columns.find(col => col.field === 'city').label = this.city;
            this.columns.find(col => col.field === 'state').label = this.state;
        }
    }
}
</script>
<style scoped>
.invoice-modal {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.invoice-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    border-bottom: 2px solid #f2f2f2;
    padding-bottom: 15px;
}

.invoice-header-left {
    text-align: left;
}

.invoice-header-right {
    text-align: right;
}

.invoice-header-left h4 {
    font-size: 24px;
    color: #333;
    font-weight: bold;
}

.invoice-header-left .invoice-date,
.invoice-header-right .invoice-id {
    font-size: 14px;
    color: #777;
}

.invoice-header-right .invoice-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

.invoice-table {
    width: 100%;
}

.invoice-table table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
}

.invoice-table th,
.invoice-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
}

.invoice-table th {
    background-color: #f9f9f9;
    color: #333;
    font-weight: bold;
}


.invoice-table .total-row td {
    font-size: 18px;
}

.invoice-table td {
    color: #555;
}
</style>