<template>
    <div class="close-header-title no-con">
        <section class="booking-section pt-4 pt-lg-0">
            <div class="container-inner">
                <div v-if="messageToShow" :class="{ 'error-message': isError, 'success-message': isSuccess }">
                    {{ messageToShow }}
                </div>
                <div class="row">
                    <div class="box-column px-2" v-for="(stats, index) in incomeStatistics" :key="stats.id">
                        <b-card :title="stats.title" img-alt="Image" img-top tag="article">
                            <b-carousel id="carousel-1" v-model="slide[index]" :interval="0" controls indicators
                                background="#ababab" img-width="1024" img-height="480"
                                style="text-shadow: 1px 1px 2px #333;" @sliding-start="onSlideStart(index)"
                                @sliding-end="onSlideEnd(index)">

                                <b-carousel-slide v-for="(photo, index) in stats.photos" :key="index"
                                    :img-src="photo.path">
                                </b-carousel-slide>
                            </b-carousel>
                            <div class="box-content">
                                <b-card-text>
                                    <strong>{{ $t('manage_properties.property_type') }}: </strong> {{ stats.property_type }}
                                </b-card-text>
                                <b-card-text class="address-content">
                                    <strong>{{ $t('manage_properties.address') }}: </strong> {{ stats.city }}, {{ stats.state }},
                                    {{ stats.country }}
                                </b-card-text>
                                <!-- <b-button :href="booking.link" variant="primary">Go somewhere</b-button> -->
                                <div class="d-flex box-footer">
                                    <div class="gap-y-xs">
                                        <a class="card-blue-btn" type="button" data-cy="card-view-btn"
                                            @click="propertyDetail(stats.id)"><span>View</span>
                                            <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.4977 5.57485C12.7515 5.32101 12.7515 4.90946 12.4977 4.65562L8.36113 0.519041C8.10729 0.2652 7.69573 0.2652 7.44189 0.519041C7.18805 0.772882 7.18805 1.18444 7.44189 1.43828L11.1188 5.11524L7.44189 8.79219C7.18805 9.04603 7.18805 9.45759 7.44189 9.71143C7.69573 9.96527 8.10729 9.96527 8.36113 9.71143L12.4977 5.57485ZM0.458984 5.76523L12.0381 5.76524L12.0381 4.46524L0.458984 4.46523L0.458984 5.76523Z" fill="white"/>
                                                </svg>
                                                
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </b-card>
                    </div>
                    <div class="col-lg-4" v-if="incomeStatistics.length === 0">
                        <p>No Properties available.</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import API from '@/api';
import axios from 'axios';
import Vue from 'vue';

import { Loader } from '@googlemaps/js-api-loader';
export default {
    name: 'manage_property',
    components: {
    },
    data() {
        return {
            showIncomeStatistics: false, // Controls conditional rendering of booking cards
            incomeStatistics: [], // Array to store fetched booking data
            slide: [],
            messageToShow: '',
            isError: false,
            isSuccess: false
        };
    },
    mounted() {
        this.getIcomeStatistics();
    },
    methods: {
        onSlideStart(index) {
            this.slide[index] = 0
        },
        onSlideEnd(index) {
            this.slide[index] = this.prperties[index].photos.length - 1
        },
        getIcomeStatistics() {
            API.getIcomeStatistics(fevData => {
                // this.showIncomeStatistics = true;
                this.incomeStatistics = fevData.data.incomeStatistics;
            });
        },
        propertyDetail(id) {
            this.$router.push({ name: 'property_details', params: { id: id } });
        },
    },
    computed: {
    },
    created() {
    },
}
</script>
<style>
.box-footer .card-blue-btn {
    background-color: #70d3ff;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    padding-left: 12px;
    padding-right: 12px;
    gap: 8px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 5px;
}

.error-message {
    background-color: #f8d7da;
    color: #721c24;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #f5c6cb;
}

.success-message {
    background-color: #d4edda;
    color: #155724;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #c3e6cb;
}
.booking-section .row {
    margin: 0 -0.5rem;
}
.booking-section .card {
    padding: 0px !important;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    border: 1px solid #e7e7e8;
    margin: 0;
    margin-bottom: 24px;
}
.booking-section .card .card-body {
    padding: 0;
}
.booking-section .card .card-body .box-content {
    padding: 12px;
    height: auto;
}
.booking-section .card .card-body .box-content .address-content {
    min-height: 42px;
}
.booking-section .card .card-body .box-content .box-footer {
    display: flex;
    justify-content: space-between;
}
.box-column {
    width: 25%;
    margin-bottom: 24px;
}

@media only screen and (max-width: 1399px) {
    .box-column {
        width: 25%;
    }
}
@media only screen and (max-width: 1300px) {
    .box-column {
        width: 33%;
    }
}
@media only screen and (max-width: 1149px) {
    .box-column {
        width: 33%;
    }
}
@media only screen and (max-width: 767px) {
    .box-column {
        width: 50%;
    }
    .client-dashbboard .main-panel .content-wrapper {
        padding: 0 20px 32px;
    }
}
@media only screen and (max-width: 575px) { 
    .box-column {
        width: 100%;
    }
}
</style>