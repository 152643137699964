<template>
    <div class="close-header-title no-con">
        <section class="booking-section">
            <div class="container-inner">
                <div v-if="messageToShow" :class="{ 'error-message': isError, 'success-message': isSuccess }">
                    {{ messageToShow }}
                </div>
                <div class="row">
                    <div class="box-column px-2" v-for="(booking, index) in bookings" :key="booking.id">
                        <b-card :title="booking.title" img-alt="Image" img-top tag="article">
                            <b-carousel id="carousel-1" v-model="slide[index]" :interval="0" controls indicators
                                background="#ababab" img-width="1024" img-height="480"
                                style="text-shadow: 1px 1px 2px #333;" @sliding-start="onSlideStart(index)"
                                @sliding-end="onSlideEnd(index)">

                                <b-carousel-slide v-for="(photo, index) in booking.property.photos" :key="index"
                                    :img-src="photo.path">
                                </b-carousel-slide>
                            </b-carousel>
                            <div class="box-content">
                                <b-card-text>
                                    <strong>Property Type: </strong> {{ booking.property.property_type }}
                                </b-card-text>
                                <b-card-text>
                                    <strong>Dates: </strong> {{ booking.start_date }} - {{ booking.end_date }}
                                </b-card-text>
                                <b-card-text class="address-content">
                                    <strong>Address: </strong> {{ booking.property.city }}, {{ booking.property.state
                                    }},
                                    {{ booking.property.country }}
                                </b-card-text>
                                <b-card-text>
                                    <strong>Request a tax invoice: </strong>
                                    <a href="#"
                                        @click.prevent="requestTaxInvoice(booking.propertyId, booking.user_id)">Click
                                        here</a>
                                </b-card-text>
                                <b-card-text class="cancellation-info"
                                    v-if="booking.cancellationInfo.status === 'active'">
                                    <strong>Cancellation Policy:</strong>
                                    <ul>
                                        <li v-if="booking.cancellationInfo.fullrefund">
                                            <strong>Full Refund:</strong> Cancel before {{
                                                booking.cancellationInfo.fullRefundDate }}
                                        </li>
                                        <li v-if="booking.cancellationInfo.parrefund">
                                            <strong>Partial Refund:</strong> Cancel before {{
                                                booking.cancellationInfo.partialRefundDate }}
                                        </li>
                                        <li>
                                            <strong>No Refund:</strong> No refund available after {{
                                                booking.cancellationInfo.noRefundDate }}
                                        </li>
                                    </ul>
                                </b-card-text>
                                <b-card-text v-else>
                                    <strong>{{ booking.cancellationInfo.message }}</strong>
                                </b-card-text>
                                <!-- <b-button :href="booking.link" variant="primary">Go somewhere</b-button> -->
                                <div class="d-flex box-footer">
                                    <div class="gap-y-xs">
                                        <a class="card-blue-btn" type="button" data-cy="card-view-btn"
                                            @click="propertyDetail(booking.id)">View
                                        </a>
                                    </div>
                                    <div class="gap-y-xs"
                                        v-if="booking.cancellationInfo.status !== 'expired' && (booking.cancellationInfo.fullrefund || booking.cancellationInfo.parrefund)">
                                        <a class="card-blue-btn" type="button" data-cy="card-view-btn"
                                            @click="cancelbooking(booking.id)">Cancel Booking
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </b-card>
                    </div>
                    <div class="col-lg-4" v-if="bookings.length === 0">
                        <p>No bookings available.</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import API from '@/api';
import axios from 'axios';
import Vue from 'vue';
import moment from 'moment';

import { Loader } from '@googlemaps/js-api-loader';
export default {
    name: 'my_bookings',
    components: {
    },
    data() {
        return {
            showbooking: false, // Controls conditional rendering of booking cards
            bookings: [], // Array to store fetched booking data
            slide: [],
            messageToShow: '',
            isError: false,
            isSuccess: false,
            moment
        };
    },
    mounted() {
        this.getbookings();
    },
    methods: {
        onSlideStart(index) {
            this.slide[index] = 0
        },
        onSlideEnd(index) {
            this.slide[index] = this.prperties[index].photos.length - 1
        },
        cancelbooking(id) {
            API.cancelbooking({ id: id },
                data => {
                    if (data.message) {
                        this.displayMessage(data.message);
                    } else if (data.error) {
                        this.handleApiError(data.error);
                    }

                }, err => {
                    console.log(err)
                })
        },

        handleApiError(error) {
            console.error(error);
            this.displayMessage(error, true);
        },
        displayMessage(message, isError = false) {
            this.messageToShow = message;
            this.isError = isError;
            this.isSuccess = !isError;
            this.clearMessageAfterDelay();
        },
        clearMessageAfterDelay() {
            this.getbookings();
            setTimeout(() => {
                this.messageToShow = '';
                this.isError = false;
                this.isSuccess = false;
            }, 5000);
        },
        propertyDetail(id) {
            let Authorization = localStorage.getItem('isLoggedin');
            if (Authorization) {
                this.$router.push({ name: 'invoice_detail', params: { id: id } });

            } else {
                this.$router.push({ name: 'guest_login' });
            }
        },
        prefund(startDate) {
            const futureDate = new Date(startDate);
            futureDate.setDate(futureDate.getDate() - 8);
            return futureDate.toLocaleDateString('en-US', { month: 'short', day: '2-digit' });
        },
        crefund(startDate) {
            const futureDate = new Date(startDate);
            futureDate.setDate(futureDate.getDate() - 15);
            return futureDate.toLocaleDateString('en-US', { month: 'short', day: '2-digit' });
        },
        requestTaxInvoice(id) {
            API.addTaxRequest({ id: id },
                data => {
                    if (data.message) {
                        this.displayMessage(data.message);
                    } else if (data.error) {
                        this.handleApiError(data.error);
                    }

                }, err => {
                    console.log(err)
                })
        },
        getbookings() {
            API.getusersbooking(data => {
                this.bookings = data.data.booking.map(booking => {
                    const startMoment = moment(booking.start_date);
                    const today = moment();
                    const daysFromToday = startMoment.diff(today, 'days');

                    let cancellationInfo = {
                        status: 'active',
                        fullrefund: false,
                        parrefund: false,
                        noRefundDate: false
                    };

                    // Check if the booking date has expired
                    if (today.isAfter(startMoment)) {
                        // Set status to expired if booking date has passed
                        cancellationInfo.status = 'expired';
                        cancellationInfo.message = "Booking is expired.";
                    } else if (daysFromToday > 15) {
                        cancellationInfo.fullrefund = true;
                        cancellationInfo.parrefund = true;
                        cancellationInfo.fullRefundDate = this.crefund(booking.start_date); // Complete refund date
                        cancellationInfo.partialRefundDate = this.prefund(booking.start_date); // Partial refund date
                        cancellationInfo.noRefundDate = cancellationInfo.partialRefundDate;
                    } else if (daysFromToday <= 15 && daysFromToday > 10) {
                        cancellationInfo.parrefund = true;
                        cancellationInfo.partialRefundDate = this.prefund(booking.start_date);
                        cancellationInfo.noRefundDate = cancellationInfo.partialRefundDate;
                    } else if (daysFromToday <= 10) {
                        // cancellationInfo.noRefundDate  = true;
                        cancellationInfo.noRefundDate = this.prefund(booking.start_date);
                    }

                    return {
                        ...booking,
                        cancellationInfo
                    };
                });
            });
        }
    },
    computed: {
    },
    created() {
    },
}
</script>
<style>
.box-footer .card-blue-btn {
    background-color: #70d3ff;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    padding-left: 12px;
    padding-right: 12px;
    gap: 8px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 5px;
}

.error-message {
    background-color: #f8d7da;
    color: #721c24;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #f5c6cb;
}

.success-message {
    background-color: #d4edda;
    color: #155724;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #c3e6cb;
}

.booking-section .row {
    margin: 0 -0.5rem;
}

.booking-section .card {
    padding: 0px !important;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    border: 1px solid #e7e7e8;
    margin: 0;
    margin-bottom: 0;
}

.booking-section .card .card-body {
    padding: 0;
}

.booking-section .card .card-body .box-content {
    padding: 12px;
    height: auto;
}

.booking-section .card .card-body .box-content .address-content {
    min-height: 42px;
}

.booking-section .card .card-body .box-content .box-footer {
    display: flex;
    justify-content: space-between;
}

.box-column {
    width: 16.6%;
    margin-bottom: 24px;
}

@media only screen and (max-width: 1719px) {
    .box-column {
        width: 20%;
    }
}

@media only screen and (max-width: 1399px) {
    .box-column {
        width: 25%;
    }
}

@media only screen and (max-width: 1149px) {
    .box-column {
        width: 33%;
    }
}

@media only screen and (max-width: 767px) {
    .box-column {
        width: 50%;
    }

    .client-dashbboard .main-panel .content-wrapper {
        padding: 0 20px 32px;
    }
}

@media only screen and (max-width: 574px) {
    .box-column {
        width: 100%;
    }
}
</style>