import layout from "../layout";
import full_screen_layout from "../full_screen_layout";

export default [
  {
    path: "/Admin",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "login",
        name: "login",
        component: () => import("@/pages/samples/auth-pages/login"),
        meta: { title: "Admin" },
      },
      {
        path: "forget-password",
        name: "forget-password",
        component: () => import("@/SuperAdmin/forget_password"),
      },
      {
        path: "sign-up",
        name: "sign-up",
        component: () => import("@/SuperAdmin/sign_up"),
      },
      {
        path: "reset-password/:token",
        name: "reset-password",
        component: () => import("@/SuperAdmin/reset_passwords"),
      },
      {
        path: "users/list",
        component: layout,
        children: [
          {
            path: "",
            name: "users_list",
            component: () => import("@/SuperAdmin/users_list"),
          },
        ],
      },
      {
        path: "user/users",
        component: layout,
        children: [
          {
            path: "",
            name: "users",
            component: () => import("@/SuperAdmin/users"),
          },
        ],
      },
      {
        path: "user/roles",
        component: layout,
        children: [
          {
            path: "",
            name: "roles",
            component: () => import("@/SuperAdmin/roles"),
          },
        ],
      },
      {
        path: "booking/list",
        component: layout,
        children: [
          {
            path: "",
            name: "bookings_list",
            component: () => import("@/property/bookings"),
          },
        ],
      },
      {
        path: "properties/list",
        component: layout,
        children: [
          {
            path: "",
            name: "properties_list",
            component: () => import("@/property/properties_list"),
          },
        ],
      },
      {
        path: "properties/detail",
        component: layout,
        children: [
          {
            path: "",
            name: "properties_detail",
            component: () => import("@/property/properties_detail"),
          },
        ],
      },
    ],
  },
  {
    path: "/dashboard",
    component: layout,
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("@/property/dashboard"),
      },
    ],
  },
  {
    path: "/bookings",
    component: layout,
    children: [
      {
        path: "",
        name: "bookings",
        component: () => import("@/property/Property_owner_bookings")
      }
    ]
  },
  {
    path: "/add/property/:id?",
    component: layout,
    children: [
      {
        path: "",
        name: "add_property",
        component: () => import("@/property/add_property"),
      },
    ],
  },
  {
    path: "/calendar",
    component: layout,
    children: [
      {
        path: "",
        name: "Calendar",
        component: () => import("@/property/calendar"),
      },
    ],
  },
  {
    path: "/properties",
    component: layout,
    children: [
      {
        path: "",
        name: "properties",
        component: () => import("@/property/properties"),
      },
    ],
  },
  {
    path: "/invoices",
    component: layout,
    children: [
      {
        path: "",
        name: "invoices",
        component: () => import("@/property/invoices"),
      },
    ],
  },
  {
    path: "/invoice_requests",
    component: layout,
    children: [
      {
        path: "",
        name: "invoice_requests",
        component: () => import("@/property/invoice_requests"),
      },
    ],
  },
  {
    path: "/custom_offers",
    component: layout,
    children: [
      {
        path: "",
        name: "custom_offers",
        component: () => import("@/property/custom_offers"),
      },
    ],
  },
  {
    path: "/manage_properties",
    component: layout,
    children: [
      {
        path: "",
        name: "manage_properties",
        component: () => import("@/guest/manage_property"),
      },
    ],
  },
  {
    path: "/property_details",
    component: layout,
    children: [
      {
        path: "",
        name: "property_details",
        component: () => import("@/guest/property_details"),
      },
    ],
  },
  {
    path: "/view/ratings",
    component: layout,
    children: [
      {
        path: "",
        name: "view_rating",
        component: () => import("@/property/view_rating"),
      },
    ],
  },
  {
    path: "/edit-profile",
    component: layout,
    children: [
      {
        path: "",
        name: "edit-profile",
        component: () => import("@/property/edit-profile"),
      },
    ],
  },
  {
    path: "/error-pages",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "error-404",
        name: "error-404",
        component: () => import("@/pages/samples/error-pages/error-404"),
      },
      {
        path: "error-500",
        name: "error-500",
        component: () => import("@/pages/samples/error-pages/error-500"),
      },
    ],
  },
  {
    path: "*",
    redirect: "/error-pages/error-404",
  },
];
