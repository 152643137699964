<template>
    <div class="container">
        <div class="col-md-12">
            <div class="offer-dedicated-body-left">
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade active show" id="pills-reviews" role="tabpanel"
                        aria-labelledby="pills-reviews-tab">
                        <div class="bg-white clearfix graph-star-rating">
                            <div class="top-box-rating">
                                    <div class="your-rating">
                                        <h5>{{ $t('ratings_and_reviews.your_ratings_and_reviews') }}</h5>
                                        <!-- Rating Section -->
                                        <div class="inner-rating">
                                            <h6>{{ $t('ratings_and_reviews.rate') }}</h6>
                                            <div class="rating" v-if="!currentUserRating">
                                                <!-- <h5 class="mb-4">{{ $t('ratings_and_reviews.rate') }}</h5> -->
                                                <div class="stars">
                                                    <span class="fa fa-star" :class="{ 'checked': rating >= 1 }"
                                                        @mouseover="setRating(1)"></span>
                                                    <span class="fa fa-star" :class="{ 'checked': rating >= 2 }"
                                                        @mouseover="setRating(2)"></span>
                                                    <span class="fa fa-star" :class="{ 'checked': rating >= 3 }"
                                                        @mouseover="setRating(3)"></span>
                                                    <span class="fa fa-star" :class="{ 'checked': rating >= 4 }"
                                                        @mouseover="setRating(4)"></span>
                                                    <span class="fa fa-star" :class="{ 'checked': rating >= 5 }"
                                                        @mouseover="setRating(5)"></span>
                                                </div>
                                            </div>
                                            <div class="rating" v-else>
                                                <div class="stars">
                                                    <span class="fa fa-star" :class="{ 'checked': currentUserRating >= 1 }"></span>
                                                    <span class="fa fa-star" :class="{ 'checked': currentUserRating >= 2 }"></span>
                                                    <span class="fa fa-star" :class="{ 'checked': currentUserRating >= 3 }"></span>
                                                    <span class="fa fa-star" :class="{ 'checked': currentUserRating >= 4 }"></span>
                                                    <span class="fa fa-star" :class="{ 'checked': currentUserRating >= 5 }"></span>
                                                </div>
                                            </div>
                                        </div>
                                        
            
                                        <!-- Add Photo Section -->
                                        <!-- <div class="bg-white upload-inner" v-if="!currentUserReview">
                                            <div class="photo-upload">
                                                <label for="file-upload" class="upload-button">Upload Images</label>
                                                <input type="file" id="file-upload" @change="handleFileUpload" multiple
                                                    accept="image/*" class="file-input">
            
                                                <div class="preview-images">
                                                    <div class="image-preview" v-for="(image, index) in uploadedImages"
                                                        :key="index">
                                                        <img :src="image" alt="Image Preview" class="img-thumbnail">
                                                        <button type="button" class="btn btn-danger btn-sm"
                                                            @click="removeImage(index)"><i class="fas fa-times"></i></button>
                                                    </div>
                                                </div>
            
                                            </div>
                                        </div> -->
                                    </div>
        
                                    <div class="leave-review">
                                        <!-- Review Section -->
                                        <div class="bg-white rating-review-select-page" v-if="!currentUserReview">
                                            <h5>{{ $t('ratings_and_reviews.leave_review') }}</h5>
                                            <form id="add-review">
                                                <div class="form-group">
                                                    <textarea class="form-control" placeholder="Enter Review" id="review" name="review"
                                                        v-model="userReview"></textarea>
                                                </div>
                                                <div class="form-group">
                                                    <button class="btn" @click="saveRating(rating)"
                                                        type="button">{{ $t('ratings_and_reviews.submit') }}</button>
                                                </div>
                                            </form>
                                        </div>
            
                                        <!-- Existing User Review Section -->
                                        <div class="bg-white rating-review-select-page" v-else>
                                            <p>{{ currentUserReview }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        <!-- All Ratings and Reviews Section -->
                        <div class="bg-white rating-review">
                            <div class="top-heading" style="width: 100%;">
                                <h2 class="text-center">{{ $t('ratings_and_reviews.all_ratings_and_reviews') }}</h2>
                            </div>
                            <div class="restaurant-detailed-ratings-and-reviews">
                                <div class="reviews-members new-reviews-members pt-4 pb-4" v-for="(ratingVal, index) in allRatings"
                                    :key="index">
                                    <div class="media" v-if="currentUserID !== ratingVal.user.id">
                                        <div class="reviews-head">
                                            <div class="review-icon">
                                                <a href="#"><img alt="Generic placeholder image"
                                                        src="http://bootdey.com/img/Content/avatar/avatar1.png"
                                                        class="rounded-pill"></a>
                                            </div>
                                            <div class="reviews-members-header">
                                                <h6 class="mb-1"><a class="text-black" href="#">{{ ratingVal.user.name
                                                        }}</a></h6>
                                                <p class="text-gray">{{ ratingVal.created_at }}</p>
                                                <div class="rating">
                                                    <div class="stars">
                                                        <span class="fa fa-star"
                                                            :class="{ 'checked': ratingVal.rating >= 1 }"></span>
                                                        <span class="fa fa-star"
                                                            :class="{ 'checked': ratingVal.rating >= 2 }"></span>
                                                        <span class="fa fa-star"
                                                            :class="{ 'checked': ratingVal.rating >= 3 }"></span>
                                                        <span class="fa fa-star"
                                                            :class="{ 'checked': ratingVal.rating >= 4 }"></span>
                                                        <span class="fa fa-star"
                                                            :class="{ 'checked': ratingVal.rating >= 5 }"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="media-body">
                                            <!-- <div class="reviews-members-header">
                                                <span class="star-rating float-right">
                                                    <a href="#"><i class="icofont-ui-rating active"></i></a>
                                                    <a href="#"><i class="icofont-ui-rating active"></i></a>
                                                    <a href="#"><i class="icofont-ui-rating active"></i></a>
                                                    <a href="#"><i class="icofont-ui-rating active"></i></a>
                                                    <a href="#"><i class="icofont-ui-rating"></i></a>
                                                </span>
                                                <h6 class="mb-1"><a class="text-black" href="#">{{ ratingVal.user.name
                                                        }}</a></h6>
                                                <p class="text-gray">{{ ratingVal.created_at }}</p>
                                            </div> -->
                                            <div class="reviews-members-body">
                                                <p>{{ ratingVal.review }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div v-for="(ratingVal, index) in allRatings" :key="index">
                            <div v-show="ratingVal.rating_images && ratingVal.rating_images.length > 0">
                                <div class="review-images mt-3">
                                    <h6 class="mb-3">Images Shared by {{ ratingVal.user.name }}</h6>
                                    <div class="image-gallery">
                                        <div class="image-item" v-for="(image, imgIndex) in ratingVal.rating_images"
                                            :key="imgIndex">
                                            <img :src="image.image_path" alt="Review Image" class="img-thumbnail" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>   -->
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>
<script>
import API from '@/api'
export default {
    name: 'propertyDetail',
    components: {
    },
    data() {
        return {
            allRatings: {},
            ratingVal: {},
            rating: 0,
            reviews: 0,
            currentUserRating: '',
            currentUserReview: '',
            currentUserID: '',
            userReview: '',
            uploadedImages: []
        }
    },
    mounted() {
        this.getRatingAndReviews();
    },
    methods: {
        handleFileUpload(event) {
            const files = event.target.files;
            if (files) {
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    if (file) {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                            this.uploadedImages.push(e.target.result);
                            this.$forceUpdate();
                        };
                        reader.readAsDataURL(file);
                    }
                }
            }
        },
        removeImage(index) {
            this.uploadedImages.splice(index, 1);
        },
        getRatingAndReviews() {
            const propertyId = sessionStorage.getItem('propertyId');
            API.getRatingAndReviews(propertyId, res => {
                this.allRatings = res.data;
                this.currentUserID = res.userID;
                res.data.forEach((value, key) => {
                    value.created_at = this.formatDate(value.created_at);
                    if (res.userID === value.user.id) {
                        this.currentUserRating = value.rating;
                        this.currentUserReview = value.review;
                        this.rating = value.rating;
                        this.userReview = value.review;
                    }
                });
            }, err => {
                console.log(err)
            })
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const year = date.getFullYear();

            return `${month}/${day}/${year}`;
        },
        saveRating(value) {
            this.rating = value;
            const propertyId = sessionStorage.getItem('propertyId');
            this.ratingVal = {
                rating: value,
                propertyId: propertyId,
                review: this.userReview,
                images: this.uploadedImages
            }
            API.saveRating(this.ratingVal, data => {
                if (data.status == 200) {
                    this.currentUserRating = this.rating;
                    this.currentUserReview = this.userReview;
                    this.getRatingAndReviews();
                    this.$swal({
                        type: 'success',
                        title: this.$t('ratings_and_reviews.success'),
                        text: this.$t('ratings_and_reviews.Rating_added_successfully'),
                    })
                } else {
                    this.$swal({
                        type: 'error',
                        title: 'Oops...',
                        text: data.message,
                    })
                }
            }, this.errorCB);
        },
        setRating(value) {
            this.rating = value;
        },

    },
    created() {

    },
}
</script>

<style scoped>
body {
    background: #dcdcdc;
}
.graph-star-rating h5 {
    font-size: 25px;
    color: #33333;
    line-height: 1.2;
    margin-bottom: 40px;
    font-weight: 700;
}
.inner-rating h6 {
    font-size: 25px;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
}
.total-like-user-main a {
    display: inline-block;
    margin: 0 -17px 0 0;
}

.total-like {
    border: 1px solid;
    border-radius: 50px;
    display: inline-block;
    font-weight: 500;
    height: 34px;
    line-height: 33px;
    padding: 0 13px;
    vertical-align: top;
}

.restaurant-detailed-ratings-and-reviews hr {
    margin: 0 -24px;
}

.graph-star-rating-header .star-rating {
    font-size: 17px;
}

.progress {
    background: #f2f4f8 none repeat scroll 0 0;
    border-radius: 0;
    height: 30px;
}
.rating .stars {
    display: flex;
    align-items: center;
    gap: 5px;
}
.checked,
.price span {
    color: #F2C90C;
    font-size: 18px;
}

.rating-list {
    display: inline-flex;
    margin-bottom: 15px;
    width: 100%;
}

.rating-list-left {
    height: 16px;
    line-height: 29px;
    width: 10%;
}

.rating-list-center {
    width: 80%;
}

.rating-list-right {
    line-height: 29px;
    text-align: right;
    width: 10%;
}

.offer-dedicated-nav .nav-link.active,
.offer-dedicated-nav .nav-link:hover,
.offer-dedicated-nav .nav-link:focus {
    border-color: #3868fb;
    color: #3868fb;
}
.graph-star-rating .rating-review-select-page h5 {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 500;
}
.offer-dedicated-nav .nav-link {
    border-bottom: 2px solid #fff;
    color: #000000;
    padding: 16px 0;
    font-weight: 600;
}
.rating-review-select-page form textarea {
    padding: 15px;
    height: 135px;
    border: 1px solid #D7D7D7;
    border-radius: 10px;
}
.offer-dedicated-nav .nav-item {
    margin: 0 37px 0 0;
}

.restaurant-detailed-action-btn {
    margin-top: 12px;
}

.restaurant-detailed-header-right .btn-success {
    border-radius: 3px;
    height: 45px;
    margin: -18px 0 18px;
    min-width: 130px;
    padding: 7px;
}

.text-black {
    color: #000000;
}
.rating-review {
    margin-top: 70px;
}
.rating-review .top-heading h2 {
    font-size: 25px;
    font-weight: 500;
    color: #333;
    line-height: 1.2;
    margin-bottom: 30px;
}
.icon-overlap {
    bottom: -23px;
    font-size: 74px;
    opacity: 0.23;
    position: absolute;
    right: -32px;
}

.menu-list img {
    width: 41px;
    height: 41px;
    object-fit: cover;
}

.restaurant-detailed-header-left img {
    width: 88px;
    height: 88px;
    border-radius: 3px;
    object-fit: cover;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}
.restaurant-detailed-ratings-and-reviews .reviews-members {
    margin: 0;
    max-width: inherit;
}
.restaurant-detailed-ratings-and-reviews{
    justify-content: center;
    gap: 21px;
}
.reviews-members .media .mr-3 {
    width: 56px;
    height: 56px;
    object-fit: cover;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.total-like-user {
    border: 2px solid #fff;
    height: 34px;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
    width: 34px;
}

.total-like-user-main a {
    display: inline-block;
    margin: 0 -17px 0 0;
}

.total-like {
    border: 1px solid;
    border-radius: 50px;
    display: inline-block;
    font-weight: 500;
    height: 34px;
    line-height: 33px;
    padding: 0 13px;
    vertical-align: top;
}

.restaurant-detailed-ratings-and-reviews hr {
    margin: 0 -24px;
}

.graph-star-rating-header .star-rating {
    font-size: 17px;
}

.progress {
    background: #f2f4f8 none repeat scroll 0 0;
    border-radius: 0;
    height: 30px;
}

.rating-list {
    display: inline-flex;
    margin-bottom: 15px;
    width: 100%;
}

.rating-list-left {
    height: 16px;
    line-height: 29px;
    width: 10%;
}

.rating-list-center {
    width: 80%;
}

.rating-list-right {
    line-height: 29px;
    text-align: right;
    width: 10%;
}



.progress {
    background: #f2f4f8 none repeat scroll 0 0;
    border-radius: 0;
    height: 30px;
}

/* Photo upload section */
.photo-upload {
    position: relative;
    margin-bottom: 0;
}

.photo-upload .upload-button,
.rating-review-select-page .form-group button.btn {
    display: inline-block;
    cursor: pointer;
    background-color: #70D3FF;
    border: 1px solid #70D3FF;
    color: white;
    padding: 10px 20px;
    border-radius: 60px;
    text-align: center;
    font-weight: 700;
    width: 100%;
    max-width: 260px;
    height: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.upload-inner {
    margin-top: 30px;
}
.upload-button:hover, .rating-review-select-page .form-group button.btn:hover {
    background-color: #fff;
    color: #70D3FF;
}

#file-upload {
    display: none;
}

.preview-images {
    margin-top: 30px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.image-preview {
    position: relative;
    display: inline-block;
}

.image-preview img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
}

.image-preview button {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: #70D3FF;
    color: white;
    border: none;
    border-radius: 50%;
    padding: 5px;
    width: 22px;
    height: 22px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.image-preview button i {
    font-size: 13px;
}

.image-preview button:hover {
    background-color: darkred;
}

.new-reviews-members {
    position: relative;
    overflow: hidden;
    height: auto;
    width: 32%;
    color: #000;
    text-align: center;
    border-radius: 10px;
    font-size: 16px;
    background-color: #fff;
    border: 1px solid #D7D7D7;
    padding: 25px;
    box-sizing: border-box;
}
.new-reviews-members .media {
    flex-direction: column;
    align-items: center;
}
.new-reviews-members .media .review-icon {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    display: inline-block;
    margin: 0;
}

.new-reviews-members .media .review-icon a {
    width: 100%;
    height: 100%;
}

.new-reviews-members .media .review-icon a img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.restaurant-detailed-ratings-and-reviews h5 {
    font-size: 2rem;
}

.new-reviews-members .media-body {
    margin-left: 0 !important;
    text-align: center !important;
    width: 100%;
}
.new-reviews-members .media-body .reviews-members-header h6 {
    text-align: center;
}
.new-reviews-members .media-body .reviews-members-header h6 a {
    color: #ffffff;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
}

.new-reviews-members .media-body .reviews-members-header p {
    color: #ffffff;
    font-size: 18px;
    text-transform: capitalize;
    text-align: center;
}

.new-reviews-members .media-body .rating .stars span {
    color: #fff;
    padding: 2px;
}
.new-reviews-members .media-body .rating .stars span.checked {
    color: #ff9f1a;
}

.new-reviews-members .media-body .reviews-members-body {
    font-size: 16px;
    background-color: #ffffff;
    text-align: left;
    position: relative;
}

.new-reviews-members .media-body .reviews-members-body p {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1.34;
}
.reviews-head .reviews-members-header .rating .stars .checked {
    font-size: 14px;
}

.reviews-members {
    margin-bottom: 30px;
}

.review-images h6 {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
}

.image-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.image-item {
    position: relative;
    width: 100px;
    height: 100px;
    overflow: hidden;
}

.image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.image-item:hover img {
    opacity: 0.8;
    transform: scale(1.1);
    transition: transform 0.3s ease, opacity 0.3s ease;
}
.reviews-head {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 20px;
}
.reviews-head .reviews-members-header {
    width: calc(100% - 70px);
    text-align: left;
    padding-left: 15px;
}
.reviews-head .reviews-members-header h6 {
    text-transform: capitalize;
    font-size: 18px;
    color: #333;
    font-weight: 500;
}
.reviews-head .reviews-members-header p.text-gray {
    font-size: 14px;
}
.top-box-rating {
    display: flex;
    width: 100%;
    border: 1px solid #D7D7D7;
    border-radius: 10px;
    overflow: hidden;
}
.top-box-rating .your-rating {
    width: 35%;
    background: #FAFAFA;
    display: flex;
    text-align: center;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    border-right: 1px solid #D7D7D7;
    padding: 20px;
}
.top-box-rating .leave-review {
    width: 65%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 30px;

}
.top-box-rating .your-rating .inner-rating {
    text-align: center;
}
.rating-review-select-page{
    width: 100%;
}
.top-box-rating .your-rating .inner-rating .rating .stars span.fa.fa-star {
    font-size: 20px;
}
.rating-review-select-page .form-group button.btn{
    margin-left: auto;
}
@media (max-width: 1199px){
    .restaurant-detailed-ratings-and-reviews {
        gap: 17px;
    }
    .graph-star-rating h5, .inner-rating h6 {
        font-size: 20px;
    }
}
@media (max-width: 991px){
    .new-reviews-members {
        width: 48%;
    }
    .top-box-rating .your-rating, .top-box-rating .leave-review{
        width: 50%;
    }
}
@media (max-width: 767px){
    .top-box-rating{
        flex-wrap: wrap;
    }
    .top-box-rating .your-rating, .top-box-rating .leave-review{
        width: 100%;
    }
    .top-box-rating .your-rating{
        border-bottom: 1px solid #D7D7D7;
        border-right: 0;
    }
    .top-box-rating .your-rating .inner-rating {
        width: 100%;
    }
    .top-box-rating .your-rating .inner-rating .rating .stars {
        justify-content: center;
    }
}
@media (max-width: 574px){
    .new-reviews-members {
        width: 100%;
        padding: 15px;
    }
    .rating-review .top-heading h2, .graph-star-rating h5 {
        font-size: 19px;
        margin-bottom: 25px;
    }
    .top-box-rating .your-rating, .top-box-rating .leave-review{
        padding: 15px;
    }
    .graph-star-rating h5, .inner-rating h6 {
        font-size: 19px;
    }
    .top-box-rating .your-rating .inner-rating .rating .stars span.fa.fa-star {
        font-size: 16px;
    }
    .inner-rating h6{
        margin-bottom: 15px;
    }
}
</style>