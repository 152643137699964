<template>
    <section class="tables new-updated-design px-3 px-md-0 pt-4 pt-md-0">
        <vue-snotify></vue-snotify>
        <div>
            <b-modal id="invoice-modal" ref="invoice-modal" scrollable :title="$t('Invoice Details')" size="lg"
                :hide-footer="true">
                <div class="invoice-table" v-if="selectedInvoice">
                    <div class="top-content d-flex align-items-center justify-content-between gap-2">
                        <div class="header-logo">
                            <img src="../public/assets/images/cheapcomfort-logo.svg" alt="logo">
                        </div>
                        <b-button class="invoice-btn">
                            <span>Invoice</span>
                        </b-button>
                    </div>
                    <div class="body-content">
                        <div class="body-head">
                            <h3 class="title">Customer Information</h3>
                            <div class="row">
                                <div class="col-lg-4 col-md-6">
                                    <div class="form-group">
                                        <label for="">Name:</label> <span>{{ selectedInvoice['first_name' +
                                            currentLanguage] }} {{ selectedInvoice['last_name' + currentLanguage]
                                            }}</span>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="form-group">
                                        <label for="">Email:</label> <span>{{ selectedInvoice['email' + currentLanguage]
                                            }}</span>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="form-group">
                                        <label for="">Phone:</label> <span>{{ selectedInvoice['phone_number' +
                                            currentLanguage] }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="body-table-content">
                            <div class="responsive-table">
                                <h3 class="title">Reservation Details</h3>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>DESCRIPTION</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Accommodation name:</td>
                                            <td>{{ selectedInvoice.property.property_type }}</td>
                                        </tr>
                                        <tr>
                                            <td>Accommodation address:</td>
                                            <td>{{ selectedInvoice.property.address }}</td>
                                        </tr>
                                        <tr>
                                            <td>Check-in date:</td>
                                            <td>{{ selectedInvoice.start_date }}</td>
                                        </tr>
                                        <tr>
                                            <td>Check-out date:</td>
                                            <td>{{ selectedInvoice.end_date }}</td>
                                        </tr>
                                        <tr>
                                            <td>Length of stay:</td>
                                            <td>{{ selectedInvoice.number_of_nights }} Nights</td>
                                        </tr>
                                        <tr>
                                            <td>Room or accommodation type reserved:</td>
                                            <td>{{ selectedInvoice.property.accommodation_type }}</td>
                                        </tr>
                                        <tr>
                                            <td>Number of guests:</td>
                                            <td>{{ selectedInvoice.number_of_guests }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="responsive-table financial-table">
                                <h3 class="title">Financial Details</h3>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>DESCRIPTION</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Total reservation price:</td>
                                            <td>Total amount to be paid, broken down if taxes or additional fees apply.
                                            </td>
                                            <td>{{ selectedInvoice.payment_currency }} {{ selectedInvoice.total_price }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Currency:</td>
                                            <td>{{ selectedInvoice.payment_currency }}</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Cost breakdown (optional):</td>
                                            <td>Per Night Price: {{ selectedInvoice.price_per_night }}, Host fee: {{
                                                selectedInvoice.hostFees }}, Tax: {{ selectedInvoice.taxfee }} , Service
                                                Fee: {{ selectedInvoice.servicefee }}</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Payment policy:</td>
                                            <td>Whether an advance payment has been made, or if the full amount will be
                                                paid at
                                                the property.</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Cancellation and refund policy:</td>
                                            <td>Information about potential charges or refunds in case of
                                                cancellation</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="responsive-table">
                                <h3 class="title">Other Details</h3>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>DESCRIPTION</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Reservation confirmation code:</td>
                                            <td>12345</td>
                                        </tr>
                                        <tr>
                                            <td>Check-in and check-out instructions:</td>
                                            <td>Checin After: {{ formatTime(selectedInvoice.property.checkin_after) }},
                                                Checkout Before: {{
                                                    formatTime(selectedInvoice.property.checkout_before) }}</td>
                                        </tr>
                                        <tr>
                                            <td>Property policies:</td>
                                            <td>Children are {{ selectedInvoice.property.children }} Events are{{
                                                selectedInvoice.property.Events }} <br> Pets are {{
                                                    selectedInvoice.property.Pets }}
                                                Smoking is {{ selectedInvoice.property.Smoking }}</td>
                                        </tr>
                                        <tr>
                                            <td>Included services:</td>
                                            <td><span
                                                    v-if="selectedInvoice.property.wifi_available == 1">WIFI,</span><span
                                                    v-if="selectedInvoice.property.tv_available == 1">TV </span>
                                                <span v-if="selectedInvoice.property.kitchen_available == 1">,kitchen
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Property contact information:</td>
                                            <td>Private Room Detached house</td>
                                        </tr>
                                        <!-- <tr>
                                            <td>Arrival instructions:</td>
                                            <td>Lorem ipsum dolor sit amet consectetuer.</td>
                                        </tr> -->
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="price-list">
                            <h3 class="amount">TOTAL Amount: <span class="price">{{ selectedInvoice.payment_currency }}
                                    {{ selectedInvoice.total_price }}</span></h3>
                        </div>
                        <!-- <p class="description">
                            In many cases, there is also a link provided to access the reservation and download an
                            additional receipt if needed. If the customer requires a
                            specific tax invoice, it must be requested directly from the property.
                        </p> -->
                    </div>
                    <div class="footer-content">
                        <p class="text-center"> <span>info@cheapcomforts.com </span><span> 07777 888 999 </span><span>
                                cheapcomforts.com</span></p>
                    </div>
                    <!-- <h2 class="mb-3">Customer information 1122</h2>
                    <div class="responsive-table">
                        <table>
                            <tr>
                                <td><b>{{ $t('First Name') }}:</b> {{ selectedInvoice['first_name' + currentLanguage] }}</td>
                                <td><b>{{ $t('Last Name') }}:</b> {{ selectedInvoice['last_name' + currentLanguage] }}</td>
                                <td><b>{{ $t('Email') }}:</b> {{ selectedInvoice['email' + currentLanguage] }}</td>
                                <td><b>{{ $t('Phone') }}:</b> {{ selectedInvoice['phone_number' + currentLanguage] }}</td>
                            </tr>
                            <tr>
                                <td><b>{{ $t('Card Holder') }}:</b> {{ selectedInvoice['card_holder' + currentLanguage] }}</td>
                                <td><b>{{ $t('Start Date') }}:</b> {{ selectedInvoice['start_date' + currentLanguage] }}</td>
                                <td><b>{{ $t('End Date') }}:</b> {{ selectedInvoice['end_date' + currentLanguage] }}</td> 
                                <td rowspan="2"><b>{{ $t('Total Price') }}:</b> {{ selectedInvoice['payment_currency' + currentLanguage] }} {{
                                selectedInvoice['total_price' + currentLanguage] }}</td> 
                            </tr>
                        </table>
                    </div> -->

                    <!-- Example: Embed PDF using an iframe if pdf_path exists -->
                    <!-- <iframe v-if="selectedInvoice.pdf_path" :src="selectedInvoice.pdf_path" width="100%"
                        height="600px"></iframe>
                    <p v-else>{{ $t('No PDF available for this invoice.') }}</p> -->
                </div>
            </b-modal>
        </div>
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card properties-table">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">{{ $t('View Invoices') }}</h4>
                    </div>
                    <div class="card-body">
                        <div class="search-bar mb-3 search-bar-style"> <!-- Added search bar -->
                            <b>{{ $t('Search') }}: </b>
                            <input style="border: 1px solid #dcdfe6;" type="text" v-model="serverParams.search"
                                @input="onSearch" placeholder="Search..." />
                        </div>
                        <vue-good-table mode="remote" @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :pagination-options="{
                                enabled: true, dropdownAllowAll: false, perPageDropdown: [10, 20, 50, 100, 200], nextLabel: this.$t('pagination.next'),
                                prevLabel: this.$t('pagination.Previous'),
                                rowsPerPageLabel: this.$t('pagination.rowsPerPage'),
                                ofLabel: this.$t('pagination.of'),
                            }" :rows="rows" :columns="columns" :sort-options="{
                                enabled: false,
                            }">
                            <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field == 'first_name'">
                                    <span>{{ props.row['first_name' + currentLanguage] }}</span>
                                </span>
                                <span v-if="props.column.field == 'last_name'">
                                    <span>{{ props.row['last_name' + currentLanguage] }}</span>
                                </span>
                                <span v-if="props.column.field == 'email'">
                                    <span>{{ props.row['email' + currentLanguage] }}</span>
                                </span>
                                <span v-if="props.column.field == 'phone_number'">
                                    <span>{{ props.row['phone_number' + currentLanguage] }}</span>
                                </span>
                                <span v-if="props.column.field == 'card_holder'">
                                    <span>{{ props.row['card_holder' + currentLanguage] }}</span>
                                </span>
                                <span v-if="props.column.field == 'start_date'">
                                    <span>{{ props.row["start_date" + currentLanguage] }}</span>
                                </span>
                                <span v-if="props.column.field == 'end_date'">
                                    <span>{{ props.row["end_date" + currentLanguage] }}</span>
                                </span>
                                <span v-if="props.column.field == 'total_price'">
                                    <span>{{ props.row["payment_currency" + currentLanguage] }} {{
                                        props.row["total_price" + currentLanguage] }}</span>
                                </span>
                                <span v-if="props.column.field === 'action'" class="text-nowrap properties-btns">
                                    <b-button @click="viewInvoiceDetails(props.row)" class="mr-1 edit-btn">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="17"
                                            height="17" fill="white">
                                            <path
                                                d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                                        </svg>

                                    </b-button>
                                    <b-button @click="downloadInvoice(props.row)" class="mr-1 edit-btn">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"
                                            viewBox="0 0 24 24" fill="white">
                                            <g id="Interface / Download">
                                                <path id="Vector" d="M6 21H18M12 3V17M12 17L17 12M12 17L7 12"
                                                    stroke="white" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                            </g>
                                        </svg>
                                    </b-button>
                                </span>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
        <div></div>
    </section>
</template>

<script>
import Vue, { set } from "vue";
import SortedTablePlugin from "vue-sorted-table";
import API from '@/api';
import moment from 'moment';
import axios from 'axios';

Vue.use(SortedTablePlugin, {
    ascIcon: '<i class="ti-arrow-down"></i>',
    descIcon: '<i class="ti-arrow-up"></i>'
});
export default {
    name: 'Spotters',
    data() {
        return {
            searchTime: null,
            isDisabled: false,
            modalContent: '',
            selectedInvoice: null,
            currentLanguage: '',
            selected: true,
            unSelected: false,
            serverParams: {
                search: "",
                sort: [
                    {
                        field: 'UserID', // example: 'name'
                        type: 'desc' // 'asc' or 'desc'
                    }
                ],
                page: 1, // what page I want to show
                perPage: 10 // how many items I'm showing per page
            },
            columns: [
                {
                    label: 'First Name',
                    field: 'first_name',
                    filterable: false,
                },
                {
                    label: 'Last Name',
                    field: 'last_name',
                    filterable: false,
                },
                {
                    label: 'Email',
                    field: 'email',
                    filterable: false,
                },
                {
                    label: 'Phone',
                    field: 'phone_number',
                    filterable: false,
                },
                {
                    label: 'Card Holder',
                    field: 'card_holder',
                    filterable: false,
                },
                {
                    label: 'Start Date',
                    field: 'start_date',
                    filterable: false,
                },
                {
                    label: 'End Date',
                    field: 'end_date',
                    sortable: false,
                },
                {
                    label: 'Total Price',
                    field: "total_price",
                    sortable: false,
                },
                {
                    label: 'Action',
                    field: "action",
                    sortable: false,
                },
            ],
            isLoading: false,
            rows: [],
            totalRecords: 0,
            spots: [],
            ReferredBy: new Map(),
            form: {
                ReferredID: '',
            },
        };
    },
    methods: {
        formatTime(time) {
            const [hours, minutes] = time.split(':'); // Split the time into hours and minutes
            const date = new Date();
            date.setHours(hours);
            date.setMinutes(minutes);

            let hour = date.getHours();
            const minute = date.getMinutes();
            const period = hour >= 12 ? 'PM' : 'AM';

            hour = hour % 12 || 12; // Convert 24-hour time to 12-hour time
            const formattedTime = `${hour}:${minute < 10 ? '0' + minute : minute} ${period}`;

            return formattedTime;
        },
        updateColumnLabels() {
            this.columns.forEach(col => {
                switch (col.field) {
                    case 'property_id':
                        col.label = this.$t('Property ID');
                        break;
                    case 'card_holder':
                        col.label = this.$t('Card Holder');
                        break;
                    case 'payment_id':
                        col.label = this.$t('Payment ID');
                        break;
                    case 'pdf_path':
                        col.label = this.$t('PDF');
                        break;
                    case 'action':
                        col.label = this.$t('properties_list.action');
                        break;
                    default:
                        break;
                }
            });
        },
        onLanguageChanged(language) {
            if (language == 'en') {
                this.currentLanguage = '';
            } else {
                this.currentLanguage = '_' + language;
            }

        },
        onSearch() {
            clearTimeout(this.searchTime); // Clear any existing timeout
            this.searchTime = setTimeout(() => { // Set a new timeout
                this.updateParams({ page: 1 }); // Reset to page 1 when searching
                this.loadItems();
            }, 500); // Adjust delay as needed
        },
        viewInvoiceDetails(invoice) {
            this.selectedInvoice = invoice;
            this.$refs['invoice-modal'].show();
        },
        editProperty(property) {
            this.$router.push({ name: 'property_id', params: { id: property.id, data: property } });
        },
        downloadInvoice(row) {
            const pdfUrl = row.pdf_path;
            if (pdfUrl) {
                window.open(pdfUrl, '_blank');
            } else {
                console.error('Invoice URL not found.');
            }
        },
        propertyRating(id) {
            this.$router.push({ name: 'view_rating', params: { id: id } });
        },
        dateFormat(val) {
            return moment(val).format('DD MMMM YYYY')
        },
        loadItems() {
            this.isLoading = true;
            API.getInvoices(this.serverParams,
                data => {
                    this.totalRecords = data.totalRecords;
                    this.rows = data.data;
                    this.isLoading = false;
                }, err => {
                    console.log(err)
                    this.isLoading = false;
                })
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
            console.log("update param", this.serverParams);
        },
        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.loadItems();
        },
        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage });
            this.loadItems();
        },
        errorMsg(err) {
            console.log(err);
            this.$showNotification({ message: 'Failed system error' }, "error")
        }
    },
    computed: {
        propertytype() {
            return this.$t('properties_list.Property_Type');
        },
        action() {
            return this.$t('properties_list.action');
        },
        city() {
            return this.$t('properties_list.city');
        },
        state() {
            return this.$t('properties_list.state');
        },
    },
    mounted() {
        this.isLoading = true;
        Vue.prototype.$EventBus.$on('changeLanguage', this.updateColumnLabels);
        this.loadItems();
        this.updateColumnLabels();
    },
    watch: {
        '$i18n.locale': function (newLocale, oldLocale) {
            this.columns.find(col => col.field === 'property_type').label = this.propertytype;
            this.columns.find(col => col.field === 'action').label = this.action;
            this.columns.find(col => col.field === 'city').label = this.city;
            this.columns.find(col => col.field === 'state').label = this.state;
        }
    }
}
</script>
<style>
#invoice-modal .modal-body {
    padding: 0px;
}

#invoice-modal .modal-header {
    padding: 20px 30px;
    align-items: center;
}

#invoice-modal .modal-header .modal-title {
    font-size: 30px;
}

#invoice-modal .modal-header button.close {
    font-size: 30px;
}

#invoice-modal .modal-body::-webkit-scrollbar {
    width: 10px;
}

/* Track */
#invoice-modal .modal-body::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

/* Handle */
#invoice-modal .modal-body::-webkit-scrollbar-thumb {
    background: #70D3FF;
    border-radius: 10px;
}

/* Handle on hover */
#invoice-modal .modal-body::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.invoice-table .top-content .header-logo img {
    width: auto;
    height: auto;
}

.invoice-table .top-content {
    padding: 40px 30px 30px;
}

.invoice-table .top-content .invoice-btn {
    background-color: #70D3FF;
    border: 1px solid #70D3FF;
    border-radius: 6px !important;
    outline: none;
    box-shadow: none;
    padding: 10px 25px !important;
}

.invoice-table .top-content .invoice-btn:hover {
    background-color: #55cbff;
}

.invoice-table .top-content .invoice-btn span {
    font-size: 30px;
    font-weight: 700;
    line-height: 30px;
    text-transform: uppercase;
    color: #fff;
}

.invoice-table .body-content {
    padding: 30px;
}

.body-content .body-head {
    margin-bottom: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid #B6B6B6;
}

.body-content .body-head h3.title {
    font-size: 28px;
    line-height: 28px;
    font-weight: 500;
    color: #000;
    margin-bottom: 20px;
}

.body-content h3.title {
    font-size: 28px;
    line-height: 28px;
    font-weight: 500;
    color: #000;
    margin-bottom: 20px;
}

.body-content .body-head .row .col-lg-4:first-child {
    text-align: left;
}

.body-content .body-head .row .col-lg-4:nth-child(2) {
    text-align: center;
}

.body-content .body-head .row .col-lg-4:last-child {
    text-align: right;
}

.body-content .body-head .row .form-group {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.body-content .body-head .row .form-group label {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;
    color: #70D3FF;
    margin-right: 5px;
}

.body-content .body-head .row .form-group span {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    color: #000;
}

.body-content .responsive-table {
    margin-bottom: 30px;
}

.body-content .responsive-table table thead {
    background-color: #70D3FF;
    color: #fff;
}

.body-content .responsive-table.financial-table table thead tr th:nth-child(1) {
    width: 30%;
}

.body-content .responsive-table.financial-table table thead tr th:nth-child(2) {
    width: 60%;
}

.body-content .responsive-table.financial-table table thead tr th:nth-child(3) {
    width: 10%;
    text-align: center;
    min-width: 160px;
}

.body-content .responsive-table.financial-table table tbody tr td:nth-child(3) {
    text-align: center;
    color: #70D3FF;
    font-weight: 600;
}

.body-content .responsive-table table thead tr th:nth-child(1) {
    width: 30%;
}

.body-content .responsive-table table thead tr th:nth-child(2) {
    width: 70%;
}

.body-content .responsive-table table thead tr th {
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    color: #fff;
}

.body-content .responsive-table table tbody tr td {
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    color: #717171;
}

.body-content .price-list h3.amount {
    text-align: right;
    font-size: 28px;
    line-height: 28px;
    font-weight: 500;
    color: #000;
    margin-bottom: 0px;
}

.body-content .price-list {
    margin-bottom: 30px;
}

.body-content .price-list h3.amount span {
    text-align: right;
    font-size: 38px;
    line-height: 28px;
    font-weight: 500;
    color: #70D3FF;
    margin-bottom: 0px;
}

.body-content p.description {
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    color: #717171;
    margin-bottom: 0px;
}

.footer-content {
    padding: 30px;
    background-color: #F2FAFD;
    text-align: center;
}

.footer-content p {
    font-size: 22px;
    font-weight: 400;
    line-height: normal;
    color: #717171;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.footer-content p span {
    white-space: nowrap;
    padding: 0 5px;
}

.footer-content p span:not(:last-child) {
    border-right: 2px solid #717171;
}

@media (max-width: 1200px) {
    #invoice-modal .modal-dialog.modal-lg {
        max-width: 90%;
    }

    .invoice-table .top-content {
        flex-wrap: wrap;
        gap: 20px;
    }

    .invoice-table .top-content .invoice-btn span {
        font-size: 26px;
        font-weight: 600;
    }

    .invoice-table .top-content .header-logo img {
        width: auto;
        height: 55px;
    }

    #invoice-modal .modal-header .modal-title {
        font-size: 26px;
    }
}

@media (max-width: 1023px) {
    #invoice-modal .modal-dialog.modal-lg {
        max-width: 95%;
    }

    .invoice-table .top-content {
        flex-wrap: wrap;
    }

    .invoice-table .top-content .invoice-btn span {
        font-size: 24px;
        font-weight: 600;
    }

    .invoice-table .top-content .header-logo img {
        width: auto;
        height: 55px;
    }
}

@media (max-width: 992px) {
    .body-content .body-head .row .col-lg-4:last-child {
        text-align: left;
    }

    .footer-content p {
        font-size: 20px;
    }

    .body-content .price-list h3.amount span {
        font-size: 34px;
    }

    .invoice-table .top-content .invoice-btn span {
        font-size: 24px;
    }
}

@media (max-width: 767.5px) {
    .body-content .body-head .row .col-lg-4:nth-child(2) {
        text-align: left;
    }

    #invoice-modal .modal-header {
        padding: 20px;
    }

    .invoice-table .top-content {
        padding: 40px 20px 20px;
    }

    .invoice-table .body-content {
        padding: 20px;
    }

    .footer-content {
        padding: 20px;
    }

    .body-content p.description {
        font-size: 18px;
    }

    .footer-content p {
        font-size: 18px;
    }

    .body-content .responsive-table table thead tr th {
        font-size: 18px;
    }

    .body-content .responsive-table table tbody tr td {
        font-size: 18px;
    }

    .body-content .body-head .row .form-group label {
        font-size: 18px;
    }

    .body-content .body-head .row .form-group span {
        font-size: 18px;
    }

    .body-content .price-list h3.amount {
        font-size: 24px;
    }

    .body-content .price-list h3.amount span {
        font-size: 30px;
    }

    .body-content .body-head .row .form-group {
        flex-wrap: nowrap;
    }

    .body-content .body-head .row .form-group label {
        min-width: 150px;
    }

    #invoice-modal .modal-header .modal-title {
        font-size: 24px;
    }
}

@media (max-width: 550px) {
    .invoice-table .top-content .header-logo img {
        width: auto;
        height: 40px;
    }

    .invoice-table .top-content .invoice-btn {
        padding: 6px 20px !important;
    }

    .body-content h3.title {
        font-size: 24px;
    }

    .body-content .body-head h3.title {
        font-size: 24px;
    }

    .body-content .body-head .row .form-group label {
        min-width: 100px;
    }

    .invoice-table .top-content .invoice-btn span {
        font-size: 20px;
    }

    .body-content .responsive-table table tbody tr td {
        font-size: 16px;
    }

    .body-content .responsive-table table thead tr th {
        font-size: 16px;
    }

    .body-content .body-head .row .form-group span {
        font-size: 16px;
    }

    .body-content .body-head .row .form-group label {
        font-size: 16px;
    }

    .body-content p.description {
        font-size: 16px;
    }

    .footer-content p {
        font-size: 16px;
    }

    .footer-content p span:not(:last-child) {
        border-right: 1px solid #717171;
    }
}

@media (max-width: 400px) {
    .invoice-table .top-content .header-logo img {
        width: auto;
        height: 40px;
    }

    .invoice-table .top-content .header-logo img {
        width: auto;
        height: 33px;
    }

    .body-content .price-list h3.amount span {
        font-size: 26px;
    }

    .body-content .body-head .row .form-group {
        flex-direction: column;
        align-items: flex-start;
    }

    .invoice-table .top-content .invoice-btn span {
        font-size: 18px;
    }
}
.modal-open .modal {
    margin-right: 0px !important;
}
</style>