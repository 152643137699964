<template>
  <section class="tables new-updated-design">
    <div class="container-property">
      <vue-snotify></vue-snotify>
      <div class="row">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
            </div>
            <div class="col-lg-6 d-flex justify-content-end" v-if="this.taxinvoice">
              <p v-if="this.taxinvoice.status == 0"
                style="padding: 10px; border: 2px solid black; border-radius: 20px;">Tax Invoice Requested</p>
                <p v-if="this.taxinvoice.status == 1"
                style="padding: 10px; border: 2px solid black; border-radius: 20px;">Tax Invoice Sent</p>
            </div>
            <div class="col-lg-6 d-flex justify-content-end" v-else>
              <!-- <a :href="paymentdetails.pdf_path" target="_blank">
                <Button class="btn btn-primary" style="margin-right: 10px !important;">
                  Download Invoice
                </Button>
              </a> -->
              <!-- <Button class="btn btn-primary" style="margin-right: 10px !important;">Download Invoice</Button> -->
              <a class="btn btn-primary"
                @click.prevent="requestTaxInvoice(paymentdetails.id, paymentdetails.property.user_id )">Request
                Tax Invoice</a>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-log-12">
              <h3>Customer Information</h3>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-lg-4">
              <Label>Full Name: <span> {{ this.paymentdetails.first_name }} {{ this.paymentdetails.last_name }}</span>
              </Label>
            </div>
            <div class="col-lg-4">
              <Label>Email: <span> {{ this.paymentdetails.email }}</span></Label>
            </div>
            <div class="col-lg-4">
              <Label>Phone: <span> {{ this.paymentdetails.phone_number }}</span></Label>
            </div>
          </div>
          <hr>
          <div class="row mt-4">
            <div class="col-log-12">
              <h3>Reservation Details</h3>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <table class="table">
                <thead class="table-head-custom">
                  <tr>
                    <th scope="col">Title</th>
                    <th scope="col">Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Accommodation name:</td>
                    <td>{{ this.paymentdetails.property.property_type }}</td>
                  </tr>
                  <tr>
                    <td>Accommodation address:</td>
                    <td>{{ this.paymentdetails.property.address }}</td>
                  </tr>
                  <tr>
                    <td>Check-in date:</td>
                    <td>{{ this.paymentdetails.start_date }} </td>
                  </tr>
                  <tr>
                    <td>Check-out date:</td>
                    <td>{{ this.paymentdetails.end_date }}</td>
                  </tr>
                  <tr>
                    <td>Length of stay: </td>
                    <td>{{ this.paymentdetails.number_of_nights }} Nights</td>
                  </tr>
                  <tr>
                    <td>Room or accommodation type reserved:</td>
                    <td> {{ this.paymentdetails.property.accommodation_type }}
                    </td>
                  </tr>
                  <tr>
                    <td>Number of guests:</td>
                    <td>{{ this.paymentdetails.number_of_guests }} </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <hr>
          <div class="row mt-4">
            <div class="col-log-12">
              <h3>Financial Details</h3>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <table class="table">
                <thead class="table-head-custom">
                  <tr>
                    <th scope="col">Title</th>
                    <th scope="col">Description</th>
                    <th scope="col">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Total reservation price:</td>
                    <td>Total amount to be paid, broken down if taxes or additional fees apply.</td>
                    <td>{{ this.paymentdetails.payment_currency }} {{ this.paymentdetails.total_price }}</td>
                  </tr>
                  <tr>
                    <td>Currency:</td>
                    <td>{{ this.paymentdetails.payment_currency }}</td>
                    <td> </td>
                  </tr>
                  <tr>
                    <td>Cost breakdown (optional): </td>
                    <td>Per Night Price: {{ this.paymentdetails.price_per_night }}, Host fee: {{
                      this.paymentdetails.hostFees }}, Tax: {{ this.paymentdetails.taxfee }}, Service Fee: {{
                        this.paymentdetails.servicefee }},</td>
                    <td> </td>
                  </tr>
                  <tr>
                    <td>Payment policy: </td>
                    <td>Whether an advance payment has been made, or if the full amount will be paid at
                      the property.
                    </td>
                    <td> </td>
                  </tr>
                  <tr>
                    <td>Cancellation and refund policy:</td>
                    <td>Information about potential charges or refunds in case of
                      cancellation</td>
                    <td> </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <hr>
          <div class="row mt-4">
            <div class="col-log-12">
              <h3>Other Details</h3>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <table class="table">
                <thead class="table-head-custom">
                  <tr>
                    <th scope="col">Title</th>
                    <th scope="col">Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Reservation confirmation code:</td>
                    <td>12345</td>
                  </tr>
                  <tr>
                    <td>Check-in and check-out instructions:</td>
                    <td>Checin After: {{ formatTime(paymentdetails.property.checkin_after) }}, Checkout Before: {{
                      formatTime(this.paymentdetails.property.checkout_before) }}</td>
                  </tr>
                  <tr>
                    <td>Property policies:</td>
                    <td>Children are {{ this.paymentdetails.property.children }} Events are{{
                      this.paymentdetails.property.Events }} <br> Pets are {{ this.paymentdetails.property.Pets }}
                      Smoking is {{ this.paymentdetails.property.Smoking }}</td>
                  </tr>
                  <tr>
                    <td>Included services:</td>
                    <td><span v-if="this.paymentdetails.property.wifi_available == 1">WIFI,</span><span
                        v-if="this.paymentdetails.property.tv_available == 1">TV </span> <span
                        v-if="this.paymentdetails.property.kitchen_available == 1">,kitchen </span></td>
                  </tr>
                  <tr>
                    <td>Property contact information:</td>
                    <td>{{ this.user.email }} <b>and</b> {{ this.user.mobile_phone }} for inquiries</td>
                  </tr>
                  <!-- <tr>
                    <td>Arrival instructions:</td>
                    <td>Lorem ipsum dolor sit amet consectetur.</td>
                  </tr> -->
                </tbody>
              </table>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-lg-6">
            </div>
            <div class="col-lg-6 d-flex justify-content-end">
              <h3 style="margin-right: 10px !important;">Total Amount:</h3>
              <h3 style="margin-right: 10px !important; color: #70d3ff;">{{ this.paymentdetails.payment_currency }} {{
                this.paymentdetails.total_price }} </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from "vue";
import API from "@/api";
import BarChart from "../components/charts/chartjs/barChart.vue";
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      paymentdetails: '',
      user: '',
      taxinvoice: '',
    };
  },
  methods: {
    formatTime(time) {
      const [hours, minutes] = time.split(':'); // Split the time into hours and minutes
      const date = new Date();
      date.setHours(hours);
      date.setMinutes(minutes);

      let hour = date.getHours();
      const minute = date.getMinutes();
      const period = hour >= 12 ? 'PM' : 'AM';

      hour = hour % 12 || 12; // Convert 24-hour time to 12-hour time
      const formattedTime = `${hour}:${minute < 10 ? '0' + minute : minute} ${period}`;

      return formattedTime;
    },
    getInvoicedetail() {
      const invoiceId = this.$route.params.id;
      API.getInvoicedetails(invoiceId,
        data => {
          this.paymentdetails = data.data.Details;
          this.user = data.data.User;
          this.taxinvoice = data.data.taxinvoice
        },
        err => {
          console.log(err);
        });
    },
    requestTaxInvoice(id, uid) {
      API.addTaxRequest({ id: id },
        data => {
          if (data.message) {
            Swal.fire({
              type: 'success',
              title: 'Success!',
              text: data.message,
              confirmButtonText: 'Ok',
            })
              .then((result) => {
                if (result.value) {
                  this.taxinvoice = data.tax_invoice;
                }
              });
          } else if (data.error) {
            this.handleApiError(data.error);
          }

        }, err => {
          console.log(err)
        })
    },
  },

  mounted() {
    this.getInvoicedetail();
  },

  computed: {
  },
  created() {
  }
};
</script>
<style>
.table-head-custom {
  background-color: #70d3ff;
  /* Green background */
  color: white;
  /* White text */
}
</style>