<template>
    <section class="tables new-updated-design add-property-wrap">
        <vue-snotify></vue-snotify>
        <div class="row">
            <div class="col-xl-12">
                <div class="left-card m-b-30">
                    <div class="Customer-card-body">
                        <div v-if="regMsgTextErr != ''">
                            <div class="alert-message md-form">
                                <div class="alert alert-danger">{{ regMsgTextErr }}</div>
                            </div>
                        </div>
                        <div v-if="regMsgTextSucc != ''">
                            <div class="alert-message md-form">
                                <div class="alert alert-success">{{ regMsgTextSucc }}</div>
                            </div>
                        </div>
                        <div class="step-bar">
                            <ul>
                                <li 
                                    v-for="step in steps" 
                                    :key="step" 
                                    :class="{
                                        active: currStep >= step,
                                        completed: currStep > step
                                    }"
                                >
                                {{ step }}
                                </li>
                            </ul>
                        </div>
                        <div class="card" v-if="currStep == 1">
                            <div class="card-heading">
                                <h5>What is the Type of your accommodation?</h5>
                            </div>
                            <div class="card-body md-form">
                                <div class="form-group m-0">
                                    <div class="radio-buttons-wrapper">
                                        <div v-for="(text, value) in translatedPropertyTypeOptions" :key="value"
                                            class="form-check ">
                                            <input type="radio" :id="'propertyType_' + value" :value="value"
                                                v-model="propertyData.propertyType" class="form-check-input"
                                                name="propertyType">
                                            <label style="margin-left: 0px;" :for="'propertyType_' + value"
                                                class="form-check-label ">{{ text
                                                }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" v-if="currStep == 2">
                            <div class="card-heading">
                                <h5>What is the type of your property?</h5>
                            </div>
                            <div class="card-body md-form">
                                <div class="form-group m-0">
                                    <div class="radio-buttons-wrapper">
                                        <div v-for="(text, value) in accomodationTypeOptions" :key="value"
                                            class="form-check ">
                                            <input type="radio" :id="'accomodationType_' + value" :value="value"
                                                v-model="propertyData.accomodationType" class="form-check-input"
                                                name="accomodationType">
                                            <label style="margin-left: 0px;" :for="'accomodationType_' + value"
                                                class="form-check-label ">{{ text
                                                }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card second-step" v-if="currStep == 3">
                            <div class="card-heading">
                                <h5>What services does your accommodation offer to guests?</h5>
                            </div>
                            <div class="card-body md-form">
                                <div class="form-group m-0">
                                    <div class="radio-buttons-wrapper">
                                        <label class="form-label" for="wifiAvailable">{{ $t('property_detail.wifi')
                                            }}</label>
                                        <div v-for="(optionText, optionValue) in translatedyesnoOptions"
                                            :key="optionValue" class="form-check">
                                            <input type="radio" :id="'wifiAvailable_' + optionValue"
                                                :value="optionValue" v-model="propertyData.wifiAvailable"
                                                class="form-check-input" name="wifiGroup">
                                            <label :for="'wifiAvailable_' + optionValue" class="form-check-label">{{
                                                optionText
                                            }}</label>
                                        </div>
                                    </div>
                                    <div class="radio-buttons-wrapper">
                                        <label class="form-label">{{ $t('property_detail.kitchen') }}</label>
                                        <div v-for="(optionText, optionValue) in translatedyesnoOptions"
                                            :key="optionValue" class="form-check">
                                            <input type="radio" :id="'kitchen_' + optionValue" :value="optionValue"
                                                v-model="propertyData.kitchen" class="form-check-input"
                                                name="kitchenGroup">
                                            <label :for="'kitchen_' + optionValue + '_' + propertyData.id"
                                                class="form-check-label">{{
                                                    optionText
                                                }}</label>
                                        </div>
                                    </div>
                                    <div class="radio-buttons-wrapper">
                                        <label class="form-label">{{ $t('property_detail.tv') }}</label>
                                        <div v-for="(optionText, optionValue) in translatedyesnoOptions"
                                            :key="optionValue" class="form-check">
                                            <input type="radio" :id="'tv_' + optionValue" :value="optionValue"
                                                v-model="propertyData.tv" class="form-check-input" name="tvGroup">
                                            <label :for="'tv_' + optionValue" class="form-check-label">{{ optionText
                                                }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" v-if="currStep == 4">
                            <div class="card-heading">
                                <h5>Accommodation details</h5>
                            </div>
                            <div class="card-body md-form accommodation-form">
                                <div class="form-group">
                                    <label for="max_guests">{{ $t('property_detail.Number_of_guests') }} <span
                                            class="text-red">*</span></label>
                                    <input type="number" id="max_guests" name="max_guests"
                                        :placeholder="$t('property_detail.Number_of_guests')" class="form-control"
                                        autocomplete="off" v-model="$v.propertyData.max_guests.$model" :maxlength="16"
                                        :state="$v.propertyData.max_guests.$dirty ? !$v.propertyData.max_guests.$error : null"
                                        aria-describedby="input-1-live-feedback">
                                </div>
                                <div class="form-group">
                                    <label for="min_guests">Minimum Guests Stay Duration<span
                                            class="text-red">*</span></label>
                                    <input type="number" id="min_guests" name="min_guests"
                                        :placeholder="$t('Minimum Guests Stay Duration')" class="form-control"
                                        autocomplete="off" v-model="$v.propertyData.min_guests.$model" :maxlength="16"
                                        :state="$v.propertyData.min_guests.$dirty ? !$v.propertyData.min_guests.$error : null"
                                        aria-describedby="input-1-live-feedback">
                                </div>
                                <div class="form-group">
                                    <label for="num_bedrooms">{{ $t('property_detail.Number_of_bedrooms') }} <span
                                            class="text-red">*</span></label>
                                    <input type="number" id="num_bedrooms" name="num_bedrooms"
                                        :placeholder="$t('property_detail.Number_of_bedrooms')" class="form-control"
                                        autocomplete="off" v-model="$v.propertyData.num_bedrooms.$model"
                                        :state="$v.propertyData.num_bedrooms.$dirty ? !$v.propertyData.num_bedrooms.$error : null"
                                        aria-describedby="input-1-live-feedback">
                                </div>
                                <div class="form-group quality-fileds">
                                    <div class="guest-content">
                                        <label for="king-bed">
                                            {{ $t('property_detail.king_beds') }}
                                        </label>
                                        <div class="guest-col">
                                            <template>
                                                <div class="quantity">
                                                    <b-input-group>
                                                        <b-input-group-prepend>
                                                            <b-btn variant="info" @click="kingBedDecrement()">-</b-btn>
                                                        </b-input-group-prepend>

                                                        <b-form-input type="number" min="0.00"
                                                            v-model="propertyData.king_size_bed"
                                                            :value="propertyData.king_size_bed"></b-form-input>

                                                        <b-input-group-append>
                                                            <b-btn variant="info" @click="kingBedIncrement()">+</b-btn>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="guest-content">
                                        <label for="king-bed">
                                            {{ $t('property_detail.queen_beds') }}
                                        </label>
                                        <div class="guest-col">
                                            <template>
                                                <div class="quantity">
                                                    <b-input-group>
                                                        <b-input-group-prepend>
                                                            <b-btn variant="info" @click="queenBedDecrement()">-</b-btn>
                                                        </b-input-group-prepend>

                                                        <b-form-input type="number" min="0.00"
                                                            v-model="propertyData.queen_size_bed"
                                                            :value="propertyData.queen_size_bed"></b-form-input>

                                                        <b-input-group-append>
                                                            <b-btn variant="info" @click="queenBedIncrement()">+</b-btn>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="guest-content">
                                        <label for="king-bed">
                                            {{ $t('property_detail.sofa_beds') }}
                                        </label>
                                        <div class="guest-col">
                                            <template>
                                                <div class="quantity">
                                                    <b-input-group>
                                                        <b-input-group-prepend>
                                                            <b-btn variant="info" @click="sofaBedDecrement()">-</b-btn>
                                                        </b-input-group-prepend>

                                                        <b-form-input type="number" min="0.00"
                                                            v-model="propertyData.sofa_bed"
                                                            :value="propertyData.sofa_bed"></b-form-input>

                                                        <b-input-group-append>
                                                            <b-btn variant="info" @click="sofaBedIncrement()">+</b-btn>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="num_bathrooms">{{ $t('property_detail.Number_of_bathrooms') }} <span
                                            class="text-red">*</span></label>
                                    <input type="number" id="num_bathrooms" name="num_bathrooms"
                                        :placeholder="$t('property_detail.Number_of_bathrooms')" class="form-control"
                                        autocomplete="off" v-model="$v.propertyData.num_bathrooms.$model"
                                        :state="$v.propertyData.num_bathrooms.$dirty ? !$v.propertyData.num_bathrooms.$error : null"
                                        aria-describedby="input-1-live-feedback">
                                </div>
                            </div>
                        </div>
                        <div class="card" v-if="currStep == 5">
                            <div class="card-heading">
                                <h5>Amenities details</h5>
                            </div>
                            <div class="card-body md-form amenities-form">
                                <div class="form-group mb-4">
                                    <div class="checkbox-group">
                                        <label class="custom-label">{{ $t('property_detail.Bedroom_Amenities')
                                            }}</label>

                                        <div class="form-check">
                                            <input type="checkbox" id="King_Bed" name="King_Bed" value="King_Bed"
                                                v-model="propertyData.bedroom_Amenities" class="form-check-input">
                                            <label for="King_Bed" class="form-check-label">{{
                                                $t('property_detail.King_Bed')
                                            }}</label>
                                        </div>

                                        <div class="form-check">
                                            <input type="checkbox" id="Double_Sofa_Bed" name="Double_Sofa_Bed"
                                                value="Double_Sofa_Bed" v-model="propertyData.bedroom_Amenities"
                                                class="form-check-input">
                                            <label for="Double_Sofa_Bed" class="form-check-label">{{
                                                $t('property_detail.Double_Sofa_Bed') }}</label>
                                        </div>

                                        <div class="form-check">
                                            <input type="checkbox" id="Double_Futon" name="Double_Futon"
                                                value="Double_Futon" v-model="propertyData.bedroom_Amenities"
                                                class="form-check-input">
                                            <label for="Double_Futon" class="form-check-label">{{
                                                $t('property_detail.Double_Futon')
                                            }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group m-0">
                                    <div class="checkbox-group">
                                        <label class="custom-label">{{
                                            $t('property_detail.bathroom_amenities') }}:</label>

                                        <div class="form-check">
                                            <input type="checkbox" id="Soap" name="Soap" value="Soap"
                                                v-model="propertyData.bathroom_Amenities" class="form-check-input">
                                            <label for="Soap" class="form-check-label">{{ $t('property_detail.Soap')
                                                }}</label>
                                        </div>

                                        <div class="form-check">
                                            <input type="checkbox" id="Towels_provided" name="Towels_provided"
                                                value="Towels_provided" v-model="propertyData.bathroom_Amenities"
                                                class="form-check-input">
                                            <label for="Towels_provided" class="form-check-label">{{
                                                $t('property_detail.Towels_provided') }}</label>
                                        </div>

                                        <div class="form-check">
                                            <input type="checkbox" id="Toilet" name="Toilet" value="Toilet"
                                                v-model="propertyData.bathroom_Amenities" class="form-check-input">
                                            <label for="Toilet" class="form-check-label">{{ $t('property_detail.Toilet')
                                                }}</label>
                                        </div>

                                        <div class="form-check">
                                            <input type="checkbox" id="Shower_only" name="Shower_only"
                                                value="Shower_only" v-model="propertyData.bathroom_Amenities"
                                                class="form-check-input">
                                            <label for="Shower_only" class="form-check-label">{{
                                                $t('property_detail.Shower_only')
                                            }}</label>
                                        </div>

                                        <div class="form-check">
                                            <input type="checkbox" id="Shampoo" name="Shampoo" value="Shampoo"
                                                v-model="propertyData.bathroom_Amenities" class="form-check-input">
                                            <label for="Shampoo" class="form-check-label">{{
                                                $t('property_detail.Shampoo')
                                            }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" v-if="currStep == 6">
                            <div class="card-heading">
                                <h5>Upload Images:</h5>
                            </div>
                            <div class="card-body md-form step-five">
                                <div class="form-group">
                                    <div class="upload-img">
                                        <div class="image">
                                            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M35.5859 18.0137C35.5859 8.34367 27.7459 0.513672 18.0859 0.513672C8.42594 0.513672 0.585938 8.34367 0.585938 18.0137C0.585938 27.6837 8.41594 35.5137 18.0859 35.5137C27.7559 35.5137 35.5859 27.6837 35.5859 18.0137ZM18.0359 29.1437C16.7659 29.1437 15.7259 28.1137 15.7359 26.8337V14.7537L11.8459 18.6537C10.9459 19.5537 9.48594 19.5537 8.58594 18.6537C7.68594 17.7537 7.68594 16.2937 8.58594 15.3837L16.4059 7.54367C17.3059 6.64367 18.7659 6.64367 19.6659 7.54367L27.4859 15.3837C28.3859 16.2837 28.3859 17.7437 27.4859 18.6537C26.5859 19.5637 25.1259 19.5537 24.2259 18.6537L20.3359 14.7537V26.8337C20.3359 28.1037 19.3059 29.1437 18.0359 29.1437ZM48.5259 33.7437C48.5259 31.3737 46.6059 29.4537 44.2359 29.4537C41.8659 29.4937 39.9759 31.4437 40.0059 33.8137C40.0459 36.1337 41.9159 38.0037 44.2359 38.0437C46.6059 38.0437 48.5259 36.1137 48.5259 33.7537V33.7437ZM52.3159 17.9037H39.7059C39.7059 19.9437 39.4259 21.9137 38.8959 23.7837H52.3159C53.2959 23.7837 54.0759 24.5737 54.0759 25.5437V48.5337L51.9159 46.3337C51.3559 45.7737 50.6059 45.4537 49.8159 45.4537C49.0259 45.4537 48.2659 45.7837 47.7259 46.3437L43.7359 50.4637L33.5859 40.2637C32.4459 39.1137 30.5859 39.1037 29.4359 40.2537L23.8859 45.7637V38.7437C21.9659 39.2737 19.9859 39.5437 17.9959 39.5337V52.2537C17.9959 56.4637 21.4259 59.9037 25.6359 59.9037H52.3159C56.5259 59.9037 59.9559 56.4637 59.9559 52.2537V25.5637C59.9559 21.3537 56.5359 17.9137 52.3159 17.9137V17.9037Z" fill="#D9D9D9"/>
                                            </svg>      
                                        </div>
                                        <!-- <label for="images">{{ $t('property_detail.Upload_Images') }}</label> -->
                                        <label for="images">Choose File</label>
                                        <input type="file" id="images" name="images[]" @change="previewImages" multiple
                                            accept="image/*">
                                        <p>or drag and drop an image anywhere on the page</p>
                                    </div>

                                    <div v-if="propertyData.imagePreviews.length > 0" class="images-listing">
                                        <div v-for="(imagePreview, index) in propertyData.imagePreviews" :key="index"
                                            class="images-card">
                                            <img v-if="imagePreview.path" :src="imagePreview.path" alt="Preview">
                                            <img v-else :src="imagePreview" alt="Preview">
                                            <button type="button" class="btn btn-primary" @click="removeImage(index)">
                                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15.7868 12.9865L24.7344 4.14649C25.5503 3.34411 25.5572 2.02987 24.7551 1.21366C23.953 0.397447 22.6392 0.39053 21.8233 1.19291L12.8758 10.0329L4.03193 1.08223C3.22983 0.266023 1.91605 0.259106 1.10012 1.06148C0.284189 1.87078 0.277274 3.18502 1.07937 4.00123L9.91629 12.9519L0.968739 21.7919C0.152811 22.5943 0.145896 23.9085 0.947995 24.7247C1.35596 25.1328 1.88839 25.3403 2.42082 25.3403C2.95324 25.3403 3.47184 25.1397 3.87981 24.7386L12.8274 15.8986L21.6643 24.8492C22.0722 25.2573 22.6047 25.4648 23.1371 25.4648C23.6695 25.4648 24.1881 25.2642 24.5961 24.8631C25.412 24.0607 25.4189 22.7464 24.6168 21.9302L15.7799 12.9796L15.7868 12.9865Z" fill="white"/>
                                                </svg>
                                                <!-- {{ $t('property_detail.Remove') }} -->
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" v-if="currStep == 7">
                            <div class="card-heading">
                                <h5>Description</h5>
                            </div>
                            <div class="card-body md-form">
                                <div class="form-group">
                                    <label for="description">{{ $t('property_detail.Description') }}</label>
                                    <textarea id="description" class="form-control" v-model="propertyData.description"
                                        :placeholder="$t('property_detail.Description')" rows="3"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="card" v-if="currStep == 8">
                            <div class="card-heading">
                                <h5>Price per night</h5>
                            </div>
                            <div class="card-body md-form">
                                <div class="form-group">
                                    <label for="price">{{ $t('property_detail.price') }}</label>
                                    <div>
                                        <input type="number" id="price" name="price" class="form-control"
                                            :placeholder="$t('property_detail.Price')" v-model="propertyData.price"
                                            :state="$v.propertyData.price.$dirty ? !$v.propertyData.price.$error : null"
                                            aria-describedby="input-1-live-feedback" required>
                                    </div>
                                    <div class="invalid-feedback" v-if="$v.propertyData.price.$error"
                                        id="input-1-live-feedback">
                                        This is a required field
                                    </div>
                                    <span v-if="propertyData.priceAmount" class="text-danger">{{
                                        propertyData.priceAmount
                                    }}</span>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4">
                                        <label for="price">Add Discount for specific days</label>
                                        <div class="form-check form-switch m-0">
                                            <label class="switch m-0">
                                                <input class="form-check-input" type="checkbox" id="discountToggle"
                                                    v-model="discountToggle">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <label for="price">Add discount on long stays</label>
                                        <div class="form-check form-switch m-0">
                                            <label class="switch m-0">
                                                <input class="form-check-input" type="checkbox" id="longstaysToggle"
                                                    v-model="longstaysToggle">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <label for="price">Add flash discount</label>
                                        <div class="form-check form-switch">
                                            <label class="switch">
                                                <input class="form-check-input" type="checkbox" id="flashdiscountToggle"
                                                    v-model="flashdiscountToggle">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <span>Note: A flash offer increases property visibility and draws more interest.</span>
                            </div>
                            <div class="row mx-0">
                                <div class="col-lg-4">
                                    <div class="card-body pricing-form md-form mx-0" v-if="discountToggle">
                                        <div class="form-group m-0">
                                            <div>
                                                <div class="mb-4">
                                                    <label for="discountPercentage">Discount Percentage</label>
                                                    <select id="discountPercentage" name="discountPercentage"
                                                        class="form-control" v-model="propertyData.discountpersentage">
                                                        <option value="" disabled selected>Select a discount percentage
                                                        </option>
                                                        <option v-for="percent in discountOptions" :key="percent"
                                                            :value="percent">
                                                            {{ percent }}%
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12 mb-4">
                                                    <label for="discount">Discount Start Date</label>
                                                    <div>
                                                        <input type="date" id="discountprice" name="discountprice"
                                                            class="form-control" placeholder="Discounted Price"
                                                            v-model="propertyData.discountsdate">
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 mb-4">
                                                    <label for="discount">Discount End Date</label>
                                                    <div>
                                                        <input type="date" id="discountprice" name="discountprice"
                                                            class="form-control" placeholder="Discounted Price"
                                                            v-model="propertyData.discountedate">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 p-lg-0">
                                    <div class="card-body pricing-form md-form mt-0 pt-0 mx-0" v-if="longstaysToggle">
                                        <div class="form-group m-0">
                                            <div>
                                                <div class="row">
                                                    <div class="col-lg-12 mb-4">
                                                        <div>
                                                            <label for="discountPercentage">One-week
                                                                stay</label>
                                                            <select id="discountPercentage" name="discountPercentage"
                                                                class="form-control" v-model="propertyData.oneweekdisc">
                                                                <option value="" disabled selected>Select a discount
                                                                    percentage
                                                                </option>
                                                                <option v-for="percent in discountOptions"
                                                                    :key="percent" :value="percent">
                                                                    {{ percent }}%
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 mb-4">
                                                        <div class=" ">
                                                            <label for="discountPercentage">Two-week stay</label>
                                                            <select id="discountPercentage" name="discountPercentage"
                                                                class="form-control" v-model="propertyData.twoweekdisc">
                                                                <option value="" disabled selected>Select a discount
                                                                    percentage
                                                                </option>
                                                                <option v-for="percent in discountOptions"
                                                                    :key="percent" :value="percent">
                                                                    {{ percent }}%
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-12 mb-4">
                                                        <div class="mt-0">
                                                            <label for="discountPercentage">Three-week stay</label>
                                                            <select id="discountPercentage" name="discountPercentage"
                                                                class="form-control"
                                                                v-model="propertyData.threeweekdisc">
                                                                <option value="" disabled selected>Select a discount
                                                                    percentage
                                                                </option>
                                                                <option v-for="percent in discountOptions"
                                                                    :key="percent" :value="percent">
                                                                    {{ percent }}%
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 mb-4">
                                                        <div class="mt-0">
                                                            <label for="discountPercentage">One-month stay</label>
                                                            <select id="discountPercentage" name="discountPercentage"
                                                                class="form-control" v-model="propertyData.onemonthdis">
                                                                <option value="" disabled selected>Select a discount
                                                                    percentage
                                                                </option>
                                                                <option v-for="percent in discountOptions"
                                                                    :key="percent" :value="percent">
                                                                    {{ percent }}%
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="card-body pricing-form md-form mt-0 pt-0 mx-0" v-if="flashdiscountToggle">
                                        <div class="form-group m-0">
                                            <div>
                                                <div class="mb-4">
                                                    <label for="flashdiscountPercentage">Flash Discount
                                                        Percentage</label>
                                                    <select id="flashdiscountPercentage" name="flashdiscountPercentage"
                                                        class="form-control"
                                                        v-model="propertyData.flashdiscountPercentage">
                                                        <option value="" disabled selected>Select a flash discount
                                                            percentage
                                                        </option>
                                                        <option v-for="percent in flashdiscountOptions" :key="percent"
                                                            :value="percent">
                                                            {{ percent }}%
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12 mb-4">
                                                    <label for="discount">Discount Date</label>
                                                    <div>
                                                        <input type="date" id="discountprice" name="discountprice"
                                                            class="form-control" placeholder="Discounted Price"
                                                            v-model="propertyData.flashstartDate">
                                                    </div>
                                                </div>
                                                <!-- <div class="col-lg-6 mb-4">
                                                    <label for="discount">Discount End Date</label>
                                                    <div>
                                                        <input type="date" id="discountprice" name="discountprice"
                                                            class="form-control" placeholder="Discounted Price"
                                                            v-model="propertyData.flashendDate">
                                                    </div>
                                                </div> -->
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="card" v-if="currStep == 9">
                            <div class="card-heading">
                                <h5>House Rules</h5>
                            </div>
                            <div class="card-body md-form house-form">
                                <div class="form-group">
                                    <label for="checkin_after" class="form-label">{{
                                        $t('property_detail.check_in_after') }}</label>
                                    <input type="time" id="checkin_after" name="checkin_after"
                                        placeholder="Check In After" v-model="$v.propertyData.checkin_after.$model"
                                        :class="{ 'is-invalid': $v.propertyData.checkin_after.$dirty && $v.propertyData.checkin_after.$error }"
                                        aria-describedby="checkinafter" class="form-control">
                                    <div v-if="$v.propertyData.checkin_after.$error" id="checkinafter"
                                        class="invalid-feedback">
                                        This is a required field
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="checkout_before" class="form-label">{{
                                        $t('property_detail.check_in_before') }}</label>
                                    <input type="time" id="checkout_before" name="checkout_before"
                                        :placeholder="$t('property_detail.Check_out_before')"
                                        v-model="$v.propertyData.checkout_before.$model"
                                        :class="{ 'is-invalid': $v.propertyData.checkout_before.$dirty && $v.propertyData.checkout_before.$error }"
                                        aria-describedby="checkoutbefore" class="form-control">
                                    <div v-if="$v.propertyData.checkout_before.$error" id="checkoutbefore"
                                        class="invalid-feedback">
                                        This is a required field
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="min_age" class="form-label">{{ $t('property_detail.min_age_to_rent')
                                        }}</label>
                                    <input type="number" id="min_age" name="min_age" placeholder="Minimum age to rent"
                                        v-model="$v.propertyData.min_age.$model"
                                        :class="{ 'is-invalid': $v.propertyData.min_age.$dirty && $v.propertyData.min_age.$error }"
                                        aria-describedby="minage" class="form-control">
                                    <div v-if="$v.propertyData.min_age.$error" id="minage" class="invalid-feedback">
                                        This is a required field
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="children" class="form-label">{{ $t('property_detail.children')
                                        }}</label>
                                    <select id="children" name="children" v-model="propertyData.children"
                                        :class="{ 'is-invalid': $v.propertyData.children.$dirty && $v.propertyData.children.$error }"
                                        class="form-control">
                                        <option value="" disabled>Select an option</option>
                                        <option v-for="option in childrens" :key="option" :value="option">
                                            {{ option }}
                                        </option>
                                    </select>
                                    <div v-if="$v.propertyData.children.$error" id="input-1-live-feedback"
                                        class="invalid-feedback">
                                        This is a required field.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="Events" class="form-label">{{ $t('property_detail.events') }}</label>
                                    <select id="Events" name="Events" v-model="propertyData.Events"
                                        :class="{ 'is-invalid': $v.propertyData.Events.$dirty && $v.propertyData.Events.$error }"
                                        class="form-control">
                                        <option value="" disabled>Select an option</option>
                                        <option v-for="option in Events" :key="option" :value="option">
                                            {{ option }}
                                        </option>
                                    </select>
                                    <div v-if="$v.propertyData.Events.$error" id="Events-feedback"
                                        class="invalid-feedback">
                                        This is a required field.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="Pets" class="form-label">{{ $t('property_detail.pets') }}</label>
                                    <select id="Pets" name="Pets" v-model="propertyData.Pets"
                                        :class="{ 'is-invalid': $v.propertyData.Pets.$dirty && $v.propertyData.Pets.$error }"
                                        class="form-control">
                                        <option value="" disabled>Select an option</option>
                                        <option v-for="option in Pets" :key="option" :value="option">
                                            {{ option }}
                                        </option>
                                    </select>
                                    <div v-if="$v.propertyData.Pets.$error" id="Pets-feedback" class="invalid-feedback">
                                        This is a required field.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="Smoking" class="form-label">{{ $t('property_detail.smoking') }}</label>
                                    <select id="Smoking" name="Smoking" v-model="propertyData.Smoking"
                                        :class="{ 'is-invalid': $v.propertyData.Smoking.$dirty && $v.propertyData.Smoking.$error }"
                                        class="form-control">
                                        <option value="" disabled>Select an option</option>
                                        <option v-for="option in Smoking" :key="option" :value="option">
                                            {{ option }}
                                        </option>
                                    </select>
                                    <div v-if="$v.propertyData.Smoking.$error" id="Smoking-feedback"
                                        class="invalid-feedback">
                                        This is a required field.
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="card" v-if="currStep == 10">
                            <div class="card-heading">
                                <h5>Where is the accommodation you want to add located?</h5>
                            </div>
                            <div class="card-body md-form house-form">
                                <div class="form-group">
                                    <label for="city">{{ $t('properties_list.city') }}</label>
                                    <input type="text" id="city" name="city" class="form-control"
                                        :placeholder="$t('properties_list.city')" v-model="propertyData.city"
                                        :class="{ 'is-invalid': $v.propertyData.city.$error }"
                                        aria-describedby="input-1-live-feedback">
                                </div>
                                <div class="form-group" v-if="region == 'United States'">
                                    <label for="state">{{ $t('properties_list.state') }}</label>
                                    <input type="text" id="state" name="state" class="form-control"
                                        :placeholder="$t('properties_list.state')" v-model="propertyData.state"
                                        :class="{ 'is-invalid': $v.propertyData.state.$error }"
                                        aria-describedby="input-1-live-feedback">
                                </div>
                                <!-- <div class="form-group">
                                    <label for="country">{{ $t('property_detail.Country') }}</label>
                                    <input type="text-area" id="country" name="country" class="form-control"
                                        :placeholder="$t('property_detail.Country')" v-model="propertyData.country"
                                        :class="{ 'is-invalid': $v.propertyData.country.$error }"
                                        aria-describedby="input-1-live-feedback">
                                </div> -->
                                <div class="form-group">
                                    <label for="country">{{ $t('property_detail.Country') }}<span
                                            class="text-red">*</span></label>
                                    <select id="country" name="country" v-model="propertyData.country"
                                        class="form-control"
                                        :class="{ 'is-invalid': $v.propertyData.country.$error }">
                                        <option value="" disabled>{{ $t('property_detail.Country') }}</option>
                                        <option v-for="country in countries" :key="country.id" :value="country.name">
                                            {{ country.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label for="address">{{ $t('property_detail.address') }}</label>
                                    <input type="text" id="address" name="address" class="form-control" @input="onInput"
                                        :placeholder="$t('property_detail.address')" v-model="propertyData.address"
                                        :class="{ 'is-invalid': $v.propertyData.address.$error }"
                                        aria-describedby="input-1-live-feedback">

                                    <ul v-if="suggestions.length > 0" class="suggestions-list" style="z-index: 9999;">
                                        <li v-for="(suggestion, index) in suggestions" :key="index"
                                            @click="selectSuggestion(suggestion)">
                                            {{ suggestion.description }}
                                        </li>
                                    </ul>
                                </div>
                                <!-- <div class="form-group">
                                    <label for="address">{{ $t('property_detail.address') }}</label>
                                    <input type="text-area" id="address" name="address" class="form-control"
                                        @input="onInput" :placeholder="$t('property_detail.address')"
                                        v-model="propertyData.address"
                                        :class="{ 'is-invalid': $v.propertyData.address.$error }"
                                        aria-describedby="input-1-live-feedback">
                                    <ul v-if="suggestions.length > 0" class="suggestions-list" style="z-index: 9999;">
                                        <li v-for="(suggestion, index) in suggestions" :key="index"
                                            @click="selectSuggestion(suggestion)">
                                            {{ suggestion.description }}
                                        </li>
                                    </ul>
                                </div> -->


                            </div>
                        </div>
                        <div class="card" v-if="currStep == 11">
                            <div class="card-heading">
                                <h5>Account Details</h5>
                            </div>
                            <div class="card-body md-form">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="checkin_after" class="form-label">{{
                                                $t('property_detail.cardholder_name') }}</label>
                                            <input type="text" id="Cardholder's Name" name="Cardholder's Name"
                                                placeholder="Cardholder's Name" aria-describedby="checkinafter"
                                                class="form-control">
                                            <div v-if="$v.propertyData.checkin_after.$error" id="checkinafter"
                                                class="invalid-feedback">
                                                This is a required field
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="checkin_after" class="form-label">{{ $t('property_detail.card_number')
                                                }}</label>
                                            <input type="text" id="Card Number" name="Card Number" placeholder="Card Number"
                                                aria-describedby="checkinafter" class="form-control">
                                            <div v-if="$v.propertyData.checkin_after.$error" id="checkinafter"
                                                class="invalid-feedback">
                                                This is a required field
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="checkin_after" class="form-label">{{ $t('property_detail.cvv')
                                                }}</label>
                                            <input type="text" id="CVV" name="CVV" placeholder="CVV"
                                                aria-describedby="checkinafter" class="form-control">
                                            <div v-if="$v.propertyData.checkin_after.$error" id="checkinafter"
                                                class="invalid-feedback">
                                                This is a required field
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="checkin_after" class="form-label">{{
                                                $t('property_detail.expiry_date') }}</label>
                                            <input type="date" id="Exp Date" name="Exp Date" placeholder="Exp Date"
                                                aria-describedby="checkinafter" class="form-control">
                                            <div v-if="$v.propertyData.checkin_after.$error" id="checkinafter"
                                                class="invalid-feedback">
                                                This is a required field
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card laststep p-5" v-if="currStep == 12">
                            <div class="alldone-heading">
                                <div class="icon">
                                    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="40" cy="40" r="40" fill="#70D3FF"/>
                                        <path d="M58.3409 26.5233C57.0409 25.6233 55.0409 25.9233 53.8409 27.1233L35.7409 45.2233L26.9409 36.4233C26.2409 35.7233 25.4409 35.4233 24.4409 35.4233C23.4409 35.4233 22.5409 35.8233 21.8409 36.6233C20.6409 38.0233 20.7409 40.2233 22.0409 41.5233L34.0409 53.5233C34.4409 53.9233 35.0409 54.2233 35.7409 54.2233C36.3409 54.2233 36.9409 54.0233 37.4409 53.5233L58.9409 31.9233C59.6409 31.2233 60.0409 30.1233 59.9409 29.1233C59.8409 28.1233 59.2409 27.1233 58.3409 26.5233Z" fill="white"/>
                                    </svg>     
                                </div>
                                <h5>Thank You!</h5>
                                <!-- <h5>{{ $t('property_detail.all_done') }}!</h5> -->
                                <p>We’re sent your free report to your inbox so it’s easy to access. You can find
                                        more information on our website and social pages.</p>
                            </div>
                            <div class="md-form">
                                <button type="button" class="btn btn-primary list-property" @click="addProperty()"
                                    style="margin-left: 60%; ">{{
                                        $t('property_detail.list_property') }}</button>
                            </div>
                        </div>
                        <div class="foot-btn" v-if="currStep < 13">
                            <div class="footer-buttons md-form">
                                <!-- Back Button -->
                                <button 
                                    id="bckBtn" 
                                    name="bckBtn" 
                                    class="btn btn-default pull-left" 
                                    v-if="currStep > 1"
                                    @click="bckBtn" 
                                    :disabled="regBusy"
                                >
                                    <span v-if="!regBusy">{{ $t('register.back') }}</span>
                                    <span v-else>
                                        <i class="fa fa-spinner fa-spin"></i> {{ $t('register.loading') }}
                                    </span>
                                </button>
                            
                                <!-- Submit Button -->
                                <button 
                                    id="regSub" 
                                    name="regSub" 
                                    class="btn btn-primary pull-right" 
                                    @click="regSub"
                                    :disabled="regBusy" 
                                    v-if="currStep !== 12"
                                >
                                    <span v-if="!regBusy">{{ regBtnText }}</span>
                                    <span v-else>
                                        <i class="fa fa-spinner fa-spin"></i> {{ $t('register.loading') }}
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div class="foot-btn" v-if="currStep == 13">
                            <div class="card-body md-form">
                                <button id="regSingIn" name="regSingIn" class="btn btn-primary pull-right"
                                    @click="regSingIn">{{
                                        $t('register.sign_in') }}</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div></div>
    </section>
</template>

<script>
import Vue from "vue";
import API from '@/api'
import { Loader } from '@googlemaps/js-api-loader';
import Snotify, { SnotifyPosition } from 'vue-snotify'
import { validationMixin } from "vuelidate";
import { required, minLength, url, email, sameAs } from "vuelidate/lib/validators";

const options = {
    toast: {
        position: SnotifyPosition.rightTop,
    }
}
Vue.use(Snotify, options)
export default {
    // name: addProperty,
    mixins: [validationMixin],
    data() {
        return {
            currStep: 1,
            steps: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            regBtnText: this.$t('register.next'),
            regBusy: false,
            regEmail: '',
            regPhone: '',
            regFname: '',
            type: 'password',
            regPassword: '',
            discountOptions: [5, 10, 15, 20, 25, 30, 50],
            flashdiscountOptions: [20, 25, 30, 50],
            discountToggle: false,
            flashdiscountToggle: false,
            longstaysToggle: false,
            btnText: 'fa fa-eye',
            regCpassword: '',
            regMsgTextErr: '',
            regMsgTextSucc: '',
            region: localStorage.getItem('region'),
            maxPropertyPrice: 99,
            bathroom_Amenities: {
                Soap: false,
                Towels_provided: false,
                Toilet: false,
                Shower_only: false,
                Shampoo: false,
            },
            bedroom_Amenities: {
                King_Bed: false,
                Double_Sofa_Bed: false,
                Double_Futon: false,
            },
            propertyData: {
                lang: "en",
                city: "",
                state: "",
                country: "",
                address: "",
                price: "",
                discountpersentage: "",
                flashdiscountPercentage: "",
                oneweekdisc: "",
                twoweekdisc: "",
                threeweekdisc: "",
                onemonthdis: "",
                discountsdate: "",
                discountedate: "",
                flashstartDate: "",
                // flashendDate: "",
                priceAmount: "",
                max_guests: "",
                min_guests: "",
                num_bedrooms: "",
                checkin_after: "",
                checkout_before: "",
                num_bathrooms: "",
                min_age: "",
                propertyType: "",
                accomodationType: "",
                children: "",
                Events: "",
                Pets: "",
                Smoking: "",
                wifiAvailable: null,
                kitchen: null,
                tv: null,
                king_size_bed: 0,
                queen_size_bed: 0,
                sofa_bed: 0,
                description: "",
                imagePreviews: [],
                bathroom_Amenities: [],
                bedroom_Amenities: [],
                id: "",
                currency: 'USD',
            },
            currencyOptions: [
                { value: 'USD', text: 'USD' },
                { value: 'EUR', text: 'Euro' },
                { value: 'GBP', text: 'Pound' },
                // Add more currencies as needed
            ],
            id: "",
            propertyTypeOptions: {
                'Entire Place': 'Entire Place',
                'Private Room': 'Private Room',
                'Shared Room': 'Shared Room',
                'Beachfront': 'Beachfront',
                'Mountain': 'Mountain',
                'Camping': 'Camping',
                'Lakefront': 'Lakefront',
                'Ski': 'Ski',
                'Desert': 'Desert',
                'Tropical': 'Tropical',
                // 'With_pool': 'With pool',
                'With_views': 'With views',
            },
            accomodationTypeOptions: {
                'Apartment': 'Apartment',
                'Duplex': 'Duplex',
                'Studio': 'Studio',
                'Chalet': 'Chalet',
                'Townhouse': 'Townhouse',
                'Duplex apartment': 'Duplex apartment',
                'Detached house': 'Detached house',
                'Loft': 'Loft',
                'Bungalow': 'Bungalow',
                'Micro-apartment': 'Micro-apartment',
                'Duplex (two-unit home)': 'Duplex (two-unit home)',
                'Triplex': 'Triplex',
                'Mobile home': 'Mobile home',
                'Prefabricated house': 'Prefabricated house',
                'Cabin': 'Cabin',
                'Semi-detached house': 'Semi-detached house',
                'Row house': 'Row house',
                // 'Mansion': 'Mansion',
                'Penthouse': 'Penthouse',
                'Cottage': 'Cottage',
                'Villa': 'Villa',
                'Floating home': 'Floating home',
                'Ranch': 'Ranch'
            },

            childrens: {
                'Not Allowd': 'Not Allowed',
                'Allowed: ages 0-13': 'Allowed: ages 0-13',
                'Allowed: ages 0-17': 'Allowed: ages 0-17',
            },
            Events: {
                'Not Allowd': 'Not Allowed',
                'Allowed: Family gatherings and Birthday parties': 'Allowed: Family gatherings and Birthday parties',
                'Allowed: Family gatherings': 'Allowed: Family gatherings',
                'Allowed: Birthday parties': 'Allowed:  Birthday parties',
            },
            Pets: {
                'Not Allowd': 'Not Allowed',
                'Allowed: cats less than 20 lbs per pet (limit 1 pets total)': 'Allowed: cats less than 20 lbs per pet (limit 1 pets total)',
                'Allowed: cats less than 20 lbs per pet (limit 2 pets total)': 'Allowed: cats less than 20 lbs per pet (limit 2 pets total)',
                'Allowed: cats less than 20 lbs per pet (limit 3 pets total)': 'Allowed: cats less than 20 lbs per pet (limit 3 pets total)',
            },
            Smoking: {
                'Not Allowd': 'Not Allowed',
                'Allowed': 'Allowed',
                'Allowed: in designated areas': 'Allowed: in designated areas',
            },
            yesNoOptions: {
                1: 'Yes',
                0: 'No'
            },
            countries: [],
            validaddress: true,
            placesService: null,
            suggestions: [],
        };
    },
    validations: {
        propertyData: {

            num_bathrooms: {
                required,
            },
            max_guests: {
                required,
            },
            min_guests: {
                required,
            },
            num_bedrooms: {
                required,
            },
            checkin_after: {
                required,

            },
            checkout_before: {
                required,
            },
            min_age: {
                required,
            },
            Events: {
                required,
            },
            Pets: {
                required,
            },
            Smoking: {
                required,
            },
            price: {
                required,
            },
            country: {
                required,
            },
            address: {
                required,
            },
            state: {
                required,
            },
            city: {
                required,
            },
            propertyType: {
                required,
            },
            accomodationType: {
                required,
            },
            children: {
                required,
            },
            wifiAvailable: {
                required: (value) => value === 1 || value === 0,
            },
            kitchen: {
                required: (value) => value === 1 || value === 0,
            },
            tv: {
                required: (value) => value === 1 || value === 0,
            },

        }
    },

    methods: {
        nextStep() {
            if (this.currStep < 13) {
                this.currStep++;
            }
        },
        prevStep() {
            if (this.currStep > 1) {
                this.currStep--;
            }
        },
        getMaxPropertyPrice() {
            let currecny = localStorage.getItem('currency');
            API.getMaxPrice(currecny,
                data => {
                    let maxPrice = data.data.maxPrice
                    this.maxPropertyPrice = parseInt(maxPrice)
                    // console.log(this.maxPropertyPrice, "Max property price")
                }, err => (
                    console.log(err)
                ))
        },
        kingBedIncrement() {
            this.propertyData.king_size_bed++
        },
        kingBedDecrement() {
            if (this.propertyData.king_size_bed > 0) {
                this.propertyData.king_size_bed--
            }
        },
        queenBedIncrement() {
            this.propertyData.queen_size_bed++
        },
        queenBedDecrement() {
            if (this.propertyData.queen_size_bed > 0) {
                this.propertyData.queen_size_bed--
            }
        },
        sofaBedIncrement() {
            this.propertyData.sofa_bed++
        },
        sofaBedDecrement() {
            if (this.propertyData.sofa_bed > 0) {
                this.propertyData.sofa_bed--
            }
        },
        showPassword() {
            if (this.type === 'password') {
                this.type = 'text'
                this.btnText = 'fa fa-eye-slash'
            } else {
                this.type = 'password'
                this.btnText = 'fa fa-eye'
            }
        },
        bckBtn() {
            if (this.currStep > 1) {
                this.regMsgTextErr = '';
                this.regBtnText = this.$t('register.next');
                this.currStep = this.currStep - 1;
            }
        },
        enforcePhoneFormat() {
            let x = this.regPhone
                .replace(/\D/g, "")
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

            this.regPhone = !x[2]
                ? x[1]
                : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
        },
        regSub() {
            this.regMsgTextErr = '';
            if (this.currStep === 1) {
                this.$v.propertyData.propertyType.$touch();

                if (!this.$v.propertyData.propertyType.required) {
                    this.regMsgTextErr = 'Please Select Type Of your Accomodation';
                    return;
                }
                // if (!this.$v.propertyData.accomodationType.required) {
                //     this.regMsgTextErr = 'Please Select Type Of your Property';
                //     return;
                // }
                this.currStep = 2;
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep === 2) {
                this.$v.propertyData.propertyType.$touch();

                // if (!this.$v.propertyData.propertyType.required) {
                //     this.regMsgTextErr = 'Please Select Type Of your Accomodation';
                //     return;
                // }
                if (!this.$v.propertyData.accomodationType.required) {
                    this.regMsgTextErr = 'Please Select Type Of your Property';
                    return;
                }
                this.currStep = 3;
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep === 3) {
                this.regBtnText = this.$t('register.next');
                if (this.propertyData.wifiAvailable === null) {
                    this.regMsgTextErr = 'Please Select Any Option in Wifi';
                    return;
                }
                if (this.propertyData.kitchen === null) {
                    this.regMsgTextErr = 'Please Select Any Option in Kitchen';
                    return;
                }
                if (this.propertyData.tv === null) {
                    this.regMsgTextErr = 'Please Select Any Option in TV';
                    return;
                }
                this.currStep = 4;
                this.regBtnText = this.$t('register.next');

            } else if (this.currStep == 4) {
                this.regBtnText = this.$t('register.next');
                if (!this.propertyData.max_guests) {
                    this.regMsgTextErr = 'Please Add the Number Of Guests';
                    return;
                }
                if (!this.propertyData.min_guests) {
                    this.regMsgTextErr = 'Please Add Minimum Guests Stay Duration';
                    return;
                }
                if (!this.propertyData.num_bedrooms) {
                    this.regMsgTextErr = 'Please Add the Number Of Bedrooms';
                    return;
                }
                if (!this.propertyData.num_bathrooms) {
                    this.regMsgTextErr = 'Please Add the Number Of Bathrooms';
                    return;
                }
                this.currStep = 5;
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep == 5) {
                console.log(this.propertyData)
                this.currStep = 6;
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep == 6) {
                if (!this.propertyData.imagePreviews.length) {
                    this.regMsgTextErr = 'Please Add the Images of Property';
                    return;
                }
                if (this.propertyData.imagePreviews.length < 4) {
                    this.regMsgTextErr = 'Please Add Atleast 4 Images of Property';
                    return;
                }
                this.currStep = 7;
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep == 7) {

                this.currStep = 8;
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep == 8) {
                if (!this.propertyData.price) {
                    this.regMsgTextErr = 'Please Add the Price Per night';
                    return;
                }
                if (this.propertyData.price > this.maxPropertyPrice) {
                    this.propertyData.priceAmount = `Price must be ${this.maxPropertyPrice} or less`;
                    return;
                }
                if (this.discountToggle) {
                    const discountStartDate = new Date(this.propertyData.discountsdate);
                    const discountEndDate = new Date(this.propertyData.discountedate);

                    if (!this.propertyData.discountpersentage) {
                        this.regMsgTextErr = 'Please select a discount percentage.';
                        return;
                    }

                    if (!this.propertyData.discountsdate || !this.propertyData.discountedate) {
                        this.regMsgTextErr = 'Please select both start and end dates for the discount.';
                        return;
                    }

                    if (discountStartDate > discountEndDate) {
                        this.regMsgTextErr = 'The discount start date cannot be after the end date.';
                        return;
                    }

                    const diffInDays = Math.ceil((discountEndDate - discountStartDate) / (1000 * 60 * 60 * 24));
                    if (diffInDays > 14) {
                        this.regMsgTextErr = 'Discount duration cannot exceed 2 weeks.';
                        return;
                    }
                }
                if (this.flashdiscountToggle) {
                    const flashstartDate = new Date(this.propertyData.flashstartDate);
                    // const flashendDate = new Date(this.propertyData.flashendDate);

                    if (!this.propertyData.flashdiscountPercentage) {
                        this.regMsgTextErr = 'Please select a flash discount percentage.';
                        return;
                    }

                    if (!this.propertyData.flashstartDate) {
                        this.regMsgTextErr = 'Please select the start date for the discount.';
                        return;
                    }
                }
                this.currStep = 9;
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep == 9) {
                if (!this.propertyData.checkin_after) {
                    this.regMsgTextErr = 'Please Add the Checkin TIme';
                    return;
                }
                if (!this.propertyData.checkout_before) {
                    this.regMsgTextErr = 'Please Add the Checkout TIme';
                    return;
                } if (!this.propertyData.min_age) {
                    this.regMsgTextErr = 'Please Add Min Age to rent';
                    return;
                } if (!this.propertyData.children) {
                    this.regMsgTextErr = 'Please Add Add information about childers';
                    return;
                }
                if (!this.propertyData.Events) {
                    this.regMsgTextErr = 'Please Add Add information about Events';
                    return;
                }
                if (!this.propertyData.Pets) {
                    this.regMsgTextErr = 'Please Add Add information about Pets';
                    return;
                }
                if (!this.propertyData.Smoking) {
                    this.regMsgTextErr = 'Please Add Add information about Smoking';
                    return;
                }
                if (this.propertyData.price > this.maxPropertyPrice) {
                    this.propertyData.priceAmount = `Price must be ${this.maxPrice} or less`;
                    return;
                }
                this.currStep = 10;
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep == 10) {
                if (!this.propertyData.city) {
                    this.regMsgTextErr = 'Please Add the City';
                    return;
                }
                if (localStorage.getItem('region') == 'United States' && !this.propertyData.state) {
                    this.regMsgTextErr = 'Please Add the state';
                    return;
                }
                if (!this.propertyData.country) {
                    this.regMsgTextErr = 'Please Add the Country';
                    return;
                }
                if (!this.propertyData.address) {
                    this.regMsgTextErr = 'Please Add the Address';
                    return;
                }
                const formattedAddress = [
                    this.propertyData.address,
                    this.propertyData.city,
                    this.propertyData.country
                ]
                    .filter(part => part)
                    .join(', ');
                const formattedAddress1 = '2930 Pearl Street Suite 100, Boulder, CO 80301-1124, USA'
                this.validateAddress(formattedAddress);
                setTimeout(() => {
                    if (this.validaddress == false) {
                        this.regMsgTextErr = 'Invalid address. Please enter a valid address.';
                        return;
                    }
                    this.currStep = 11;
                }, 500);
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep == 11) {
                this.currStep = 12;
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep == 12) {
                this.regBtnText = this.$t('register.next');
            }
        },

        previewImages(event) {
            const files = event.target.files;
            if (files) {
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    if (file) {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                            this.propertyData.imagePreviews.push(e.target.result);
                            this.$forceUpdate();
                        };
                        reader.readAsDataURL(file);
                    }
                }
            }
        },
        removeImage(index) {
            this.propertyData.imagePreviews.splice(index, 1);
            this.$forceUpdate();
        },
        addProperty() {
            this.$v.propertyData.$touch();
            if (this.propertyData.id) {
                API.updateProperty(this.propertyData, data => {
                    if (data.status == 200) {
                        this.$router.push({ name: 'properties' });
                    } else {
                        this.$swal({
                            type: 'error',
                            title: 'Oops...',
                            text: data.message,
                        })
                    }
                    this.isDisabled = false;
                }, this.errorCB);
            } else {
                API.addProperty(this.propertyData, data => {
                    if (data.status == 200) {
                        if (this.$route.path == '/add_property') {
                            this.$router.push({ name: 'home' });
                        } else {
                            this.$router.push({ name: 'properties' });
                        }
                    } else {
                        this.$swal({
                            type: 'error',
                            title: 'Oops...',
                            text: data.message,
                        })
                    }
                    this.isDisabled = false;
                }, this.errorCB);
            }

        },
        editProperty(id) {
            if (id != null) {
                API.editProperty(id, res => {
                    this.propertyData = res.data.data;
                    // console.log("Success Resp:", res.data.flashdiscount);
                    if (res.data.discounts != null) {
                        this.longstaysToggle = true;  // Directly update longstaysToggle
                        this.propertyData.oneweekdisc = res.data.discounts.oneweekdisc;
                        this.propertyData.twoweekdisc = res.data.discounts.twoweekdisc;
                        this.propertyData.threeweekdisc = res.data.discounts.threeweekdisc;
                        this.propertyData.onemonthdis = res.data.discounts.onemonthdis;
                    } else {
                        this.longstaysToggle = false;  // Ensure it's set to false if no discounts
                    }
                    if (res.data.flashdiscount != null) {
                        this.flashdiscountToggle = true;  // Directly update longstaysToggle
                        this.propertyData.flashdiscountPercentage = res.data.flashdiscount.discount_percentage;
                        const flashDate = new Date(res.data.flashdiscount.start_date);
                        const formattedDate = this.formatDate(flashDate);

                        // Log the formatted date
                        console.log('Formatted Date:', formattedDate);

                        // Assign the formatted date to the property
                        this.propertyData.flashstartDate = formattedDate;
                    } else {
                        this.flashdiscount = false;  // Ensure it's set to false if no discounts
                    }
                    this.propertyData.imagePreviews = res.data.data.photos;
                    this.propertyData.propertyType = res.data.data.property_type;
                    this.propertyData.accomodationType = res.data.data.accommodation_type;
                    this.propertyData.wifiAvailable = res.data.data.wifi_available ? 1 : 0;
                    this.propertyData.price = res.data.data.price;
                    this.propertyData.kitchen = res.data.data.kitchen_available ? 1 : 0;
                    this.propertyData.tv = res.data.data.tv_available ? 1 : 0;
                    this.propertyData.lang = this.$i18n.locale;
                    this.propertyData.description = res.data.data.property_description;

                    if (typeof this.propertyData.bathroom_Amenities === 'string') {
                        this.propertyData.bathroom_Amenities = JSON.parse(this.propertyData.bathroom_Amenities);
                    }
                    if (Array.isArray(this.propertyData.bathroom_Amenities)) {
                        this.propertyData.bathroom_Amenities.forEach(amenity => {
                            if (amenity === "Soap") {
                                this.propertyData.soapAvailable = true;
                            } else if (amenity === "Shower_only") {
                                this.propertyData.showerOnly = true;
                            } else if (amenity === "Towels_provided") {
                                this.propertyData.towelsProvided = true;
                            } else if (amenity === "Shampoo") {
                                this.propertyData.shampooAvailable = true;
                            } else if (amenity === "Toilet") {
                                this.propertyData.toiletAvailable = true;
                            }
                        });
                    }
                    if (typeof this.propertyData.bedroom_Amenities === 'string') {
                        this.propertyData.bedroom_Amenities = JSON.parse(this.propertyData.bedroom_Amenities);
                    }
                    if (Array.isArray(this.propertyData.bedroom_Amenities)) {
                        this.propertyData.bedroom_Amenities.forEach(amenity => {
                            if (amenity === "King_Bed") {
                                this.propertyData.bedroom_Amenities.King_Bed = true;
                            } else if (amenity === "Double_Sofa_Bed") {
                                this.propertyData.bedroom_Amenities.Double_Sofa_Bed = true;
                            } else if (amenity === "Double_Futon") {
                                this.propertyData.bedroom_Amenities.Double_Futon = true;
                            }
                        });
                    }
                }, err => {
                    console.log(err);
                })
            }
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = ('0' + (date.getMonth() + 1)).slice(-2); // Add 1 as months are zero-based
            const day = ('0' + date.getDate()).slice(-2); // Add leading zero if needed

            return `${year}-${month}-${day}`;
        },
        validateAddress(address11) {
            const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address11)}&key=${process.env.VUE_APP_GOOLE_PLACES_API_KEY}`;
            fetch(geocodingUrl)
                .then(response => response.json())
                .then(data => {
                    if (data.status === 'OK') {
                        const result = data.results[0];
                        const addressComponents = result.address_components;
                        let hasStreet = false;
                        let city = '';
                        let country = '';
                        let formattedAddress = result.formatted_address;
                        addressComponents.forEach(component => {
                            if (component.types.includes('street_number') || component.types.includes('route')) {
                                hasStreet = true;
                            }
                            if (component.types.includes('locality')) {
                                city = component.long_name;
                            }
                            if (component.types.includes('administrative_area_level_1')) {
                                city = component.long_name;
                            }
                            if (component.types.includes('postal_town')) {
                                city = component.long_name;
                            }
                            if (component.types.includes('country')) {
                                country = component.long_name;
                            }
                        });
                        if (hasStreet && city && country) {
                            this.validaddress = true;
                            console.log('Address is valid and complete:', formattedAddress);
                            return true;
                        } else {
                            this.validaddress = false;
                            console.log('Address is incomplete. Valid city or country but missing full address.');
                            return false;
                        }
                    } else {
                        console.log('Address validation failed. Status:', data.status);
                        this.validaddress = false;
                        return false;
                    }
                })
                .catch(error => {
                    console.error('Error during Geocoding API call:', error);
                    return false;
                });
        },
        async loadPlacesService() {
            console.log('loader called')
            const loader = new Loader({
                apiKey: process.env.VUE_APP_GOOLE_PLACES_API_KEY,
                version: 'weekly',
                libraries: ['places']
            });
            try {
                await loader.load();
                this.placesService = new window.google.maps.places.AutocompleteService();
            } catch (error) {
                console.error('Error loading Google Maps API:', error);
            }
        },
        onInput() {
            console.log(this.propertyData.address);
            if (this.propertyData.address.length < 3) {
                this.suggestions = [];
                return;
            }

            console.log('Fetching predictions...');
            if (!this.placesService) {
                console.error('PlacesService is not initialized');
                return;
            }
            this.placesService.getPlacePredictions(
                { input: this.propertyData.address, types: ['geocode'] },
                (predictions, status) => {
                    console.log('predictions', predictions);
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        this.suggestions = predictions;
                    } else {
                        console.error('Error fetching place predictions:', status);
                        this.suggestions = [];
                    }
                }
            );
        },
        selectSuggestion(suggestion) {
            this.propertyData.address = suggestion.description;
            this.suggestions = [];  // Clear suggestions after selection
        },

    }, computed: {

        // translatedstate: {
        //     get() {
        //         const lang = this.$i18n.locale;
        //         if (lang === 'en') {
        //             return this.propertyData.state;
        //         } else {
        //             return this.propertyData[`state_${lang}`];
        //         }
        //     },
        //     set(newValue) {
        //         const lang = this.$i18n.locale;
        //         if (lang === 'en') {
        //             this.propertyData.state = newValue;
        //         } else {
        //             this.propertyData[`state_${lang}`] = newValue;
        //         }
        //     }
        // },
        // translatedCity: {
        //     get() {
        //         const lang = this.$i18n.locale;
        //         if (lang === 'en') {
        //             return this.propertyData.city;
        //         } else {
        //             return this.propertyData[`city_${lang}`];
        //         }
        //     },
        //     set(newValue) {
        //         const lang = this.$i18n.locale;
        //         if (lang === 'en') {
        //             this.propertyData.city = newValue;
        //         } else {
        //             this.propertyData[`city_${lang}`] = newValue;
        //         }
        //     }
        // },
        // translatedcountry: {
        //     get() {
        //         const lang = this.$i18n.locale;
        //         if (lang === 'en') {
        //             return this.propertyData.country;
        //         } else {
        //             return this.propertyData[`country_${lang}`];
        //         }
        //     },
        //     set(newValue) {
        //         const lang = this.$i18n.locale;
        //         if (lang === 'en') {
        //             this.propertyData.country = newValue;
        //         } else {
        //             this.propertyData[`country_${lang}`] = newValue;
        //         }
        //     }
        // },
        // translatedDescription: {
        //     get() {
        //         const lang = this.$i18n.locale;
        //         if (lang === 'en') {
        //             return this.propertyData.property_description;
        //         } else {
        //             return this.propertyData[`property_description_${lang}`];
        //         }
        //     },
        //     set(newValue) {
        //         const lang = this.$i18n.locale;
        //         if (lang === 'en') {
        //             this.propertyData.property_description = newValue;
        //         } else {
        //             this.propertyData[`property_description_${lang}`] = newValue;
        //         }
        //     }
        // },
        translatedPropertyTypeOptions() {
            const translatedOptions = {};
            for (const key in this.propertyTypeOptions) {
                translatedOptions[key] = this.$t(`property_detail.${key}`);
            }
            return translatedOptions;
        },
        translatedyesnoOptions() {
            const translatedOptions = {};
            for (const key in this.yesNoOptions) {
                translatedOptions[key] = this.$t(`property_detail.${key}`);
            }
            return translatedOptions;
        }
    },
    mounted() {
        const id = this.$route.params.id;
        if (id) {
            // Do something with the id
            console.log("ID:", id);
            this.editProperty(id);
        } else {
            // Handle case where id is not provided
            console.log("No ID provided");
        }
        API.getAllCountries(data => {
            this.countries = data.data;
        }, error => {
            console.log(error);
        });
        this.loadPlacesService();
        this.getMaxPropertyPrice();
    },
    watch: {
        'propertyData.address': function (newAddress) {
            this.fetchSuggestions(newAddress);
        },
        // translatedcountrys(newValue) {
        //     this.propertyData.country = newValue;
        // },
        // translatedState(newValue) {
        //     this.propertyData.state = newValue;
        // },
        // translatedCity(newValue) {
        //     this.propertyData.city = newValue;
        // },
        // translatedDescription(newValue) {
        //     this.propertyData.property_description = newValue;
        // },
        '$i18n.locale': function (newLocale, oldLocale) {
            this.propertyData.lang = this.$i18n.locale;

        }
    }
}
</script>

<style></style>

<style>
.left-card.m-b-30 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
}
.checkbox-group .custom-control-label {
    padding-top: 5px !important;
}

.custom-label {
    font-size: 0.875rem;
}

.checkbox-group .b-form-checkbox {
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
    vertical-align: middle;
}

.Customer-card-body {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.Customer-card-body .card {
    border: 0;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 0 6px 5px #0000000f;
}

.Customer-card-body .card .card-heading h5 {
    margin: 0;
    font-size: 20px;
    line-height: 1.2;
    color: #fff;
}

.Customer-card-body .card-body {
    border: 0;
    border-radius: 0;
    padding: 20px;
}

.Customer-card-body .card-body .form-group .form-check {
    margin-top: 0;
    margin-bottom: 15px;
    width: 33%;
}

.Customer-card-body .card-body .form-group .radio-buttons-wrapper {
    padding-left: 1.25rem;
    display: flex;
    flex-wrap: wrap;
}

.Customer-card-body .card .card-heading {
    margin-bottom: 0;
    background: #70D3FF;
    padding: 20px;
}

.footer-buttons.md-form {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-buttons.md-form .btn.btn-default {
    border: 1px solid #B6B6B6;
    background: #B6B6B6;
    width: 105px;
    color: #fff;
    margin-right: 15px;
    font-weight: 500;
    border-radius: 5px;
    height: 44px;
}

.footer-buttons.md-form .btn.btn-default:hover {
    background: #70d3ff;
    border-color: #70d3ff;
    color: #fff;
}

.footer-buttons.md-form .btn.btn-primary {
    border-radius: 5px !important;
    box-shadow: none !important;
    height: 44px;
    margin-left: auto !important;
}

.Customer-card-body .card.second-step .card-body .form-group {
    margin: 0;
}

.Customer-card-body .card.second-step .card-body .form-group .radio-buttons-wrapper {
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.Customer-card-body .card.second-step .card-body .form-group .radio-buttons-wrapper label.form-check-label {
    margin-left: 0;
    color: #717171;
    font-size: 16px;
}
.Customer-card-body .card.second-step .card-body .form-group .radio-buttons-wrapper label.form-label {
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
    color: #333;
}

.Customer-card-body .card.second-step .card-body .form-group .form-check {
    width: 70px;
    padding-left: 20px;
}

.Customer-card-body .card-body .form-group .form-control {
    border-radius: 10px;
    border: 1px solid #D6D6D6;
}

.Customer-card-body .card-body .form-group .checkbox-group .form-check .form-check-label {
    margin: 0;
}

.Customer-card-body .card-body .form-group .checkbox-group .form-check {
    padding-left: 20px;
    width: 100%;
}

.Customer-card-body .card-body .form-group .checkbox-group .custom-label {
    font-weight: 600;
    color: #33333;
    font-size: 16px;
}
.Customer-card-body .amenities-form .form-group {
    width: 48%;
}
.Customer-card-body .card-body.md-form.amenities-form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.Customer-card-body .card-body.step-five .form-group label {
    display: block;
    font-weight: 500;
    margin-bottom: 10px;
}

.Customer-card-body .card-body.step-five .form-group {
    margin: 0;
}

.images-listing {
    display: flex;
    flex-wrap: wrap;
    gap: 22px;
}

.images-listing .images-card {
    margin-top: 15px;
    width: auto;
    position: relative;
}

.images-listing .images-card img {
    border-radius: 15px;
    width: 120px;
    height: 120px;
    object-fit: cover;
    border: 1px solid #ddd;
}

.images-listing .images-card button.btn {
    width: 26px !important;
    margin-top: 0 !important;
    border-radius: 100px !important;
    height: 26px;
    padding: 0 !important;
    font-size: 10px !important;
    box-shadow: none !important;
    position: absolute;
    right: -10px;
    top: -10px;
    background: #70d3ff !important;
}
.images-listing .images-card button.btn svg {
    width: 12px;
}

.Customer-card-body .card-body.step-five .form-group input[type=file] {
    opacity: 0;
    width: 0;
    height: 0;
    display: none;
}
.Customer-card-body .card-body.step-five .form-group .upload-img {
    flex-wrap: wrap;
    border: 2px dotted #70D3FF;
    border-radius: 15px;
    text-align: center;
    max-width: 500px;
    margin: 10px auto;
    gap: 25px;
    padding: 25px;
}
.Customer-card-body .card-body.step-five .form-group .upload-img .image {
    width: 100%;
}
.Customer-card-body .card-body.step-five .form-group .upload-img label {
    margin-right: 0;
    border: none;
    background: #70d3ff;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background .2s ease-in-out;
    width: 100%;
    margin: 0;
    max-width: 150px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}
.Customer-card-body .card-body.step-five .form-group .upload-img p {
    width: 100%;
    color: #717171;
    font-size: 16px;
    line-height: 1.2;
}
.Customer-card-body .card-body .form-group textarea.form-control {
    padding: 10px;
    height: 150px;
    border-radius: 10px;
}

.Customer-card-body .card.laststep {
    border: 0;
    border-radius: 10px;
    padding: 40px 20px;
    text-align: center;
}

.Customer-card-body .card-body .form-group label {
    font-weight: 500;
    color: #717171;
    font-size: 16px;
}
.Customer-card-body .card-body .form-group label .text-red {
    color: red;
}

.guest-content {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    margin-bottom: 15px;
    border-right: 0 !important;
}

.guest-content:last-child {
    margin-bottom: 0;
}

.guest-content .btn.btn-info {
    padding: 0;
    width: auto;
    height: auto;
    font-size: 24px;
    background: transparent;
    border: 0;
    color: #D9D9D9;
    border-radius: 0;
}

.guest-content .form-control {
    padding: 0;
    height: 30px;
    border: 0;
    text-align: center;
    width: 60px;
    appearance: none;
}

.guest-content .form-control::-webkit-outer-spin-button,
.guest-content .form-control::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.form-group.quality-fileds {
    display: flex;
    align-items: center;
    gap: 25px;
}

.form-group.quality-fileds .guest-content {
    margin: 0;
    gap: 6px;
    flex-wrap: wrap;
}

.form-group.quality-fileds .guest-content .input-group {
    gap: 0;
    border: 1px solid #D6D6D6;
    border-radius: 10px;
    overflow: hidden;
    padding: 6px 10px;
    flex-wrap: nowrap;
}

.form-group.quality-fileds .guest-content label {
    margin: 0;
    width: 100%;
    color: #717171;
    font-size: 16px;
}
.Customer-card-body .card-body .form-group .quantity .form-control {
    border: 0;
}
</style>


<style>
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
.Customer-card-body .accommodation-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.Customer-card-body .accommodation-form .form-group {
    width: 48%;
}
.Customer-card-body .card-body.pricing-form {
    margin: 0 20px 30px;
    padding: 20px !important;
    border: 1px solid #D6D6D6;
    border-radius: 10px;
}
.Customer-card-body .card-body.house-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.Customer-card-body .card-body.house-form .form-group {
    width: 48%;
}
.alldone-heading {
    max-width: 600px;
    margin: 0 auto 30px;
}
.alldone-heading h5 {
    font-size: 30px;
    line-height: 1.2;
    color: #70D3FF;
    margin-bottom: 25px;
}
.alldone-heading p {
    color: #717171;
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
}
.Customer-card-body .card.laststep .list-property {
    border-radius: 10px !important;
    box-shadow: none !important;
}
.add-property-wrap .guest-content .form-control { 
        width: 100%; 
    }
    .add-property-wrap .form-group.quality-fileds {
        gap: 12px;
    }
@media (max-width: 1200px) {

}
@media (max-width: 991px) {
    /* .Customer-card-body .form-group.quality-fileds { 
        gap: 15px;
    } */
}
@media (max-width: 767px) {
    .client-dashbboard .main-panel .content-wrapper {
        padding: 20px 20px 32px;
    }

    .Customer-card-body .card .card-heading h5 {
        font-size: 18px;
    }

    .form-group.quality-fileds {
        flex-wrap: wrap;
    }

    .Customer-card-body .card-body.step-five .form-group input[type=file] {
        font-size: 13px;
    }
    .Customer-card-body .card-body .form-group .form-check {
        width: 48%;
    }
    .Customer-card-body .accommodation-form .form-group {
        width: 100%;
    }
    .add-property-wrap  .form-group.quality-fileds .guest-content { 
        flex: 1;
    }
}
@media (max-width: 575px) {
    .add-property-wrap  .Customer-card-body .card-body .form-group .form-check,
    .form-group.quality-fileds .guest-content,
    .form-group.quality-fileds .guest-content .guest-col,
    .Customer-card-body .amenities-form .form-group,
    .Customer-card-body .card-body.house-form .form-group {
        width: 100%;
    }
    .add-property-wrap .form-group.quality-fileds .guest-content {
        flex: none;
    }
}
.add-property-wrap  .images-listing {
    justify-content: center;
}
</style>