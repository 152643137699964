<template>
    <section class="tables new-updated-design  px-3 px-md-0 pt-4 pt-md-0">
        <vue-snotify></vue-snotify>
        <div>
            <b-modal id="offer-detail-modal" ref="offer-detail-modal" :title="$t('Offer Details')" size="lg"
                class="offer-detail-modal">
                <div v-if="selectedOffer" class="offer-detail-modal-content">
                    <!-- Offer Table Section -->
                    <div class="offer-detail-modal-table">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title m-0">{{ $t('Offer Details') }}</h4>
                            </div>
                            <div class="card-body">
                                <div class="row row-border">
                                    <div class="col-sm-6 col-md-4">
                                        <p><span class="font-weight-bolder">{{ $t('Customer Name')
                                                }}:</span> {{ selectedOffer.user.name }}</p>
                                        <p><span class="font-weight-bolder">{{ $t('Customer Email')
                                                }}:</span> {{ selectedOffer.user.email }}</p>

                                    </div>
                                    <div class="col-sm-6 col-md-4">
                                        <p><span class="font-weight-bolder">{{ $t('Property Type')
                                                }}:</span> {{ selectedOffer.property.property_type }}</p>
                                        <p><span class="font-weight-bolder">{{ $t('Accomodation Type')
                                                }}:</span> {{ selectedOffer.property.accommodation_type }}</p>
                                        <p><span class="font-weight-bolder">{{ $t('Property Address')
                                                }}:</span> {{ selectedOffer.property.address }}</p>
                                        <p><span class="font-weight-bolder">{{ $t('City')
                                                }}:</span> {{ selectedOffer.property.city }}</p>
                                        <p><span class="font-weight-bolder">{{ $t('Country')
                                                }}:</span> {{ selectedOffer.property.country }}</p>
                                        <p><span class="font-weight-bolder">{{ $t('Property Description')
                                                }}:</span> {{ selectedOffer.property.property_description }}</p>
                                        <p><span class="font-weight-bolder">{{ $t('Price Per Night') }}:</span>
                                            {{ selectedOffer.property.currency }} {{ selectedOffer.property.price
                                            }}/night</p>
                                    </div>
                                    <div class="col-sm-6 col-md-4">
                                        <p><span class="font-weight-bolder">{{ $t('Total Price') }}:</span>
                                            {{ selectedOffer.currency }} {{ selectedOffer.original_price }}</p>
                                        <p><span class="font-weight-bolder">{{ $t('Offered Price') }}:</span>
                                            {{ selectedOffer.currency }} {{ selectedOffer.offered_price }}</p>
                                        <p><span class="font-weight-bolder">{{ $t('No. of days to stay') }}:</span>
                                            {{ selectedOffer.no_of_days }}</p>
                                        <p><span class="font-weight-bolder">{{ $t('No. of Guests') }}:</span>
                                            {{ selectedOffer.no_of_days }}</p>
                                        <p><span class="font-weight-bolder">{{ $t('Start Date') }}:</span>
                                            {{ selectedOffer.start_date }}</p>
                                        <p><span class="font-weight-bolder">{{ $t('End Date') }}:</span>
                                            {{ selectedOffer.end_date }}</p>
                                        <p><span class="font-weight-bolder">{{ $t('Offer Status') }}:</span>
                                            {{ ucfirst(selectedOffer.status) }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="invoice-table">
                    <table>
                        <thead>
                            <tr>
                                <th>{{ $t('Total Price') }}</th>
                                <th>{{ $t('Per Night Price') }}</th>
                                <th>{{ $t('Offered Price') }}</th>
                                <th>{{ $t('Offered Per Night Price') }}</th>
                                <th>{{ $t('Price Difference in %') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="selectedOffer">
                                <td>{{ selectedOffer.currency || 'N/A' }} {{ selectedOffer.original_price || 'N/A' }}
                                </td>
                                <td>{{ selectedOffer.property?.currency || 'N/A' }} {{ selectedOffer.property?.price ||
                                    'N/A' }}/night</td>
                                <td>{{ selectedOffer.currency || 'N/A' }} {{ selectedOffer.offered_price || 'N/A' }}
                                </td>
                                <td>{{ selectedOffer.currency || 'N/A' }} {{ selectedOffer.offer_per_night_price ||
                                    'N/A' }}/night</td>
                                <td>{{ selectedOffer.price_difference_percentage || 'N/A' }}%
                                </td>
                            </tr>
                            <tr v-else>
                                <td colspan="5">{{ $t('No data available') }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </b-modal>
        </div>
        <div v-if="isLoading" class="loading-overlay">
            <div class="spinner"></div>
        </div>
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card properties-table">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">{{ $t('Custom Offers') }}</h4>
                    </div>
                    <div class="card-body">
                        <div class="search-bar mb-3 search-bar-style"> <!-- Added search bar -->
                            <b>{{ $t('Search') }}: </b>
                            <input style="border: 1px solid #dcdfe6;" type="text" v-model="serverParams.search"
                                @input="onSearch" placeholder="Search..." />
                        </div>
                        <vue-good-table mode="remote" @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :pagination-options="{
                                enabled: true, dropdownAllowAll: false, perPageDropdown: [10, 20, 50, 100, 200], nextLabel: this.$t('pagination.next'),
                                prevLabel: this.$t('pagination.Previous'),
                                rowsPerPageLabel: this.$t('pagination.rowsPerPage'),
                                ofLabel: this.$t('pagination.of'),
                            }" :rows="rows" :columns="columns" :sort-options="{
                                enabled: false,
                            }">
                            <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field == 'offer_id'">
                                    <span>#{{ props.row['offer_id' + currentLanguage] }}</span>
                                </span>
                                <span v-if="props.column.field == 'name'">
                                    <span>{{ props.row.user['name' + currentLanguage] }}</span>
                                </span>
                                <span v-if="props.column.field == 'email'">
                                    <span>{{ props.row.user['email' + currentLanguage] }}</span>
                                </span>
                                <span v-if="props.column.field == 'original_price'">
                                    <span>{{ props.row['currency' + currentLanguage] }} {{ props.row['original_price' +
                                        currentLanguage] }}</span>
                                </span>
                                <span v-if="props.column.field == 'offered_price'">
                                    <span>{{ props.row['currency' + currentLanguage] }} {{ props.row['offered_price' +
                                        currentLanguage] }}</span>
                                </span>
                                <span v-if="props.column.field == 'start_date'">
                                    <span>{{ props.row["start_date" + currentLanguage] }}</span>
                                </span>
                                <span v-if="props.column.field == 'end_date'">
                                    <span>{{ props.row["end_date" + currentLanguage] }}</span>
                                </span>
                                <span v-if="props.column.field == 'status'">
                                    <span>{{ ucfirst(props.row["status" + currentLanguage]) }}</span>
                                </span>
                                <span v-if="props.column.field === 'action'"
                                    class="text-nowrap action-buttons-multiple">
                                    <b-button @click="updateStatus(props.row, 'accepted')" variant="success"
                                        class="mr-1 edit-btn">
                                        Accept
                                    </b-button>
                                    <b-button @click="updateStatus(props.row, 'rejected')" variant="danger"
                                        class="mr-1 edit-btn">
                                        Reject
                                    </b-button>
                                    <b-button @click="viewOfferDetails(props.row)" variant="warning"
                                        class="mr-1 edit-btn">
                                        View Detail
                                    </b-button>
                                </span>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
        <div></div>
    </section>
</template>

<script>
import Vue, { set } from "vue";
import SortedTablePlugin from "vue-sorted-table";
import API from '@/api';
import moment from 'moment';
import axios from 'axios';
import Swal from 'sweetalert2';

Vue.use(SortedTablePlugin, {
    ascIcon: '<i class="ti-arrow-down"></i>',
    descIcon: '<i class="ti-arrow-up"></i>'
});
export default {
    name: 'Spotters',
    data() {
        return {
            offers: [],
            searchTime: null,
            isDisabled: false,
            modalContent: '',
            selectedOffer: null,
            currentLanguage: '',
            selected: true,
            unSelected: false,
            serverParams: {
                search: "",
                sort: [
                    {
                        field: 'UserID', // example: 'name'
                        type: 'desc' // 'asc' or 'desc'
                    }
                ],
                page: 1, // what page I want to show
                perPage: 10 // how many items I'm showing per page
            },
            columns: [
                {
                    label: 'Offer ID',
                    field: 'offer_id',
                    filterable: false,
                },
                {
                    label: 'Customer Name',
                    field: 'name',
                    filterable: false,
                },
                {
                    label: 'Customer Email',
                    field: 'email',
                    filterable: false,
                },
                {
                    label: 'Total Price',
                    field: 'original_price',
                    filterable: false,
                },
                {
                    label: 'Offered Price',
                    field: 'offered_price',
                    filterable: false,
                },
                {
                    label: 'Start Date',
                    field: 'start_date',
                    filterable: false,
                },
                {
                    label: 'End Date',
                    field: 'end_date',
                    sortable: false,
                },
                {
                    label: 'Status',
                    field: "status",
                    sortable: false,
                },
                {
                    label: 'Action',
                    field: "action",
                    sortable: false,
                },
            ],
            isLoading: false,
            rows: [],
            totalRecords: 0,
            spots: [],
            ReferredBy: new Map(),
            form: {
                ReferredID: '',
            },
        };
    },
    methods: {
        updateColumnLabels() {
            this.columns.forEach(col => {
                switch (col.field) {
                    case 'property_id':
                        col.label = this.$t('Property ID');
                        break;
                    case 'card_holder':
                        col.label = this.$t('Card Holder');
                        break;
                    case 'payment_id':
                        col.label = this.$t('Payment ID');
                        break;
                    case 'pdf_path':
                        col.label = this.$t('PDF');
                        break;
                    case 'action':
                        col.label = this.$t('properties_list.action');
                        break;
                    default:
                        break;
                }
            });
        },
        onLanguageChanged(language) {
            if (language == 'en') {
                this.currentLanguage = '';
            } else {
                this.currentLanguage = '_' + language;
            }

        },
        // getStatusText(status) {
        //     return status === 0 ? 'Pending' : 'Sent';
        // },
        onSearch() {
            clearTimeout(this.searchTime);
            this.searchTime = setTimeout(() => {
                this.updateParams({ page: 1 });
                this.loadItems();
            }, 500);
        },
        // viewInvoiceDetails(invoice) {
        //     this.selectedInvoice = invoice;
        //     this.$refs['invoice-modal'].show();
        // },
        editProperty(property) {
            this.$router.push({ name: 'property_id', params: { id: property.id, data: property } });
        },
        viewOfferDetails(row) {
            console.log("Offer Data is:", row);
            this.selectedOffer = row;
            this.$refs['offer-detail-modal'].show();
        },
        propertyRating(id) {
            this.$router.push({ name: 'view_rating', params: { id: id } });
        },
        dateFormat(val) {
            return moment(val).format('DD MMMM YYYY')
        },
        loadItems() {
            this.isLoading = true;
            API.getCustomOffers(this.serverParams,
                data => {
                    this.totalRecords = data.totalRecords;
                    this.rows = data.data;
                    this.isLoading = false;
                }, err => {
                    console.log(err)
                    this.isLoading = false;
                })
        },
        updateStatus(row, newStatus) {
            if (row.status === 'accepted' || row.status === 'rejected') {
                Swal.fire({
                    type: 'error',
                    title: 'Action not allowed',
                    text: 'You can perform this action once.',
                    confirmButtonText: 'Ok',
                });
                return;
            }

            const payload = {
                id: row.id,
                status: newStatus
            };
            this.isLoading = true;
            API.updateCustomOfferStatus(payload, (data) => {
                this.isLoading = false;
                if (data.message) {
                    Swal.fire({
                        type: 'success',
                        title: 'Success!',
                        text: data.message,
                        confirmButtonText: 'Ok',
                    }).then((result) => {
                        if (result.value) {
                            this.loadItems();
                        }
                    });
                } else if (data.error) {
                    this.handleApiError(data.error);
                }
            }, (err) => {
                console.log(err);
                this.isLoading = false;
            });
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
            console.log("update param", this.serverParams);
        },
        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.loadItems();
        },
        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage });
            this.loadItems();
        },
        errorMsg(err) {
            console.log(err);
            this.$showNotification({ message: 'Failed system error' }, "error")
        },
        ucfirst(str) {
            if (!str) return str; // handle empty string or undefined
            return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        }
    },
    computed: {
        propertytype() {
            return this.$t('properties_list.Property_Type');
        },
        action() {
            return this.$t('properties_list.action');
        },
        city() {
            return this.$t('properties_list.city');
        },
        state() {
            return this.$t('properties_list.state');
        },
    },
    mounted() {
        this.isLoading = true;
        Vue.prototype.$EventBus.$on('changeLanguage', this.updateColumnLabels);
        this.loadItems();
        this.updateColumnLabels();
    },
    watch: {
        '$i18n.locale': function (newLocale, oldLocale) {
            this.columns.find(col => col.field === 'property_type').label = this.propertytype;
            this.columns.find(col => col.field === 'action').label = this.action;
            this.columns.find(col => col.field === 'city').label = this.city;
            this.columns.find(col => col.field === 'state').label = this.state;
        }
    }
}
</script>
<style>
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #ccc;
    border-top-color: #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>