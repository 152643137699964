<template>
    <div class="close-header-title no-con">
        <section class="privacy-section">
            <div class="container">
                <div class="main-heading">
                    <h1>Contact Us cheapcomforts.com™ </h1>
                    <p>We search the top sites to help travelers book the perfect cheap Comforts </p>
                </div>
                <div class="content-section center">
                    <form class="send-message" @submit.prevent="sendMessage">
                        <div class="form-group">
                            <label for="name">Name</label>
                            <input type="text" v-model="form.name" class="form-control" id="name" :placeholder="$t('Propertis.enter_email')"
                            :class="{'is-invalid': errors.name}" />
                            <div v-if="errors.name" class="invalid-feedback">{{ errors.name }}</div>
                        </div>
                        <div class="form-group">
                            <label for="email">Email</label>
                            <input type="email" class="form-control" v-model="form.email" id="email" :placeholder="$t('Propertis.enter_email')" 
                            :class="{'is-invalid': errors.email}" />
                            <div v-if="errors.email" class="invalid-feedback">{{ errors.email }}</div>
                        </div>
                        <div class="form-group">
                            <label for="message">{{ $t('Propertis.Message') }}</label>
                            <textarea class="form-control" id="message" rows="5"
                            :placeholder="$t('Propertis.enter_message')" v-model="form.message" 
                            :class="{'is-invalid': errors.message}"></textarea>
                            <div v-if="errors.message" class="invalid-feedback">{{ errors.message }}</div>
                        </div>
                        <button type="submit" class="btn btn-primary">Send</button>
                    </form>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import API from '@/api';
import axios from 'axios';
import Vue from 'vue';
import { Loader } from '@googlemaps/js-api-loader';
import { regex } from '@syncfusion/ej2-inputs';

export default {
    name: 'contact_us.vue',
    components: {},
    data() {
        return {
            form: {
                name: '',
                email: '',
                message: '',
            },
            errors: {}
        };
    },
    methods: {
        validate() {
            this.errors = {}; // Reset errors
            let isValid = true;

            // Validate name
            if (!this.form.name) {
                this.errors.name = 'Name is required.';
                isValid = false;
            }

            // Validate email
            if (!this.form.email) {
                this.errors.email = 'Email is required.';
                isValid = false;
            } else if (!/\S+@\S+\.\S+/.test(this.form.email)) {
                this.errors.email = 'Email is invalid.';
                isValid = false;
            }

            // Validate message
            if (!this.form.message) {
                this.errors.message = 'Message is required.';
                isValid = false;
            }

            return isValid;
        },
        sendMessage() {
            if (this.validate()) {
                API.submitContactForm(
                    this.form,
                    data => {
                        if (data.status == 200) {
                        this.$swal({
                            type: 'success',
                            title: 'Success',
                            text: data.message,
                        })
                        } else {
                        this.$swal({
                            type: 'error',
                            title: 'Oops...',
                            text: data.message,
                        })
                        }
                        this.isDisabled = false;
                    },
                    err => {
                        this.isDisabled = false;
                        this.$swal({
                        type: 'error',
                        title: 'Oops...',
                        text: err.message,
                        })
                    }
                )
            }
        }
    },
    computed: {},
    created() {}
}
</script>
<style>
h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
    margin-bottom: 5px !important;
}
.form-group label {
  font-size: 18px;
}
.form-control {
    display: block;
    width: 100%;
    height: 46px;
    padding: 5px 15px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5rem;
    color: #333;
    background-color: #fff;
    background-image: none;
    border: 1px solid #676A7D !important;
    border-radius: 30px;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
}
.send-message button.btn.btn-primary {
  font-size: 16px !important;
  line-height: 1.25rem;
  min-block-size: 2.25rem;
  padding: .25rem 1rem;
  background: #70d3ff !important;
  color: #fff;
  border: 1px solid #70d3ff;
  width: 100%;
  gap: .5rem;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 30px !important;
}
textarea.form-control {
    height: 110px;
    padding: 15px;
}
.send-message button.btn.btn-primary:hover {
    background-color: #fff !important;
}
.invalid-feedback {
    display: block;
    color: red;
    font-size: 0.875em;
}
.is-invalid {
    border-color: red;
}
</style>